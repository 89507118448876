<template>
    <div id="timesheetCalendarRis">



        <div v-if="sezione == 'Timesheet'">

            <div class="cl_cont_azi_res_ts">



                <div class="cont_names">

                    <div id="res_full_name" class="name_res"></div>

                    <div id='userRed' title='Aperto'>
                        <img src="@/assets/user_red.png"
                            style='border:0!important;width:30px;float:right;margin-top:-13px;margin-right:16px' />
                    </div>

                    <div id='userYellow'>
                        <img src="@/assets/user_yellow.png"
                            style='border:0!important;width:30px;float:right;margin-top:-13px;margin-right:16px' />
                    </div>

                    <div id='userGreen'>
                        <img src="@/assets/user_green.png"
                            style='border:0!important;width:30px;float:right;margin-top:-13px;margin-right:16px' />
                    </div>


                </div>


            </div>


            <div class="totals">
                <div class="grouptotal">
                    <span class="titletotals" title="Ore Ordinarie">
                        <v-icon title="Ore Ordinarie">mdi-account-hard-hat</v-icon>
                        <span> {{ totPresenzaMese }} H</span>
                    </span>
                    <span class="detailtotals">{{ totPresenzaMese }} </span>
                </div>
                <div class="grouptotal">
                    <span class="titletotals" title="Ore Assenza">
                        <v-icon title="Ore Assenza">mdi-account-minus</v-icon>
                        <span> {{ totAssenzaMese }} H</span>
                    </span>
                    <span class="detailtotals">{{ totAssenzaMese }} </span>
                </div>

                <div class="grouptotal">
                    <span class="titletotals" title="Ore Straordinarie">
                        <v-icon title="Ore Straordinario">mdi-account-plus</v-icon>
                        <span> {{ totStraordinariMese }} H</span>
                    </span>
                    <span class="detailtotals">{{ totStraordinariMese }}</span>
                </div>

                <div class="grouptotal" v-if="viewRep == 'S'">
                    <span class="titletotals" title="Ore Reperibilità">
                        <v-icon title="Ore Reperibilità">mdi-account-star</v-icon>
                        <span> {{ totReperibilitaMese }} H</span>
                    </span>
                    <span class="detailtotals">{{ totReperibilitaMese }}</span>
                </div>

                <div class="grouptotal" v-if="viewInterv == 'S'">
                    <span class="titletotals" title="Ore Intervento">
                        <v-icon title="Ore Intervento">mdi-account-star</v-icon>
                        <span> {{ totInterventoMese }} H</span>
                    </span>
                    <span class="detailtotals">{{ totInterventoMese }}</span>
                </div>
            </div>

            <v-layout d-flex align-center justify-center style="min-height: auto;margin-top:66px;">
                <v-card-actions>
                    <div class="contcalendar">

                        <datepicker ref="defCalendar" @dayclick="onDayClick" @update:from-page="pageChange"
                            format="DD-MM-YYYY" v-model="date" is-expanded :first-day-of-week="2" locale="it"
                            color="yellow" is-light :attributes="attributes" :disabled-dates="disabledDates" />
                    </div>
                </v-card-actions>
            </v-layout>

            <rise-loader v-if="showSpinner" :size="'20px'" :color="'#ffd212'" />


            <div class="mx-auto nocolor contQuanties" color="transparent" style="transform: translate(0px, -4px);}">



                <v-list class="transparent containerFields">
                    <v-list-item v-for="item in forecast" :key="item.day" :id="item.day"
                        style="min-height: 40px!important;max-height: 40px!important;">


                        <div style="display: flex;
align-items: center;
justify-items: center;
justify-content: center;
">

                            <div style="width: 37px;"
                                v-if="(item.day != 'Reperibilità') || (item.day == 'Reperibilità' && viewRep == 'S') || (item.day == 'Ordinarie' && IsPartitaIva == 'S') || (item.day == 'Assenza' && IsPartitaIva == 'S')">
                                <v-icon>{{ item.icon }}</v-icon>
                            </div>

                            <div style="width: 59px;"
                                v-if="(item.day != 'Reperibilità') || (item.day == 'Reperibilità' && viewRep == 'S') || (item.day == 'Ordinarie' && IsPartitaIva == 'S') || (item.day == 'Assenza' && IsPartitaIva == 'S')">
                                {{ item.day }}
                            </div>

                            <div class="conSelect"
                                v-if="item.day == 'Straordinari' || (item.day == 'Ordinarie' && IsPartitaIva == 'S') || (item.day == 'Assenza' && IsPartitaIva == 'S')">

                                <v-text-field readonly type="text" :min="0" :max="10" class="selectHour2"
                                    @change="updateSumDay" :items="itemsQuantity" label="" dense
                                    :value="item.quantity + ' H'" :ref="'combo' + item.day"
                                    :id="'input' + item.day"></v-text-field>

                                <div class="addbuttons">

                                    <div class="minusContainer">
                                        <img src='/emt/img/image_minus.png' class="minus" :data-id="item.day"
                                            v-on:click="sumHours" />
                                    </div>

                                    <div>
                                        <img src='/emt/img/image_plus.png' class="plus" :data-id="item.day"
                                            v-on:click="sumHours" />
                                    </div>

                                </div>

                            </div>

                            <v-select v-if="item.day == 'Causale' && IsPartitaIva == 'S'" class="selectHour2"
                                :items="causali" item-value="idtypeactivity" item-text="description" label="" dense
                                v-model="item.quantity" @change="updateSumDay"
                                style="width:180px!important;min-width: 180px!important;"></v-select>


                            <div class="conSelect" style="margin-left: 15px;" v-if="item.day == 'Descrizione'">

                                <v-text-field label="" dense :value="item.description"
                                    style="min-width: 165px!important;width: 165px!important;" maxlength="28"
                                    @input="onChangeDescription" :id="'input' + item.day"
                                    v-model="valDescription"></v-text-field>

                            </div>

                            <div class="conSelect" v-if="item.day == 'Reperibilità' && viewRep == 'S'">

                                <v-text-field readonly type="text" :min="0" :max="10"
                                    style="margin-top: 18px!important;" class="selectHour2" @change="updateSumDay"
                                    :items="itemsQuantity" label="" dense :value="item.quantity + ' H'"
                                    :ref="'combo' + item.day" :id="'input' + item.day"></v-text-field>

                                <div class="addbuttons">

                                    <div class="minusContainer">
                                        <img src='/emt/img/image_minus.png' class="minus" :data-id="item.day"
                                            v-on:click="sumHours" />
                                    </div>

                                    <div>
                                        <img src='/emt/img/image_plus.png' class="plus" :data-id="item.day"
                                            v-on:click="sumHours" />
                                    </div>

                                </div>

                                <div style="transform: translateY(-9px);">
                                    <v-switch label="" false-value="N" true-value="S" v-model="viewInterv"
                                        @change="onChangeViewInterv"></v-switch>
                                </div>


                            </div>

                            <div class="conSelect" v-if="item.day == 'Intervento'">

                                <v-text-field readonly type="text" :min="0" :max="10"
                                    v-if="item.day != 'Causale' && item.day != 'Totale'" class="selectHour2"
                                    @change="updateSumDay" :items="itemsQuantity" label="" dense
                                    :value="item.quantity + ' H'" :ref="'combo' + item.day"
                                    :id="'input' + item.day"></v-text-field>


                                <div class="addbuttons">

                                    <div class="minusContainer">
                                        <img src='/emt/img/image_minus.png' class="minus" :data-id="item.day"
                                            v-on:click="sumHours" />
                                    </div>

                                    <div>
                                        <img src='/emt/img/image_plus.png' class="plus" :data-id="item.day"
                                            v-on:click="sumHours" />
                                    </div>

                                </div>

                            </div>

                            <div class="conSelect" v-if="item.day == 'Dalle'">

                                <v-text-field readonly type="text" :min="0" :max="10" class="selectHour2"
                                    :disabled="false" label="" dense :value="item.timeStart"
                                    :id="'input' + item.day"></v-text-field>


                                <div class="addbuttons">

                                    <div class="minusContainer">
                                        <img src='/emt/img/image_minus.png' class="minus" :data-id="item.day"
                                            v-on:click="sumHours2" />
                                    </div>

                                    <div>
                                        <img src='/emt/img/image_plus.png' class="plus" :data-id="item.day"
                                            v-on:click="sumHours2" />
                                    </div>

                                </div>

                            </div>

                            <div class="conSelect" v-if="item.day == 'Alle'">

                                <v-text-field readonly type="text" :min="0" :max="10" class="selectHour2"
                                    :disabled="false" label="" dense :value="item.timeEnd"
                                    :id="'input' + item.day"></v-text-field>



                                <div class="addbuttons">

                                    <div class="minusContainer">
                                        <img src='/emt/img/image_minus.png' class="minus" :data-id="item.day"
                                            v-on:click="sumHours2" />
                                    </div>

                                    <div>
                                        <img src='/emt/img/image_plus.png' class="plus" :data-id="item.day"
                                            v-on:click="sumHours2" />
                                    </div>

                                </div>

                            </div>

                            <div class="conSelect" v-if="item.day == 'Progetto' && IsPartitaIva == 'S'">

                                <div class="clViewProjects" title="Vedi ore progetti">
                                    <v-icon style="font-size:20px!important"
                                        v-on:click="openPopupOreProgetti">mdi-table</v-icon>
                                </div>

                            </div>

                        </div>


                    </v-list-item>
                </v-list>


            </div>

        </div>



        <div v-if="sezione == 'Ferie'">

            <div v-if="!isMobile" class="cl_cont_azi_res_ts clferiepermessi">

                <div style="color: black;font-weight: bold;font-size:10px;margin-left: 10px;">Gestione ferie / permessi
                </div>

                <div style="transform: translateY(1px);">
                    <div style="display: flex;">
                        <div style="display:flex;align-items: center;margin-left: 20px;">
                            <div style="width: 114px;font-size: 10px;font-weight: bold;">H. Tot. Ferie {{ mesePeriodo
                                }}:
                            </div>
                            <div style="margin-left: 5px;font-size: 10px;font-weight: bold;width:28px;color:red">{{
                                oreTotFerie }}
                            </div>
                        </div>
                        <div style="display:flex;align-items: center;margin-left: 27px;">
                            <div style="font-size: 10px;font-weight: bold;width:192px">H. Tot. Permesso Retribuito {{
                                mesePeriodo }}:</div>
                            <div style="margin-left: 5px;font-size: 10px;font-weight: bold;width:28px;color:red">{{
                                oreTotPermRetr
                            }}
                            </div>
                        </div>
                    </div>
                    <!-- <div style="display: flex;">
                        <div style="display:flex;align-items: center;margin-left: 20px">
                            <div style="font-size: 10px;font-weight: bold;width:134px">H. Tot. Ferie AC / AP:</div>
                            <div style="margin-left: 5px;font-size: 10px;font-weight: bold;width:28px">{{
                                oreTotFerieACAP }}
                            </div>
                        </div>
                        <div style="display:flex;align-items: center;margin-left: 27px">
                            <div style="font-size: 10px;font-weight: bold;width:200px">H. Tot. Permessi AC / AP:</div>
                            <div style="margin-left: 5px;font-size: 10px;font-weight: bold;width:50px">{{
                                oreTotPermessiACAP
                            }}
                            </div>
                        </div>
                    </div> -->
                </div>



            </div>

            <div v-if="isMobile" class="cl_cont_azi_res_ts clferiepermessi">

                <div>
                    <div style="color: black;font-weight: bold;font-size:10px;margin-left: 10px;">Gestione ferie /
                        permessi
                    </div>
                    <div style="display: flex;">
                        <div style="display:flex;align-items: center;margin-left: 10px;">
                            <div style="width: 113px;font-size: 10px;font-weight: bold;">H. Tot. Ferie {{ mesePeriodo
                            }}:
                            </div>
                            <div style="margin-left: 5px;font-size: 10px;font-weight: bold;width:28px;color:red">{{
                                oreTotFerie }}
                            </div>
                        </div>
                        <div style="display:flex;align-items: center;margin-left: 27px;">
                            <div style="font-size: 10px;font-weight: bold;width:192px">H. Tot. Permesso Retribuito {{
                                mesePeriodo }}:</div>
                            <div style="margin-left: 5px;font-size: 10px;font-weight: bold;width:28px;color:red">{{
                                oreTotPermRetr
                            }}
                            </div>
                        </div>
                    </div>
                    <!-- <div style="display: flex;">
                        <div style="display:flex;align-items: center;margin-left: 10px">
                            <div style="font-size: 9px;font-weight: bold;width:107px">H. Tot. Ferie AC / AP:</div>
                            <div style="margin-left: 5px;font-size: 9px;font-weight: bold;width:28px">{{
                                oreTotFerieACAP }}
                            </div>
                        </div>
                        <div style="display:flex;align-items: center;margin-left: 27px">
                            <div style="font-size: 9px;font-weight: bold;width:186px">H. Tot. Permessi AC / AP:</div>
                            <div style="margin-left: 5px;font-size: 9px;font-weight: bold;width:50px">{{
                                oreTotPermessiACAP
                                }}
                            </div>
                        </div>
                    </div> -->


                </div>

            </div>

            <v-layout d-flex align-center justify-center style="min-height: auto;margin-top:4px;">
                <v-card-actions>
                    <div class="contcalendarferie" id="contCalendarHoliday">

                        <datepicker is-range :first-day-of-week="2" is-expanded color="yellow" is-light
                            ref="defCalendar" :attributes="attributesCal" @dayclick="onDayClickFerie"
                            format="DD-MM-YYYY" v-model="rangeCal" :disabled-dates="disabledDatesCal"
                            @update:from-page="onMonthChangeCal" />

                    </div>
                </v-card-actions>
            </v-layout>

            <rise-loader v-if="showSpinner" :size="'20px'" :color="'#ffd212'" />

            <div class="mx-auto nocolor contQuanties contVoices" color="transparent"
                style="transform: translate(0px, -50px);">

                <v-list-item one-line class="contDayFormatted" style="min-height:20px;">
                    <v-list-item-content>

                        <v-list-item-subtitle class="dayFormatted"
                            style="justify: center; text-align: center;font-size: 9px;" justify="center">{{
                                dayFormatted }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list class="transparent" style="transform: translateX(-12px);">

                    <v-list-item v-if="viewSelezione">
                        <v-list-item-title align="start" justify="start"> Selezione </v-list-item-title>
                        <v-list-item-subtitle class="text-right"
                            style="min-width: 200px;font-size:9px;font-weight: bold;color:black">
                            {{ rangeDetailCal }}
                        </v-list-item-subtitle>
                    </v-list-item>


                    <v-list-item v-if="viewCausale">
                        <v-list-item-title align="start" justify="start"> Causale </v-list-item-title>

                        <v-list-item-subtitle class="text-right" style="min-width: 217px">
                            <v-select v-model="causale" class="selectHour" :disabled="false" :items="causaliFerie"
                                item-value="idtype_holidays_permits" item-text="description" label="" dense
                                @change="onChangeCausale"></v-select>

                        </v-list-item-subtitle>
                    </v-list-item>

                    <v-list-item v-if="vediGiornata">
                        <v-list-item-title align="start" justify="start"> Giornata </v-list-item-title>

                        <v-list-item-subtitle class="text-right" style="min-width: 217px">

                            <v-radio-group v-model="tipoGiornata" row
                                style="padding-right: 0px!important;margin-right: 0px!important;text-align: right!important;margin-left: 2px!important;">

                                <v-radio label="Completa" value="G_COMPLETA" v-on:change="chgRadio"></v-radio>
                                <v-radio label="Parziale" value="G_PARZIALE" v-on:change="chgRadio"></v-radio>

                            </v-radio-group>

                        </v-list-item-subtitle>
                    </v-list-item>



                    <v-list-item id='startHour' v-if="singleMode">

                        <v-list-item-title align="start" justify="start"> Orario inizio
                        </v-list-item-title>

                        <v-list-item-subtitle class="text-right" style="min-width: 217px">

                            <div class="conSelect">

                                <v-text-field v-model="timeStartCal" readonly type="text" :min="0" :max="10"
                                    class="selectHour" @change="updateSumDayCal" :disabled="false" label="" dense
                                    value=""></v-text-field>


                                <div class="addbuttons">

                                    <div class="minusContainer">
                                        <img src='/emt/img/image_minus.png' class="minus" data-id="startHour"
                                            v-on:click="sumHoursCal" />
                                    </div>

                                    <div>
                                        <img src='/emt/img/image_plus.png' class="plus" data-id="startHour"
                                            v-on:click="sumHoursCal" />
                                    </div>

                                </div>

                            </div>



                        </v-list-item-subtitle>
                    </v-list-item>

                    <v-list-item id='endHour' v-if="singleMode">

                        <v-list-item-title align="start" justify="start"> Orario fine
                        </v-list-item-title>

                        <v-list-item-subtitle class="text-right" style="min-width: 217px">

                            <div class="conSelect">

                                <v-text-field v-model="timeEndCal" readonly type="text" :min="0" :max="10"
                                    class="selectHour" @change="updateSumDayCal" :disabled="false" label="" dense
                                    value=""></v-text-field>


                                <div class="addbuttons">

                                    <div class="minusContainer">
                                        <img src='/emt/img/image_minus.png' class="minus" data-id="endHour"
                                            v-on:click="sumHoursCal" />
                                    </div>

                                    <div>
                                        <img src='/emt/img/image_plus.png' class="plus" data-id="endHour"
                                            v-on:click="sumHoursCal" />
                                    </div>

                                </div>

                            </div>


                        </v-list-item-subtitle>
                    </v-list-item>

                    <v-list-item v-if="viewNote">

                        <v-list-item-title align="start" justify="start"> Note </v-list-item-title>

                        <v-list-item-subtitle class="text-right" style="min-width: 217px">
                            <v-textarea id="notes" rows="2" class="selectHour totaleCombo" :disabled="false" label=""
                                dense v-model="notes"></v-textarea>
                        </v-list-item-subtitle>
                    </v-list-item>



                </v-list>


            </div>


        </div>

        <div v-if="sezione == 'Malattia'">

            <div class="cl_cont_azi_res_ts clferiepermessi">

                <div style="color: black;font-weight: bold;font-size:10px;margin-left: 10px;">Gestione malattia
                </div>

                <div style="width: 106px;display:flex;align-items: center;margin-left: 20px;">
                    <div style="width: 65px;font-size: 10px;font-weight: bold;">Ore Totali:</div>
                    <div style="margin-left: 5px;font-size: 10px;font-weight: bold;">{{ oreTotaliMalattia }}</div>
                </div>

            </div>

            <v-layout d-flex align-center justify-center style="min-height: auto;margin-top:4px;">
                <v-card-actions>
                    <div class="contcalendarferie" id="contCalendarHoliday">

                        <datepicker :is-range="rangeDiseaseEnabled" :first-day-of-week="2" is-expanded color="yellow"
                            is-light ref="defCalendar" :attributes="attributeDiseaseCal" @dayclick="onDayClickDisease"
                            format="DD-MM-YYYY" v-model="rangeDisease" :disabled-dates="disabledDatesDisease"
                            @update:from-page="onMonthChangeDisease" />

                    </div>
                </v-card-actions>
            </v-layout>

            <rise-loader v-if="showSpinner" :size="'20px'" :color="'#ffd212'" />

            <div class="mx-auto nocolor contQuanties contVoices" color="transparent"
                style="transform: translate(0px, -50px);">


                <v-list class="transparent" style="transform: translateX(-12px);">


                    <v-list-item v-if="viewSelezione">
                        <v-list-item-title align="start" justify="start"> Selezione </v-list-item-title>
                        <v-list-item-subtitle class="text-right"
                            style="min-width: 200px;font-size:9px;font-weight: bold;color:black">
                            {{ rangeDetailDisease }}
                        </v-list-item-subtitle>
                    </v-list-item>

                    <v-list-item v-if="viewProtocollo">

                        <v-list-item-title align="start" justify="start"> Numero protocollo
                        </v-list-item-title>

                        <v-list-item-subtitle class="text-right" style="min-width: 160px">
                            <v-text-field type="number" v-model.number="certificato" class="selectHour"
                                :disabled="false" item-value="certificato" item-text="Certificato" label="" dense
                                @blur="onBlurNumeroProtocollo"></v-text-field>

                        </v-list-item-subtitle>
                    </v-list-item>

                    <v-list-item v-if="viewNote">

                        <v-list-item-title align="start" justify="start"> Note </v-list-item-title>

                        <v-list-item-subtitle class="text-right" style="min-width: 160px">
                            <v-textarea id="notes" rows="2" class="selectHour totaleCombo" :disabled="false" label=""
                                dense v-model="textNote"></v-textarea>
                        </v-list-item-subtitle>
                    </v-list-item>




                </v-list>



            </div>


        </div>


        <div class="contpopupprotocollo">

            <template>
                <modal name="popupProtocollo" :clickToClose="false" :width="300" :height="209">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Numero Protocollo

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-text-field v-model="numeroProtocollo" label="Protocollo" id="protocollo"
                                maxlength="20"></v-text-field>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">



                                <v-img alt="" class="btn-confirm-protocollo" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="32" max-height="32" style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmProtocollo" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="contpopuppprogetti">

            <template>
                <modal name="popupProgetti" :clickToClose="false" :width="300" :height="269">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Ore Progetti

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12"
                            style="margin-top:10px!important;font-weight:bold;margin-left:10px;display: flex;">

                            <div>Giorno:</div>
                            <div style="margin-left: 10px;">{{ dataGiornataProgetti }}</div>
                            <div style="margin-left: 144px;">Ore Totali:</div>
                            <div style="margin-left: 10px;">{{ oreTotaliGiornataProgetti }}</div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div v-for="item in valuesProjects"
                                style="display: flex;height: 40px;margin-left: 10px;align-items: center;justify-items: center;"
                                :key="item.projectid" :id="item.projectid">

                                <div style="width:60px">
                                    {{ item.codice }}
                                </div>

                                <div class="conSelect" style="margin-top:11px;margin-left: 11px;">

                                    <v-text-field readonly type="text" :min="0" :max="10" class="selectHour2" label=""
                                        dense :value="item.ore + ' H'" :ref="'combo' + item.projectid"
                                        :id="'input' + item.projectid"></v-text-field>

                                    <div class="addbuttons" style="margin-left: 28px;">

                                        <div class="minusContainer">
                                            <img src='/emt/img/image_minus.png' class="minus" :data-id="item.projectid"
                                                v-on:click="sumHoursProject" />
                                        </div>

                                        <div>
                                            <img src='/emt/img/image_plus.png' class="plus" :data-id="item.projectid"
                                                v-on:click="sumHoursProject" />
                                        </div>

                                    </div>

                                </div>


                            </div>


                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-confirm-ore-progetti" contain
                                    src="@/assets/btn_cancel3_emt.png" max-width="32" max-height="32"
                                    style="cursor:pointer" transition="scale-transition"
                                    @click="btnCancelOreProgetti" />

                                <v-img alt="" class="btn-confirm-ore-progetti" contain
                                    src="@/assets/btn_confirm3_emt.png" max-width="32" max-height="32"
                                    style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmOreProgetti" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopupnotaspese">

            <template>
                <modal name="popupNotaSpese" :clickToClose="false" :width="300" :height="710">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Inserimento Nota Spese

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="text-align: center">



                            <button type="button" id="upload-button" class="buttonFileEditSpesa" value="uplFile"
                                @click="openPopupUpload()">

                                <div class="containButtonFileEditSpesa">

                                    <div class="button--text">{{ testoPulsante }}</div>

                                    <div style="margin-left:10px;">
                                        <v-img src="@/assets/upload_file.png" max-width="20" max-height="20" />
                                    </div>


                                </div>





                            </button>



                        </v-col>

                    </v-row>

                    <v-row
                        style="padding-top: 0px!important;margin-top:0px!important;padding-bottom: 0px!important;margin-bottom:0px!important">

                        <v-col cols="12" md="12"
                            style="padding-top: 0px!important;margin-top:0px!important;padding-bottom: 0px!important;margin-bottom:0px!important">

                            <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpData" v-model="spese.data_trasferta" label="Data Trasferta"
                                        persistent-hint readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="spese.data_trasferta" no-title @input="showPicker = false"
                                    locale="it"></v-date-picker>
                            </v-menu>


                        </v-col>


                    </v-row>

                    <v-row
                        style="padding-top: 0px!important;margin-top:0px!important;padding-bottom: 0px!important;margin-bottom:0px!important">

                        <v-col cols="12" md="12"
                            style="padding-top: 0px!important;margin-top:0px!important;padding-bottom: 0px!important;margin-bottom:0px!important">

                            <v-text-field id="fldInpDescription" v-model="spese.description" label="Descrizione">
                            </v-text-field>

                        </v-col>

                    </v-row>

                    <v-row
                        style="padding-top: 0px!important;margin-top:0px!important;padding-bottom: 0px!important;margin-bottom:0px!important">
                        <v-col cols="12" md="12"
                            style="padding-top: 0px!important;margin-top:0px!important;padding-bottom: 0px!important;margin-bottom:0px!important">
                            <v-text-field id="fldPiva" v-model="spese.piva" label="Partiva Iva">
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row
                        style="padding-top: 0px!important;margin-top:0px!important;padding-bottom: 0px!important;margin-bottom:0px!important">
                        <v-col cols="12" md="12"
                            style="padding-top: 0px!important;margin-top:0px!important;padding-bottom: 0px!important;margin-bottom:0px!important">

                            <v-select :items="allTypes" item-value="id" item-text="title" label="Tipo" dense
                                v-model="spese.idprojects_cdc_type" @change="onChangeTipo"></v-select>

                        </v-col>
                    </v-row>


                    <v-row v-if="showKm"
                        style="padding-top: 0px!important;margin-top:0px!important;padding-bottom: 0px!important;margin-bottom:0px!important">

                        <v-col cols="12" md="12"
                            style="padding-top: 0px!important;margin-top:0px!important;padding-bottom: 0px!important;margin-bottom:0px!important">

                            <div id="d_km"
                                class="cl-importo v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute">Km</label>

                                            <vue-autonumeric v-model="spese.km" id="fldInpAmount" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>


                    <v-row v-if="showKm"
                        style="padding-top: 0px!important;margin-top:0px!important;padding-bottom: 0px!important;margin-bottom:0px!important">
                        <v-col cols="12" md="12"
                            style="padding-top: 0px!important;margin-top:0px!important;padding-bottom: 0px!important;margin-bottom:0px!important">
                            <v-textarea id="fldNoteNataSpeseCalTs" v-model="spese.note" label="Note">
                            </v-textarea>
                        </v-col>
                    </v-row>






                    <v-row
                        style="padding-top: 0px!important;margin-top:0px!important;padding-bottom: 0px!important;margin-bottom:0px!important">

                        <v-col cols="12" md="12"
                            style="padding-top: 0px!important;margin-top:0px!important;padding-bottom: 0px!important;margin-bottom:0px!important">

                            <div id="d_importo2"
                                class="cl-importo v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute">Importo</label>

                                            <vue-autonumeric v-model="spese.amount" id="fldInpAmount" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-confirm-ore-progetti" contain
                                    src="@/assets/btn_cancel3_emt.png" max-width="32" max-height="32"
                                    style="cursor:pointer" transition="scale-transition"
                                    @click="btnCancelInsNotaSpese" />

                                <v-img alt="" class="btn-confirm-ore-progetti" contain
                                    src="@/assets/btn_confirm3_emt.png" max-width="32" max-height="32"
                                    style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmInsNotaSpese" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopupuploadfile">

            <template>
                <modal name="popupUploadFile" :clickToClose="false" :width="386" :height="341">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:11px;margin-left:8px;font-weight: bold;">{{ titoloPopup2 }}</label>


                        </v-col>

                    </v-row>

                    <v-row class="rowPrevFile">

                        <v-col cols="12" md="12" style="margin-left: 12px;">


                            <span class="files">
                                <input type="file" id="input-file-attach" name="logo" :accept="fileAccettati"
                                    class="dropify" data-max-width="6610" data-max-height="6200" />
                            </span>


                        </v-col>

                    </v-row>


                    <v-row style="margin-top: 0px!important;">

                        <v-col cols="12" md="12" style="margin-top: -23px!important;display:flex">

                            <label style="font-size:11px;margin-left:12px;font-weight: bold;">File:</label>

                            <div style="margin-left: 10px;font-size:11px;font-weight: bold;">{{ nomeFilePresente }}
                            </div>

                        </v-col>

                    </v-row>

                    <v-row style="margin-top: 0px!important;">

                        <v-col cols="12" md="12" style="margin-top: -7px!important;display:flex">

                            <label style="font-size:11px;margin-left:12px;font-weight: bold;">Dim:</label>

                            <div style="margin-left: 10px;font-size:11px;font-weight: bold;">{{ dimFilePresente }}</div>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-upload" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" @click="btnCancelUpload" />

                                <v-img alt="" class="btn-confirm-upload" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="32" max-height="32" style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmUpload" />


                            </div>

                        </v-col>

                    </v-row>



                </modal>
            </template>

        </div>



    </div>




</template>

<script>
var env = process.env.NODE_ENV;
if (env != "development") console.log = function () { };

import _ from 'lodash';


import {
    bus
} from "../main";

import apitimesheet from "../utils/timesheet/apitimesheet";
import router from ".././router";

import datepicker from "v-calendar/lib/components/date-picker.umd";
import RiseLoader from "vue-spinner/src/RiseLoader.vue";


import apiholidaypermits from "../utils/holidaypermits/apiholidaypermits";
import apidisease from "../utils/disease/apidisease";

import apiuploadfile from "../utils/uploadfile/apiuploadfile";

import apispese from "../utils/spese/apispese";

import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';


export default {

    watch: {
        date: {
            handler: function () {
                // console.log(this.date)
            },
            deep: true,
        },
    },

    updated() {

    },

    mounted: async function () {

        /*eslint-disable no-undef*/





        this.$root.$children[0].checkLogout();

        var pointerVue = this;

        window.numEventOrdinario = 0;
        window.numEventASaveAndOpen = 0;
        window.numEventASaveAndClose = 0;
        window.numEventASave = 0;
        window.numEventPdfAmm = 0;
        window.numEventPdfFull = 0;

        window.numEventDipAvanti = 0;
        window.numEventDipIndietro = 0;

        window.curRowDip = 0;

        window.curProgetto = "";

        this.dipendenti = this.$root.$children[0].dipendenti;
        this.aziende = this.$root.$children[0].aziende;

        console.log("AZI 2: ", this.aziende);

        this.isDipEbadge = parseInt(window.$cookies.get("is_ebadge"));

        bus.$off("bottomIconClickEvent");

        $("#Ordinarie").hide();
        $("#Assenza").hide();
        $("#Straordinari").hide();
        $("#Causale").hide();
        $("#Descrizione").hide();
        $("#Reperibilità").hide();
        $("#Intervento").hide();
        $("#Dalle").hide();
        $("#Alle").hide();
        $("#Progetto").hide();
        $("#Totale").hide();


        setTimeout(() => {

            this.getIdProjectDefault();

        }, 100);

        $(document).ready(function () {


            $('body').on('keydown', '#protocollo', function (event) {
                console.log(event);

                if (!/^[0-9]$/.test(event.key) &&
                    event.key !== "Backspace" &&
                    event.key !== "Tab" &&
                    event.key !== "ArrowLeft" &&
                    event.key !== "ArrowRight" &&
                    event.key !== "Delete") {
                    event.preventDefault(); // Blocca il carattere
                }


            });


        });

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {



                case "btn_refr_tms":
                    this.resetDay();
                    this.syncMonth();
                    break;


                case "btn_save":

                    this.validate();


                    break;


                case "btn_send_tms":




                    this.sendTimesheetForApprove();


                    break;


                case "btn_ferie_permessi":

                    this.sezione = "Ferie";

                    this.currentMonthFerie = new Date().getMonth() + 1;

                    this.currentYearFerie = new Date().getFullYear();

                    this.isMobile = false;

                    if (window.innerWidth <= 768) {

                        this.isMobile = true;

                    }

                    setTimeout(() => {

                        this.loadCausaliFerie();

                        this.loadTotFeriePermessi();

                        setTimeout(() => {

                            this.setupCalendarFerie();

                        }, 300);



                    }, 200);

                    break;


                case "btn_save_request_holiday":

                    this.validateRequestHolidays();

                    break;

                case "btn_timesheet":

                    this.sezione = "Timesheet";

                    setTimeout(() => {

                        $("#Totale").hide();
                        this.resetDay();
                        this.syncMonth();

                    }, 200);

                    break;

                case "btn_delete_day":

                    this.deleteDayCalendar();

                    break;


                case "btn_malattia":

                    this.sezione = "Malattia";

                    this.currentMonthDisease = new Date().getMonth() + 1;

                    this.currentYearDisease = new Date().getFullYear();

                    setTimeout(() => {

                        this.resetDayDisease();

                        this.setupCalendarDisease();

                    }, 200);


                    break;

                case "btn_save_disease":

                    this.validateDisease();

                    break;

                case "btn_delete_disease":

                    this.deleteDayDisease();

                    break;

                case "btn_ins_nota_spese":

                    this.openPopupNotaSpese();

                    break;


                default:
                    break;
            }
        });






        setTimeout(() => {

            pointerVue.getProgetti();

        }, 1000);








    },

    mountedOLD() {
        //console.log ( "CAlendar" ,this.$refs.defCalendar );
        //var pointerTHis = this ;

        $("body").on("click", ".v-menu__content", function () {

            //$(this).hide();
            //  $(".selectHour").trigger("blur");
            //      window.pointerThis = pointerTHis ;

            //this.$refs.comboOrdinari.click();
            //      console.log ( "all refs" , window.refs );
            //$(".v-select--is-menu-active").find(".v-icon").trigger("click");

            /* $(".v-select--is-menu-active").find(".v-select__slot").trigger("blur");

            setTimeout(() => {
                $("body").trigger("click");

            }, 100); */

            //$(".v-select__slot").trigger("click");
            //window.refs.comboOrdinari.hide();

            //   this.$refs.combo.blur();

        });

    },
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];
        this.$root.$children[0].buttonsTop = [];

        //to manage if admin
        var namesurname = window.$cookies.get("namesurname");

        this.$root.$children[0].curDipTop = namesurname;
        this.$root.$children[0].curDipOreTop = "";
        this.$root.$children[0].curDipOreTopLabel = "";

        bus.$off("bottomIconClickEvent");
        bus.$off("menuClickEvent");
    },



    methods: {

        saveDataNotaSpese: async function () {

            var that = this;

            console.log("SPESE: ", that.spese);



            var v_token = window.$cookies.get("token");


            that.$root.$children[0].showProgress = true;

            var response = await apispese.validateInsertSpese(
                v_token,
                that.spese.data_trasferta,
                that.spese.amount
            ).then(async (res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from validateInsertSpese", res);

                if (res.data.Result == "OK") {

                    console.log("OK");

                    that.$modal.hide('popupNotaSpese');


                    that.$root.$children[0].showProgress = true;

                    var response2 = await apispese.insertSpese(
                        that.spese,
                        undefined,
                        v_token
                    ).then((res2) => {

                        that.$root.$children[0].showProgress = false;
                        console.log("res from insertSpese", res2);

                        var id_inserted = res2.data.IdInsert;

                        that.$swal

                            .fire({
                                title: "Approvazione",
                                html: "La spesa è stata inserita la si vuole inviare in approvazione ?",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                                cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                                customClass: {
                                    cancelButton: 'order-1',
                                    confirmButton: 'order-2',
                                }
                            })
                            .then(async function (result) {

                                if (result.value == true) {


                                    that.$root.$children[0].showProgress = true;

                                    var response3 = await apispese.validateForAppr(
                                        v_token,
                                    ).then(async (res3) => {

                                        console.log("res from validateForAppr", res3);

                                        if (res3.data.Result == "OK") {



                                            var response4 = await apispese.sendForAppr(
                                                v_token,
                                                id_inserted
                                            ).then((res4) => {

                                                that.$root.$children[0].showProgress = false;
                                                console.log("res from sendForAppr", res4);

                                                that.$swal({
                                                    icon: "success",
                                                    text: "Dati inviati correttamente",
                                                    showConfirmButton: false,
                                                    timer: 2000
                                                });

                                                setTimeout(() => {

                                                    that.resetDay();
                                                    that.syncMonth();

                                                }, 200);

                                            }).catch(err4 => {
                                                that.$root.$children[0].showProgress = false;
                                                console.log(err4);
                                                var msg2 = err4.response.data.Error;

                                                that.$swal({
                                                    icon: "error",
                                                    text: msg2,
                                                    showConfirmButton: false,
                                                    timer: 8000
                                                });
                                                console.log("Errori", "Non è stato possibile eliminare i dati");
                                                console.log("response", response4);

                                            }

                                            );




                                        }
                                        else {

                                            that.$swal({
                                                icon: "error",
                                                text: res3.data.ErrDetails,
                                                showConfirmButton: false,
                                                timer: 2000
                                            });



                                        }



                                    }).catch(err5 => {
                                        that.$root.$children[0].showProgress = false;
                                        console.log(err5);
                                        var msg = err5.response.data.Error;

                                        that.$swal({
                                            icon: "error",
                                            text: msg,
                                            showConfirmButton: false,
                                            timer: 8000
                                        });
                                        console.log("Errori", "Non è stato possibile eliminare i dati");
                                        console.log("response", response3);

                                    }

                                    );





                                }
                                else {

                                    setTimeout(() => {

                                        that.resetDay();
                                        that.syncMonth();

                                    }, 200);


                                }

                            });




                    }).catch(err2 => {
                        that.$root.$children[0].showProgress = false;
                        console.log(err2);
                        var msg = err2.response.data.Error;

                        that.$swal({
                            icon: "error",
                            text: msg,
                            showConfirmButton: false,
                            timer: 8000
                        });
                        console.log("Errori", "Non è stato possibile salvare i dati");
                        console.log("response", response2);

                    }

                    );







                }

                if (res.data.Result == "ERROR") {

                    console.log("ERROR");

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });



                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );








        },

        onChangeTipo: function (newValue) {

            var that = this;

            that.spese.idprojects_cdc_type = newValue;
            that.spese.km = 0;

            that.showKm = false;

            if (newValue == 20) {


                that.showKm = true;


            }


        },

        initInsertNotaSpese: async function () {

            var that = this;

            console.log("SPESE: ", that.spese);

            var v_token = window.$cookies.get("token");


            that.showSpinner = true;

            var response2 = await apispese.initInsert(
                v_token
            ).then((res2) => {

                console.log(response2);

                that.showSpinner = false;
                console.log("res from initInsert", res2);

                // inizializzo campi
                that.spese.description = "";
                that.spese.piva = "";
                that.spese.idprojects_cdc_type = "";
                that.spese.km = "";
                that.spese.note = "";
                that.spese.amount = "";

                setTimeout(() => {

                    $(".contpopupnotaspese").show();

                    that.$modal.show('popupNotaSpese');

                }, 200);

            }).catch(err2 => {
                that.$root.$children[0].showProgress = false;
                console.log(err2);
                var msg = err2.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );








        },


        btnCancelUpload: function () {

            var that = this;

            that.$modal.hide('popupUploadFile');

            setTimeout(() => {

                $(".contpopupnotaspese").show();

                that.$modal.show('popupNotaSpese');


            }, 200);

        },

        btnConfirmUpload: function () {

            var errore = "";

            var v_file = $("#input-file-attach")[0];

            if (v_file.files.length == 0) {
                errore = "Specificare un'allegato";
            }

            if (errore == "") {

                if (v_file.files.length > 0) {

                    // controllo estensione

                    v_file = v_file.files[0];

                    console.log("FILE 1: ", v_file);

                    var v_elements = v_file.name.split('.');

                    var v_ext_file = v_elements[v_elements.length - 1];

                    console.log("FILE ELEMENTS: ", v_elements);
                    console.log("FILE EXT: ", v_ext_file);


                    var v_ele_file_accept = this.fileAccettati.split(',');

                    console.log("FILE ELEMENTS ACCEPT: ", v_ele_file_accept);

                    var v_find_ext = 0;

                    for (var xx = 0; xx < v_ele_file_accept.length; xx++) {

                        var v_ext_file_tmp = "." + v_ext_file;

                        console.log(v_ext_file_tmp);

                        if (v_ext_file_tmp == v_ele_file_accept[xx]) {
                            v_find_ext = 1;
                        }

                    }

                    if (v_find_ext == 0) {
                        errore = "I formati accettati sono " + this.fileAccettati;
                    }



                    if (errore == "") {

                        // controllo dimensione file

                        if ((this.dimKbFilePresente < this.dimMinKbFile) || (this.dimKbFilePresente > this.dimMaxKbFile)) {


                            var strMin = (this.dimMinKbFile / 1000) + " Kb";

                            var strMax = (this.dimMaxKbFile / 1000000) + " Mb";

                            errore = "La grandezza del file deve essere compresa tra " + strMin + " e " + strMax;

                        }


                    }






                }

            }






            if (errore == "") {

                console.log("ok");

                this.uploadFile();

            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3500
                });


            }


        },

        uploadFile: async function () {



            var that = this;

            console.log("FATT FILE: ", that.fattura);

            var id_rec = 0;


            var v_file = $("#input-file-attach")[0].files[0];

            that.$modal.hide('popupUploadFile');


            that.attachPresent = true;


            console.log("ID_REC: ", id_rec);
            console.log("FILE: ", v_file);
            console.log("FOLDER SERVER: ", that.folderServerToSave);
            console.log("FOLDER DOX: ", that.folderDoxToSave);

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;




            var response3 = await apiuploadfile.setFileEasyer(
                id_rec,
                v_file,
                that.folderServerToSave,
                that.folderDoxToSave,
                "foto_easyer",
                v_token,
                that.removeUploadFile
            ).then((res2) => {

                console.log(response3);

                that.showSpinner = false;
                console.log("res from setFileEasyer", res2);


                that.$swal({
                    icon: "success",
                    text: "File caricato correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });


                setTimeout(() => {

                    $(".contpopupnotaspese").show();

                    that.$modal.show('popupNotaSpese');


                }, 2000);



            }).catch(err2 => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err2.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }


            );





        },


        openPopupUpload: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.nomeFilePresente = "";
            that.dimFilePresente = "";
            that.dimKbFilePresente = 0;


            var id_rec = 0;

            that.$root.$children[0].showProgress = true;



            var response3 = await apiuploadfile.getFile(
                id_rec,
                "foto_easyer",
                that.folderServerToSave,
                v_token
            ).then((res2) => {

                console.log(response3);

                that.$root.$children[0].showProgress = false;
                console.log("res from getFile", res2);


                that.dimMinKbFile = res2.data.MinFileSize;

                that.dimMaxKbFile = res2.data.MaxFileSize;

                that.$modal.hide('popupNotaSpese');


                setTimeout(() => {

                    $(".contpopupuploadfile").show();

                    that.$modal.show('popupUploadFile');


                    setTimeout(() => {


                        var oggDrop = $('.dropify').dropify({
                            messages: {
                                default: 'Drag',
                                replace: /* s */ '',
                                remove: 'Rimuovi',
                                error: 'File troppo grande'
                            }
                        });

                        $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
                        $(".dropify-render").append("<img>");

                        oggDrop.on('dropify.afterClear', function (event, element) {

                            console.log(event, element);

                            that.removeUploadFile = 1;

                            that.nomeFilePresente = "";
                            that.dimFilePresente = "";
                            that.dimKbFilePresente = 0;

                        });



                        $('#input-file-attach').on('change', function () {

                            that.removeUploadFile = 0;

                            var v_file = $("#input-file-attach")[0].files[0];

                            console.log("FILE: ", v_file);

                            that.nomeFilePresente = v_file.name;

                            var v_size = v_file.size;

                            that.dimKbFilePresente = v_size;

                            var str_size = "0 Kb";


                            if (v_size > 0) {

                                var v_division = v_size / 1000;

                                str_size = v_division.toFixed(1) + " Kb";


                            }



                            that.dimFilePresente = str_size;



                        });



                        console.log("FILE PRESENT: ", res2.data.NomeFile);

                        if (res2.data.NomeFile != null) {

                            if (res2.data.NomeFile != "") {

                                var elements = res2.data.NomeFile.split(".");

                                console.log("ELEMENTS: ", elements);

                                var v_ext = elements[elements.length - 1];

                                console.log("EXT: ", v_ext);

                                if (v_ext == "pdf") {

                                    $(".rowPrevFile").find('.dropify-render').append("<i class='dropify-font-file'></i>");
                                    $(".rowPrevFile").find('.dropify-render').append("<span class='dropify-extension'>" + v_ext + "</span>");
                                    $(".rowPrevFile").find(".dropify-wrapper").addClass("has-preview");
                                    $(".rowPrevFile").find(".dropify-preview").show("slow");

                                }
                                else {


                                    var v_url = this.getUrlDomain("public/" + this.folderServerToSave) + res2.data.NomeFile;




                                    $(".rowPrevFile").find('.dropify-render img').attr("src", v_url);
                                    $(".rowPrevFile").find(".dropify-wrapper").addClass("has-preview");
                                    $(".rowPrevFile").find(".dropify-preview").show("slow");


                                }




                                that.nomeFilePresente = res2.data.NomeFile;

                                that.dimKbFilePresente = res2.data.FileSize;


                                var str_size = "0 Kb";

                                var v_size = res2.data.FileSize;

                                if (v_size > 0) {

                                    var v_division = v_size / 1000;

                                    str_size = v_division.toFixed(1) + " Kb";


                                }



                                that.dimFilePresente = str_size;

                            }


                        }








                    }, 300);


                }, 300);






            }).catch(err2 => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err2.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }


            );

        },

        btnCancelInsNotaSpese: function () {

            this.$modal.hide('popupNotaSpese');


        },

        btnConfirmInsNotaSpese: function () {

            var that = this;

            var errore = "";

            if (that.attachPresent == false) {
                errore = "Specificare un'allegato";
            }


            if (errore == "") {

                if (that.spese.data_trasferta == "") {

                    errore = "Specificare la data trasferta";

                }

            }


            // if (errore == "") {

            //     if (!that.verificaDataTrasferta(that.spese.data_trasferta)) {

            //         errore = "La data trasferta deve essere minore o uguale ad oggi";

            //     }

            // }



            if (errore == "") {

                if (that.spese.description == "") {

                    errore = "Specificare la descrizione";

                }

            }



            if (errore == "") {

                if (that.spese.idprojects_cdc_type == "") {

                    errore = "Specificare il tipo";

                }

            }

            if (errore == "") {

                if (that.spese.idprojects_cdc_type == 20) {

                    if (that.spese.km == "") {

                        errore = "Specificare i km";


                    }


                }

            }


            if (errore == "") {

                if ($("#fldInpAmount").val() == "") {

                    errore = "Specificare l'importo";

                }

            }

            if (errore == "") {

                if (parseFloat($("#fldInpAmount").val()) == 0) {

                    errore = "Specificare l'importo";

                }

            }



            if (errore == "") {

                console.log("ok");

                this.saveDataNotaSpese();

            }
            else {


                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }

        },

        openPopupNotaSpese: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");


            that.showSpinner = true;

            var response = await apitimesheet.checkEnableNotaSpese(
                v_token
            ).then((res) => {

                that.showSpinner = false;
                console.log("res from checkEnableNotaSpese", res);

                if (res.data.Result == "S") {



                    if (that.date != "") {

                        console.log("PERIOD: ", that.date);
                        console.log("ANNO: ", that.curYear);
                        console.log("MESE: ", that.curMonth);
                        console.log("GIORNO: ", that.curDay);

                        var v_date = that.curYear.toString();
                        v_date = v_date + "-" + that.curMonth.toString().padStart(2, "0");
                        v_date = v_date + "-" + that.curDay.toString().padStart(2, "0");


                        console.log("V_DATE: ", v_date);

                        that.spese.data_trasferta = v_date;

                        that.initInsertNotaSpese();



                    }
                    else {

                        that.$swal({
                            icon: "error",
                            text: "Selezionare una data",
                            showConfirmButton: false,
                            timer: 3000
                        });

                    }



                }
                else {


                    that.$swal({
                        icon: "error",
                        text: "Funzione non abilitata",
                        showConfirmButton: false,
                        timer: 3000
                    });




                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });


                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },

        updateDaysForProgects: function () {

            console.log("PIANO_ORARIO: ", this.piano_orario[0]);
            var ore_totali = this.piano_orario[0].lunedi;
            console.log("LUNEDI: ", ore_totali);



            // rimuovo per il giorno corrente gli elementi che hanno ordinale maggiore di 1
            for (var xOrd = 2; xOrd <= this.countProjAssoc; xOrd++) {

                var keyToRemove = this.curDay + "_" + xOrd.toString();

                console.log("KEY: ", keyToRemove);

                if (keyToRemove in this.monthArray) {
                    delete this.monthArray[keyToRemove];
                }

            }


            // verifico su quanti progetti ho specificato le ore
            var cntProjSpecOre = 0;
            for (var xEle = 0; xEle < this.valuesProjects.length; xEle++) {
                if (this.valuesProjects[xEle].ore > 0) {
                    cntProjSpecOre = cntProjSpecOre + 1;
                }
            }

            if (cntProjSpecOre == 1) {

                // recupero l'id del progetto su cui ho specificato le ore
                var id_project = 0;
                var hour_project2 = 0;
                var codice2 = "";
                for (var xEle2 = 0; xEle2 < this.valuesProjects.length; xEle2++) {
                    if (this.valuesProjects[xEle2].ore > 0) {
                        id_project = this.valuesProjects[xEle2].projectid;
                        hour_project2 = this.valuesProjects[xEle2].ore;
                        codice2 = this.valuesProjects[xEle2].codice;
                    }
                }

                // aggiorno la struttura generale con l'id del progetto
                this.monthArray[this.curDay + "_1"].id_progetto = id_project;
                this.monthArray[this.curDay + "_1"].hour_ordinario = hour_project2;
                this.monthArray[this.curDay + "_1"].desc_progetto = codice2;


            }

            if (cntProjSpecOre > 1) {

                var id_project2 = 0;
                var hour_project = 0;
                var codice3 = "";

                var xCount = 1;
                for (var xEle3 = 0; xEle3 < this.valuesProjects.length; xEle3++) {

                    id_project2 = 0;
                    hour_project = 0;
                    if (this.valuesProjects[xEle3].ore > 0) {
                        id_project2 = this.valuesProjects[xEle3].projectid;
                        hour_project = this.valuesProjects[xEle3].ore;
                        codice3 = this.valuesProjects[xEle3].codice;
                    }

                    if (xCount == 1) {

                        var xKeyDay2 = this.curDay + "_" + xCount.toString();
                        this.monthArray[xKeyDay2].id_progetto = id_project2;
                        this.monthArray[xKeyDay2].hour_ordinario = hour_project;
                        this.monthArray[xKeyDay2].desc_progetto = codice3;

                        if (this.oreTotaliGiornataProgetti < ore_totali) {
                            this.monthArray[xKeyDay2].hour_activity = ore_totali - hour_project;
                        }

                    }

                    if (xCount > 1) {

                        var xKeyToCopy = this.curDay + "_1";
                        var xKeyDay3 = this.curDay + "_" + xCount.toString();

                        // inserisco la copia dell'elemento
                        if (xKeyToCopy in this.monthArray) {
                            this.monthArray[xKeyDay3] = { ...this.monthArray[xKeyToCopy] }; // Clona l'oggetto
                        }

                        // aggiorno il nuovo elemento
                        this.monthArray[xKeyDay3].id_progetto = id_project2;
                        this.monthArray[xKeyDay3].hour_ordinario = hour_project;
                        this.monthArray[xKeyDay3].desc_progetto = codice3;

                        if (this.oreTotaliGiornataProgetti < ore_totali) {
                            this.monthArray[xKeyDay3].hour_activity = ore_totali - hour_project;
                        }

                    }

                    xCount = xCount + 1;
                }


            }

            console.log("MONTH ARRAY: ", this.monthArray);

            this.$modal.hide('popupProgetti');

            this.syncMOnthIcons();

        },

        openPopupOreProgetti: function (event) {

            console.log("EVENTO: ", event);

            var that = this;

            console.log("ARRAY_MONTH: ", that.monthArray);

            var errore = "";

            var vDay = this.monthArray[that.curDay + "_1"];

            if (vDay.hour_ordinario == 0) {
                errore = "Specificare le ore ordinarie";
            }

            if (errore == "") {
                if (vDay.idtypeactivity > 0) {
                    if (vDay.hour_activity == 0) {
                        errore = "Specificare le ore di assenza";
                    }
                }
            }

            if (errore == "") {
                if (vDay.hour_activity > 0) {
                    if (vDay.idtypeactivity == 0) {
                        errore = "Specificare una causale";
                    }
                }
            }





            if (errore == "") {

                that.dataGiornataProgetti = that.curDay;

                // var totOre = 0;


                // for (let key in that.monthArray) {

                //     var curEle = that.monthArray[key];

                //     if (curEle.day == that.curDay) {
                //         totOre = totOre + parseFloat(curEle.hour_ordinario);
                //     }


                // }

                // that.oreTotaliGiornataProgetti = totOre;

                that.oreTotaliGiornataProgetti = that.forecast[0].quantity;

                // carico le ore attuali dei progetti del giorno selezionato nella struttura

                // resetto la struttura
                for (var xProj = 0; xProj < that.valuesProjects.length; xProj++) {
                    that.valuesProjects[xProj].ore = 0;
                }

                // carico i valori attuali dei progetti per il giorno selezionato
                for (var xOrd3 = 1; xOrd3 <= 3; xOrd3++) // fino ad un massimo di 3 progetti
                {

                    var xKeyDay = that.curDay + "_" + xOrd3.toString();

                    if (xKeyDay in that.monthArray) {

                        if (that.monthArray[xKeyDay].id_progetto != "") {
                            if (that.monthArray[xKeyDay].id_progetto > 0) {

                                for (var xProj2 = 0; xProj2 < that.valuesProjects.length; xProj2++) {

                                    if (that.monthArray[xKeyDay].id_progetto == that.valuesProjects[xProj2].projectid) {

                                        that.valuesProjects[xProj2].ore = that.monthArray[xKeyDay].hour_ordinario;

                                    }

                                }

                            }
                        }

                    }

                }




                setTimeout(() => {

                    $(".contpopuppprogetti").show();

                    that.$modal.show('popupProgetti');

                }, 200);

            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });



            }








        },

        btnConfirmOreProgetti: function () {

            var errore = "";

            var totOre = 0;
            for (var xx12 = 0; xx12 < this.valuesProjects.length; xx12++) {
                totOre = totOre + parseFloat(this.valuesProjects[xx12].ore)
            }

            if (totOre != parseFloat(this.oreTotaliGiornataProgetti)) {
                errore = "Le ore totali dei progetti devono essere " + this.oreTotaliGiornataProgetti;
            }

            if (errore == "") {

                console.log("ok");

                this.updateDaysForProgects();

            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });
            }



        },

        btnCancelOreProgetti: function () {

            this.$modal.hide('popupProgetti');

        },


        loadTotFeriePermessi: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;

            var response = await apitimesheet.getTotalsHolidaysPermits(
                v_token
            ).then((res) => {

                that.showSpinner = false;
                console.log("res from getTotalsHolidaysPermits", res);

                if (res.data.Result == "OK") {

                    that.oreTotFerieACAP = res.data.TotFerie;

                    that.oreTotPermessiACAP = res.data.TotPermessi;

                }
                else {


                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });




                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });


                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },


        deleteDayDisease: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var start = that.rangeDisease.start;

            var $mesi = [
                "Gennaio",
                "Febbraio",
                "Marzo",
                "Aprile",
                "Maggio",
                "Giugno",
                "Luglio",
                "Agosto",
                "Settembre",
                "Ottobre",
                "Novembre",
                "Dicembre",
            ];


            var startLiteral = start.getDate() + " " + $mesi[start.getMonth()] + " " + start.getFullYear();

            var txtMsg = "Sei sicuro di voler eliminare dalla malattia la data del <b>" + startLiteral + "</b>";

            that.$swal
                .fire({
                    title: "Sei sicuro?",
                    html: txtMsg,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        console.log("ok");

                        that.showSpinner = true;

                        var response = await apidisease.deleteDay(
                            that.idDisease,
                            that.daySelected,
                            v_token,
                            that.oreContratto
                        ).then((res) => {

                            that.showSpinner = false;
                            console.log("res from deleteDay", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.resetDayDisease();

                                that.setupCalendarDisease();


                            }, 2000);


                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );


                    }


                });


        },


        updateDisease: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");


            var strProtocolli = "";
            for (var xx5 = 0; xx5 < that.protocolli.length; xx5++) {
                if (strProtocolli != "") {
                    strProtocolli = strProtocolli + "#";
                }
                strProtocolli = strProtocolli + that.protocolli[xx5].data;
                strProtocolli = strProtocolli + ";" + that.protocolli[xx5].protocollo;
            }

            console.log("PROTOCOLLI: ", strProtocolli);
            console.log("ID DISEASE: ", that.idDisease);

            that.showSpinner = true;

            var response = await apidisease.updateDay(
                that.idDisease,
                strProtocolli,
                that.daySelected,
                v_token
            ).then((res) => {

                that.showSpinner = false;
                console.log("res from updateDay", res);


                that.$swal({
                    icon: "success",
                    text: "Dati aggiornati correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });



                setTimeout(() => {

                    that.resetDayDisease();
                    that.setupCalendarDisease();

                }, 2000);


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );


        },



        onBlurNumeroProtocollo: function () {

            console.log("ON_BLUR_NUMERO_PROTOCOLLO");

            console.log("ARRAY_PROTOCOLLI: ", this.protocolli);


            var protocolliNew = [];

            for (var xx = 0; xx < this.protocolli.length; xx++) {

                console.log("PROCOLLO 2: ", this.protocolli[xx]);


                if (this.daySelected != this.protocolli[xx].data) {
                    protocolliNew.push({
                        data: this.protocolli[xx].data,
                        protocollo: this.protocolli[xx].protocollo,
                    });
                }

            }

            protocolliNew.push({
                data: this.daySelected,
                protocollo: this.certificato,
            });


            console.log("PROTOCOLLI_NEW: ", protocolliNew);


            this.protocolli = protocolliNew;



        },

        insertDisease: function () {


            var that = this;

            var v_token = window.$cookies.get("token");

            var startDate = that.rangeDisease.start.getFullYear() + '-' + this.addZero((that.rangeDisease.start.getMonth() + 1)) + '-' + this.addZero(that.rangeDisease.start.getDate());

            var endDate = that.rangeDisease.end.getFullYear() + '-' + this.addZero((that.rangeDisease.end.getMonth() + 1)) + '-' + this.addZero(that.rangeDisease.end.getDate());

            var notes = that.textNote;

            var certificato = "";

            console.log("V_TOKEN: ", v_token);
            console.log("START_DATE: ", startDate);
            console.log("END_DATE: ", endDate);
            console.log("NOTE: ", notes);


            var $mesi = [
                "Gennaio",
                "Febbraio",
                "Marzo",
                "Aprile",
                "Maggio",
                "Giugno",
                "Luglio",
                "Agosto",
                "Settembre",
                "Ottobre",
                "Novembre",
                "Dicembre",
            ];

            var start = that.rangeDisease.start;
            var end = that.rangeDisease.end;

            console.log("START: ", start);
            console.log("END: ", end);


            var startLiteral = start.getDate() + " " + $mesi[start.getMonth()] + " " + start.getFullYear();

            var endLiteral = end.getDate() + " " + $mesi[end.getMonth()] + " " + end.getFullYear();

            var txtMsg = "";

            if (startLiteral == endLiteral) {
                txtMsg = "Vuoi inserire la <b>Malattia</b> per il <b>" + startLiteral + "</b> ?";

            }

            if (startLiteral != endLiteral) {
                txtMsg = "Vuoi inserire la <b>Malattia</b> dal <b>" + startLiteral + "</b> al <b>" + endLiteral + "</b> ?";

            }



            this.$swal
                .fire({
                    title: "Sei sicuro ?",
                    html: txtMsg,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }
                })
                .then((result) => {
                    if (result.value == true) {


                        var API_URL = this.getUrlDomainApi("insertDiseaseNew");

                        var v_key = this.generateRandomString(100, "gWnKdU7d4Sb58hFbL4G6");
                        v_key = v_key + "|" + v_token.toString();
                        v_key = v_key + "|" + startDate.toString();
                        v_key = v_key + "|" + endDate.toString();
                        v_key = v_key + "|" + certificato.toString();
                        v_key = v_key + "|" + notes.toString();
                        v_key = v_key + "|1";
                        v_key = v_key + "|" + this.generateRandomString(100, "gWnKdU7d4Sb58hFbL4G6");
                        v_key = btoa(v_key);
                        v_key = this.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6") + v_key + this.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");



                        that.showSpinner = true;


                        $.ajax({
                            type: "POST",
                            url: API_URL,
                            data: {
                                "v_token": v_key

                            },
                            success: function (resultData) {

                                that.showSpinner = false;

                                console.log("resultData", resultData, that);

                                that.$swal({
                                    icon: "success",
                                    text: "La richiesta è stata salvata correttamente",
                                    showConfirmButton: false,
                                    timer: 2000
                                });



                                setTimeout(() => {

                                    that.resetDayDisease();
                                    that.setupCalendarDisease();

                                }, 2000);

                            },
                            error: function (errorData) {
                                console.log(errorData);
                                var msg = errorData.responseJSON.Error;

                                that.$swal({
                                    icon: "error",
                                    text: msg,
                                    showConfirmButton: false,
                                    timer: 3000
                                });
                                that.showSpinner = false;

                            }
                        });


                    }
                });



        },


        insertDiseaseWithProtocollo: function () {


            var that = this;

            that.$modal.hide('popupProtocollo');

            var v_token = window.$cookies.get("token");

            var startDate = that.rangeDisease.start.getFullYear() + '-' + this.addZero((that.rangeDisease.start.getMonth() + 1)) + '-' + this.addZero(that.rangeDisease.start.getDate());

            var endDate = that.rangeDisease.end.getFullYear() + '-' + this.addZero((that.rangeDisease.end.getMonth() + 1)) + '-' + this.addZero(that.rangeDisease.end.getDate());

            var notes = that.textNote;

            var certificato = that.numeroProtocollo;

            console.log("V_TOKEN: ", v_token);
            console.log("START_DATE: ", startDate);
            console.log("END_DATE: ", endDate);
            console.log("NOTE: ", notes);
            console.log("PRTOCOLLO: ", certificato);



            var $mesi = [
                "Gennaio",
                "Febbraio",
                "Marzo",
                "Aprile",
                "Maggio",
                "Giugno",
                "Luglio",
                "Agosto",
                "Settembre",
                "Ottobre",
                "Novembre",
                "Dicembre",
            ];

            var start = that.rangeDisease.start;
            var end = that.rangeDisease.end;

            console.log("START: ", start);
            console.log("END: ", end);


            var startLiteral = start.getDate() + " " + $mesi[start.getMonth()] + " " + start.getFullYear();

            var endLiteral = end.getDate() + " " + $mesi[end.getMonth()] + " " + end.getFullYear();

            var txtMsg = "";

            if (startLiteral == endLiteral) {
                txtMsg = "Vuoi inserire la <b>Malattia</b> per il <b>" + startLiteral + "</b> ?";

            }

            if (startLiteral != endLiteral) {
                txtMsg = "Vuoi inserire la <b>Malattia</b> dal <b>" + startLiteral + "</b> al <b>" + endLiteral + "</b> ?";

            }



            this.$swal
                .fire({
                    title: "Sei sicuro ?",
                    html: txtMsg,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }
                })
                .then((result) => {
                    if (result.value == true) {


                        var API_URL = this.getUrlDomainApi("insertDiseaseNew");

                        var v_key = this.generateRandomString(100, "gWnKdU7d4Sb58hFbL4G6");
                        v_key = v_key + "|" + v_token.toString();
                        v_key = v_key + "|" + startDate.toString();
                        v_key = v_key + "|" + endDate.toString();
                        v_key = v_key + "|" + certificato.toString();
                        v_key = v_key + "|" + notes.toString();
                        v_key = v_key + "|1";
                        v_key = v_key + "|" + this.generateRandomString(100, "gWnKdU7d4Sb58hFbL4G6");
                        v_key = btoa(v_key);
                        v_key = this.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6") + v_key + this.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");



                        that.showSpinner = true;


                        $.ajax({
                            type: "POST",
                            url: API_URL,
                            data: {
                                "v_token": v_key

                            },
                            success: function (resultData) {

                                that.showSpinner = false;

                                console.log("resultData", resultData, that);

                                that.$swal({
                                    icon: "success",
                                    text: "La richiesta è stata salvata correttamente",
                                    showConfirmButton: false,
                                    timer: 2000
                                });



                                setTimeout(() => {

                                    that.resetDayDisease();
                                    that.setupCalendarDisease();

                                }, 2000);

                            },
                            error: function (errorData) {
                                console.log(errorData);
                                var msg = errorData.responseJSON.Error;

                                that.$swal({
                                    icon: "error",
                                    text: msg,
                                    showConfirmButton: false,
                                    timer: 3000
                                });
                                that.showSpinner = false;

                            }
                        });


                    }
                });



        },

        btnConfirmProtocollo: function () {

            var that = this;

            var errore = "";

            if (that.numeroProtocollo == "") {
                errore = "Specificare un numero di protocollo";
            }

            if (errore == "") {

                console.log("ok");

                that.insertDiseaseWithProtocollo();

            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });


            }


        },

        openPopupProtocollo: function () {

            var that = this;

            that.numeroProtocollo = "";

            setTimeout(() => {

                $(".contpopupprotocollo").show();

                that.$modal.show('popupProtocollo');

            }, 200);




        },

        saveDataDisease: function () {


            var that = this;


            if (that.isUpdateDisease == 0) {


                that.$swal
                    .fire({
                        title: "Numero di protocollo",
                        html: "Hai già un numero di protocollo da specificare per il periodo ?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "<img title='Si' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                        cancelButtonText: "<img title='No' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                        customClass: {
                            cancelButton: 'order-1',
                            confirmButton: 'order-2',
                        }
                    })
                    .then((result) => {
                        if (result.value == true) {

                            console.log("ok");

                            that.openPopupProtocollo();


                        }
                        else {

                            console.log("ok");

                            that.insertDisease();

                        }
                    });



            }



            if (that.$route.params.id > 0) {


                console.log("ok");




            }

        },

        checkDisease: async function () {

            var that = this;

            var start = that.rangeDisease.start;
            var end = that.rangeDisease.end;

            var startLiteral = start.getFullYear() + "-" + (start.getMonth() + 1).toString().padStart(2, "0") + "-" + start.getDate().toString().padStart(2, "0");

            var endLiteral = end.getFullYear() + "-" + (end.getMonth() + 1).toString().padStart(2, "0") + "-" + end.getDate().toString().padStart(2, "0");

            var response;

            try {

                that.showSpinner = true;



                response = await apiholidaypermits.checkRequestHolidayRis(
                    startLiteral,
                    endLiteral
                ).then((res) => {

                    that.showSpinner = false;
                    console.log("res from checkRequestHolidayRis", res);

                    if (res.data.Result == "OK") {

                        console.log("ok");

                        that.saveDataDisease();


                    }
                    else {
                        that.$swal({
                            icon: "error",
                            text: res.data.ErrDetails,
                            showConfirmButton: false,
                            timer: 3000
                        });
                    }



                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile eliminare questa richiesta");
                    console.log("response", response);

                }

                );


            } catch (error) {
                that.$root.$children[0].showProgress = false;
                console.log(error);

            }


        },

        addZero: function (number) {
            return (number < 10 ? '0' : '') + number;
        },

        validateDisease() {

            var errore = "";

            console.log("PROTOCOLLI: ", this.protocolli);


            if (this.isUpdateDisease == 0) {



                console.log("this.range", this.rangeDisease);

                var start = this.rangeDisease.start;

                if (start == undefined) {
                    errore = "Selezionare il periodo";
                }

                if (errore == "") {

                    var dt_start = new Date(this.rangeDisease.start).getTime();
                    var dt_end = new Date(this.rangeDisease.end).getTime();

                    console.log("DATE START: ", dt_start);
                    console.log("DATE END: ", dt_end);

                    var current_date = new Date();
                    var formattedDate = current_date.getFullYear() + '-' + this.addZero((current_date.getMonth() + 1)) + '-' + this.addZero(current_date.getDate());

                    console.log("CURRENT DATE: ", formattedDate);

                    current_date = new Date(formattedDate).getTime();

                    if ((dt_start < current_date) || (dt_end < current_date)) {


                        errore = "Data Inizio e Data Fine devono essere maggiori o uguali della Data Corrente";



                    }

                }




                if (errore == "") {

                    console.log("ok");

                    if (this.isUpdateDisease == 0) {

                        this.checkDisease();
                    }




                }
                else {

                    this.$swal({
                        icon: "error",
                        text: errore,
                        showConfirmButton: false,
                        timer: 3000
                    });


                }

            }

            if (this.isUpdateDisease == 1) {

                console.log("ok");

                var numProtocolloOk = 1;

                for (var xx3 = 0; xx3 < this.protocolli.length; xx3++) {

                    if (this.protocolli[xx3].protocollo == "") {
                        numProtocolloOk = 0;
                    }

                }


                if (numProtocolloOk == 0) {
                    errore = "Specificare il numero di protocollo per tutti i giorni del periodo";
                }




                if (errore == "") {

                    console.log("ok");

                    this.updateDisease();


                }
                else {

                    this.$swal({
                        icon: "error",
                        text: errore,
                        showConfirmButton: false,
                        timer: 3000
                    });


                }


            }

        },

        setupButtonsDisease: function (v_enable, is_enable_delete) {

            var pulsantis = [];

            if (v_enable) {

                pulsantis.push(



                    {
                        text: "",
                        icon: "mdi-refresh",
                        image: "https://app.emtool.eu/public/_lib/img/icon_save_emt.png",
                        link: "",
                        id: "btn_save_disease",
                        disabled: false,
                        title: "Salva",
                        width: 28
                    },


                );

                if (is_enable_delete == 1) {

                    pulsantis.push(



                        {
                            text: "",
                            icon: "mdi-refresh",
                            image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                            link: "",
                            id: "btn_delete_disease",
                            disabled: false,
                            title: "Elimina",
                            width: 30
                        },


                    );


                }

            }

            pulsantis.push(



                {
                    text: "",
                    icon: "mdi-refresh",
                    image: "https://app.emtool.eu/public/_lib/img/timesheet3.png",
                    link: "",
                    id: "btn_timesheet",
                    disabled: false,
                    title: "Timesheet",
                    width: 28
                },


            );

            this.$root.$children[0].bnavbuttons = pulsantis;

        },

        setupCalendarDisease: async function () {


            var startDate = this.currentYearDisease.toString() + "-" + this.currentMonthDisease.toString().padStart(2, "0") + "-01";

            const today = new Date();
            var lastDay = new Date(today.getFullYear(), this.currentMonthDisease, 0).getDate();

            var endDate = this.currentYearDisease.toString() + "-" + this.currentMonthDisease.toString().padStart(2, "0") + "-" + lastDay.toString().padStart(2, "0");

            console.log("DATE START: ", startDate);
            console.log("DATE END: ", endDate);





            var v_token = window.$cookies.get("token");

            var username = btoa(window.$cookies.get("username"));


            var API_URL = this.getUrlDomainApi("getDiseaseCalendar");

            var that = this;
            that.showSpinner = true;
            var todos = [];

            var datesDisabled = [];
            var arrayProtocolli = [];

            $.ajax({
                type: "POST",
                url: API_URL,
                data: {
                    "v_user": username,
                    "username": v_token,
                    "v_id": that.$route.params.id
                },
                success: function (resultData) {
                    that.showSpinner = false;
                    console.log("resultData", resultData, that);
                    var arry = Object.entries(resultData.Disease.giorni);
                    var arry2 = Object.entries(resultData.Protocolli2);

                    console.log("ARRY2: ", arry2);

                    $(arry2).each(function (oki, ok) {

                        arrayProtocolli.push({
                            data: ok[0],
                            protocollo: ok[1],
                        });


                    });

                    console.log("ARRAY_PROTOCOLLI: ", arrayProtocolli);

                    that.protocolli = arrayProtocolli;

                    that.periodoDalUpd = resultData.PeriodoDal;
                    that.periodoAlUpd = resultData.PeriodoAl;

                    that.textNote = resultData.Note;


                    that.confCalendar = arry;

                    that.PeriodiDisabilitati = resultData.MesiDisabledTimesheet;

                    that.PeriodiEditAbilitati = resultData.MesiEditEnabled;

                    var oreContratto = resultData.OreContratto;

                    that.oreContratto = resultData.OreContratto;





                    that.oreTotaliMalattia = 0;

                    $(arry).each(function (oki, ok) {
                        console.log("CIAO", ok[0]);

                        var curDate = new Date(ok[0] + "T00:00:00Z");
                        var formattedDate = curDate.toISOString().split("T")[0];



                        if ((formattedDate >= startDate) && (formattedDate <= endDate)) {
                            that.oreTotaliMalattia += parseFloat(oreContratto);
                        }

                        console.log("OK1: ", ok[1]);

                        var elements3 = ok[1].split("|");

                        var v_color = elements3[0];

                        var v_desc = "";
                        if (elements3[1] != "") {
                            v_desc = "N. Protocollo " + elements3[1];
                        }


                        todos.push({
                            description: v_desc,
                            dates: ok[0],
                            color: v_color,
                            isComplete: true,
                            dot: true
                        });


                        // if (that.isUpdateDisease == 0) {

                        //     console.log("INSERT");


                        //     var elements = ok[0].split("-");

                        //     console.log(elements);


                        //     var v_anno = parseInt(elements[0]);
                        //     var v_mese = parseInt(elements[1]) - 1;
                        //     var v_giorno = parseInt(elements[2]);


                        //     console.log(v_anno);
                        //     console.log(v_mese);
                        //     console.log(v_giorno);


                        //     datesDisabled.push({
                        //         start: new Date(v_anno, v_mese, v_giorno),
                        //         end: new Date(v_anno, v_mese, v_giorno)
                        //     });

                        // }





                    });
                    that.todos = todos;


                    for (var xx = 0; xx < resultData.GiorniDisabledTimesheet.length; xx++) {


                        var dateSearch = resultData.GiorniDisabledTimesheet[xx];

                        var findDay = 0;
                        for (var yy = 0; yy < datesDisabled.length; yy++) {

                            var giorno55 = datesDisabled[yy].start.getDate(); // Giorno (1-31)
                            var mese55 = datesDisabled[yy].start.getMonth() + 1; // Mese (0-11, quindi +1)
                            var anno55 = datesDisabled[yy].start.getFullYear(); // Anno (es. 2025)

                            var strDate = anno55.toString() + "-" + mese55.toString().padStart(2, "0") + "-" + giorno55.toString().padStart(2, "0");


                            if (strDate == dateSearch) {
                                findDay = 1;
                            }

                        }


                        if (findDay == 0) {

                            var elements33 = dateSearch.split("-");


                            var v_anno33 = parseInt(elements33[0]);
                            var v_mese33 = parseInt(elements33[1]) - 1;
                            var v_giorno33 = parseInt(elements33[2]);

                            datesDisabled.push({
                                start: new Date(v_anno33, v_mese33, v_giorno33),
                                end: new Date(v_anno33, v_mese33, v_giorno33)
                            });

                        }



                    }






                    const oggi = new Date();
                    var strCurDate = oggi.getFullYear().toString() + "|" + (oggi.getMonth() + 1).toString();



                    console.log("CURDATE: ", strCurDate);

                    var disInterface = 0;

                    for (var jj = 0; jj <= resultData.MesiDisabledTimesheet.length; jj++) {
                        if (strCurDate == resultData.MesiDisabledTimesheet[jj]) {
                            disInterface = 1;
                        }
                    }

                    console.log("DISINTERFACE: ", disInterface);
                    console.log("VIEW_SELEZIONE: ", that.viewSelezione);
                    console.log("VIEW_NOTE: ", that.viewNote);


                    if (disInterface == 1) {

                        that.viewSelezione = false;
                        that, viewProtocollo = false;
                        that.viewNote = false;

                        that.setupButtons(false);

                    }



                    console.log("DATE_DISABLE 2: ", datesDisabled);

                    that.disabledDatesDisease = datesDisabled;


                    that.setupButtonsDisease(true, 0);

                }
            });

        },

        onMonthChangeDisease(newMonth) {

            var that = this;

            console.log("Nuovo mese selezionato:", newMonth);
            console.log("CONF CALENDAR:", that.confCalendar);
            console.log("PERIODO DISABLED:", that.PeriodiDisabilitati);


            if (that.confCalendar != null) {


                var startDate = newMonth.year.toString() + "-" + newMonth.month.toString().padStart(2, "0") + "-01";

                var lastDay = new Date(newMonth.year, newMonth.month, 0).getDate();

                var endDate = newMonth.year.toString() + "-" + newMonth.month.toString().padStart(2, "0") + "-" + lastDay.toString().padStart(2, "0");

                console.log("DATE START: ", startDate);
                console.log("DATE END: ", endDate);

                var arry = that.confCalendar;

                that.oreTotaliMalattia = 0;



                $(arry).each(function (oki, ok) {
                    console.log("CIAO", ok[0]);

                    var elements2 = ok[1].split("|");

                    console.log("ELEMENTS2: ", elements2);


                    var curDate = new Date(ok[0] + "T00:00:00Z");
                    var formattedDate = curDate.toISOString().split("T")[0];

                    console.log("CURDATE: ", formattedDate);
                    console.log("CURDATE_STARTDATE: ", startDate);
                    console.log("CURDATE_ENDDATE: ", endDate);

                    if ((formattedDate >= startDate) && (formattedDate <= endDate)) {
                        that.oreTotaliMalattia += parseFloat(that.oreContratto);
                    }







                });




                var curPeriod = newMonth.year.toString() + "|" + newMonth.month.toString();

                console.log("CURPERIOD: ", curPeriod);

                var disInterface2 = 0;

                for (var jj = 0; jj <= that.PeriodiDisabilitati.length; jj++) {
                    if (curPeriod == that.PeriodiDisabilitati[jj]) {
                        disInterface2 = 1;
                    }
                }

                console.log("DISINTERFACE 3: ", disInterface2);
                console.log("VIEW_SELEZIONE 3: ", that.viewSelezione);
                console.log("VIEW_NOTE 3: ", that.viewNote);


                if (disInterface2 == 0) {

                    that.setupButtonsDisease(true, 0);

                }

                if (disInterface2 == 1) {

                    that.setupButtonsDisease(false, 0);
                    that.viewSelezione = false;
                    that.viewProtocollo = false;
                    that.viewNote = false;
                }




            }


        },

        onDayClickDisease(day) {


            var that = this;

            console.log("DAY: ", day);
            console.log("RANGE: ", that.rangeDisease);
            console.log("CONF CALENDAR: ", that.confCalendar);

            //console.log("DATES DISABLED: ", this.disabledDatesCal);

            var cur_date = day.year.toString() + "-" + day.month.toString().padStart(2, '0') + "-" + day.day.toString();

            //console.log("CUR DATE: ", cur_date);

            that.daySelected = day.id;


            var is_disabled = 0;
            for (var xx6 = 0; xx6 < that.disabledDatesDisease.length; xx6++) {

                var date_disabled = new Date(that.disabledDatesDisease[xx6].start);
                var giorno = date_disabled.getDate();
                var mese = date_disabled.getMonth() + 1;
                var anno = date_disabled.getFullYear();

                var dataDisabled = anno.toString() + "-" + mese.toString().padStart(2, '0') + "-" + giorno.toString().padStart(2, '0');

                //console.log("DATE DISABLED: ", dataDisabled);

                if (cur_date == dataDisabled) {
                    is_disabled = 1;
                }
            }

            console.log("DAY_DISABLED: ", is_disabled);

            var viewFields = 0;

            that.isUpdateDisease = 0;

            if (is_disabled == 0) {

                if ((that.rangeDisease.start != undefined) && (that.rangeDisease.end != undefined)) {

                    var dateStart = new Date(that.rangeDisease.start);
                    var giornoStart = dateStart.getDate();
                    var meseStart = dateStart.getMonth() + 1;
                    var annoStart = dateStart.getFullYear();
                    var startDate = annoStart.toString() + "-" + meseStart.toString().padStart(2, '0') + "-" + giornoStart.toString().padStart(2, '0');

                    var dateEnd = new Date(that.rangeDisease.end);
                    var giornoEnd = dateEnd.getDate();
                    var meseEnd = dateEnd.getMonth() + 1;
                    var annoEnd = dateEnd.getFullYear();
                    var endDate = annoEnd.toString() + "-" + meseEnd.toString().padStart(2, '0') + "-" + giornoEnd.toString().padStart(2, '0');

                    console.log("START DATE: ", startDate);
                    console.log("END DATE: ", endDate);


                    if (startDate == endDate) {
                        viewFields = 1;
                    }


                    if (startDate != endDate) {

                        console.log("ok");

                        let currentDate = new Date(startDate);
                        let stopDate = new Date(endDate);
                        let dateArray = [];

                        while (currentDate <= stopDate) {
                            dateArray.push(currentDate.toISOString().split('T')[0]); // Formatta la data come YYYY-MM-DD
                            currentDate.setDate(currentDate.getDate() + 1); // Incrementa di un giorno
                        }

                        console.log("PERIOD: ", dateArray);

                        var findDay = 0;

                        for (var xx9 = 0; xx9 < dateArray.length; xx9++) {

                            var vDay = dateArray[xx9];

                            $(that.confCalendar).each(function (oki, ok) {

                                console.log("DATA", ok[0]);

                                if (vDay == ok[0]) {
                                    findDay = 1;
                                }

                            });


                        }

                        console.log("FIND DAY: ", findDay);

                        if (findDay == 0) {
                            viewFields = 1;
                        }

                        if (findDay == 1) {

                            that.$swal({
                                icon: "errore",
                                text: "La modifica del numero di protocollo è possibile solo selezionando un singolo giorno",
                                showConfirmButton: false,
                                timer: 2000
                            });


                        }


                    }


                    if (viewFields == 1) {

                        var strProtocollo = "";
                        var strNote = "";
                        var isEnableDelete = 0;

                        $(that.confCalendar).each(function (oki, ok) {

                            console.log("DATA", ok[0]);

                            var elements55 = ok[1].split("|");

                            console.log("ELEMENTS: ", elements55);

                            if (cur_date == ok[0]) {
                                strProtocollo = elements55[1];
                                strNote = elements55[2];
                                that.idDisease = elements55[3];
                                isEnableDelete = elements55[4];

                                that.isUpdateDisease = 1;


                            }


                        });

                        console.log("PROTOCOLLO: ", strProtocollo);
                        console.log("NOTE: ", strNote);


                        that.dayFormatted = day.ariaLabel;
                        that.viewSelezione = true;
                        that.viewNote = true;

                        that.setupButtonsDisease(true, isEnableDelete);

                        if (that.isUpdateDisease == 1) {
                            that.viewProtocollo = true;
                            that.certificato = strProtocollo;
                            that.textNote = strNote;
                        }

                        if (that.isUpdateDisease == 0) {
                            that.certificato = "";
                            that.viewProtocollo = false;
                            that.textNote = "";
                        }




                    }


                }

            }

            console.log("VIEW FIELDS: ", viewFields);



        },


        deleteDayCalendar: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var start = that.rangeCal.start;

            var $mesi = [
                "Gennaio",
                "Febbraio",
                "Marzo",
                "Aprile",
                "Maggio",
                "Giugno",
                "Luglio",
                "Agosto",
                "Settembre",
                "Ottobre",
                "Novembre",
                "Dicembre",
            ];


            var startLiteral = start.getDate() + " " + $mesi[start.getMonth()] + " " + start.getFullYear();

            var txtMsg = "Sei sicuro di voler eliminare dalla richiesta la data del <b>" + startLiteral + "</b>";

            that.$swal
                .fire({
                    title: "Sei sicuro?",
                    html: txtMsg,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        console.log("ok");

                        that.showSpinner = true;

                        var response = await apiholidaypermits.deleteDayHolidays(
                            that.idHeaderDayForUpdate,
                            that.daySelForUpdate,
                            v_token
                        ).then((res) => {

                            that.showSpinner = false;
                            console.log("res from deleteDayHolidays", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dayFormatted = "";
                                that.viewCausale = false;
                                that.viewNote = false;

                                that.setupButtonsFerie(true, 0);
                                that.resetDayFerie();
                                that.setupCalendarFerie();


                            }, 2000);


                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );


                    }


                });


        },

        updateDayCalendar: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");


            var vTimeStart = that.timeStartCal;
            var vTimeEnd = that.timeEndCal;

            if (that.causale == 1) {
                if (that.tipoGiornata == "G_COMPLETA") {
                    vTimeStart = "09:00";
                    if (that.oreContratto == 4) {
                        vTimeEnd = "13:00";
                    }
                    if (that.oreContratto == 8) {
                        vTimeEnd = "18:00";
                    }
                }
            }

            vTimeStart = vTimeStart + ":00";
            vTimeEnd = vTimeEnd + ":00";


            console.log("ID HEADER: ", that.idHeaderDayForUpdate);
            console.log("GIORNO: ", that.daySelForUpdate);
            console.log("CAUSALE: ", that.causale);
            console.log("TIPO GIORNATA: ", that.tipoGiornata);
            console.log("ORA INIZIO: ", vTimeStart);
            console.log("ORA FINE: ", vTimeEnd);
            console.log("NOTE: ", that.notes);


            var $mesi = [
                "Gennaio",
                "Febbraio",
                "Marzo",
                "Aprile",
                "Maggio",
                "Giugno",
                "Luglio",
                "Agosto",
                "Settembre",
                "Ottobre",
                "Novembre",
                "Dicembre",
            ];

            var start = that.rangeCal.start;


            var startLiteral = start.getDate() + " " + $mesi[start.getMonth()] + " " + start.getFullYear();


            var txtMsg = "";

            if (that.causale == 1) // ferie
            {

                if (that.tipoGiornata == "G_COMPLETA") {

                    txtMsg = "Vuoi aggiornare il giorno <b>" + startLiteral + "</b> con la causale <b>Ferie</b> ?";

                }

                if (that.tipoGiornata == "G_PARZIALE") {

                    if (vTimeStart == vTimeEnd) {


                        txtMsg = "Vuoi aggiornare il giorno <b>" + startLiteral + "</b> con la causale <b>Ferie</b> alle ore <b>" + vTimeStart + "</b> ?";

                    }

                    if (vTimeStart != vTimeEnd) {

                        txtMsg = "Vuoi aggiornare il giorno <b>" + startLiteral + "</b> con la causale <b>Ferie</b> dalle ore <b>" + vTimeStart + "</b> alle ore <b>" + vTimeEnd + "</b> ?";

                    }


                }

            }

            if (that.causale != 1) {

                var descType = "";
                if (that.causale == 2) {
                    descType = "Permesso Retribuito";
                }
                if (that.causale == 3) {
                    descType = "Permesso Non Retribuito";
                }
                if (that.causale == 6) {
                    descType = "Trasferta";
                }
                if (that.causale == 8) {
                    descType = "Permesso Studio";
                }
                if (that.causale == 10) {
                    descType = "Donazione Sangue";
                }

                if (vTimeStart == vTimeEnd) {


                    txtMsg = "Vuoi aggiornare il giorno <b>" + startLiteral + "</b> con la causale <b>" + descType + "</b> alle ore <b>" + vTimeStart + "</b> ?";

                }


                if (vTimeStart != vTimeEnd) {


                    txtMsg = "Vuoi aggiornare il giorno <b>" + startLiteral + "</b> con la causale <b>" + descType + "</b> dalle ore <b>" + vTimeStart + "</b> alle ore <b>" + vTimeEnd + "</b> ?";

                }



            }

            that.$swal
                .fire({
                    title: "Sei sicuro?",
                    html: txtMsg,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        console.log("ok");

                        that.showSpinner = true;

                        var response = await apiholidaypermits.updateDayHolidays(
                            that.idHeaderDayForUpdate,
                            that.daySelForUpdate,
                            that.causale,
                            vTimeStart,
                            vTimeEnd,
                            that.notes,
                            v_token
                        ).then((res) => {

                            that.showSpinner = false;
                            console.log("res from updateDayHolidays", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati aggiornati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dayFormatted = "";
                                that.viewCausale = false;
                                that.viewNote = false;

                                that.resetDayFerie();
                                that.setupCalendarFerie();


                            }, 2000);


                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );


                    }



                });



        },

        resetDayDisease: function () {
            this.rangeDisease = {};
            this.rangeDetailDisease = "";
            this.viewSelezione = false;
            this.viewProtocollo = false;
            this.viewNote = false;
            this.certificato = "";
            this.textNote = "";
        },

        resetDayFerie: function () {
            this.rangeCal = {};
            this.rangeDetailCal = "";
            this.causale = "";
            this.tipoGiornata = "G_COMPLETA";
            this.vediGiornata = false;
            this.timeEndCal = "";
            this.timeStartCal = "";
            this.singleMode = false;
            this.notes = "";
        },

        insertAndSendRequestHolidays: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            var $mesi = [
                "Gennaio",
                "Febbraio",
                "Marzo",
                "Aprile",
                "Maggio",
                "Giugno",
                "Luglio",
                "Agosto",
                "Settembre",
                "Ottobre",
                "Novembre",
                "Dicembre",
            ];

            var start = that.rangeCal.start;
            var end = that.rangeCal.end;

            console.log("START: ", start);
            console.log("END: ", end);


            var startLiteral = start.getDate() + " " + $mesi[start.getMonth()] + " " + start.getFullYear();

            var endLiteral = end.getDate() + " " + $mesi[end.getMonth()] + " " + end.getFullYear();

            var txtMsg = "";

            if (that.causale == 1) // ferie
            {

                if (that.tipoGiornata == "G_COMPLETA") {

                    if (startLiteral == endLiteral) {
                        txtMsg = "Vuoi inserire la richiesta di <b>Ferie</b> per il <b>" + startLiteral + "</b> ?";

                    }

                    if (startLiteral != endLiteral) {
                        txtMsg = "Vuoi inserire la richiesta di <b>Ferie</b> dal <b>" + startLiteral + "</b> al <b>" + endLiteral + "</b> ?";

                    }



                }

                if (that.tipoGiornata == "G_PARZIALE") {

                    if (startLiteral == endLiteral) {
                        txtMsg = "Vuoi inserire la richiesta di <b>Ferie</b> per il <b>" + startLiteral + "</b> dalle ore <b>" + that.timeStartCal + "</b> alle ore <b>" + that.timeEndCal + "</b> ?";

                    }

                    if (startLiteral != endLiteral) {
                        txtMsg = "Vuoi inserire la richiesta di <b>Ferie</b> dal <b>" + startLiteral + "</b> dalle ore <b>" + that.timeStartCal + "</b> al <b>" + endLiteral + "</b> alle ore <b>" + that.timeEndCal + "</b> ?";

                    }


                }

            }


            if (that.causale != 1) {

                var descType = "";
                if (that.causale == 2) {
                    descType = "Permesso Retribuito";
                }
                if (that.causale == 3) {
                    descType = "Permesso Non Retribuito";
                }
                if (that.causale == 6) {
                    descType = "Trasferta";
                }
                if (that.causale == 8) {
                    descType = "Permesso Studio";
                }
                if (that.causale == 10) {
                    descType = "Donazione Sangue";
                }

                if (startLiteral == endLiteral) {
                    txtMsg = "Vuoi inserire la richiesta di <b>" + descType + "</b> per il <b>" + startLiteral + "</b> dalle ore <b>" + that.timeStartCal + "</b> alle ore <b>" + that.timeEndCal + "</b> ?";

                }


                if (startLiteral != endLiteral) {
                    txtMsg = "Vuoi inserire la richiesta di <b>" + descType + "</b> dal <b>" + startLiteral + "</b> dalle ore <b>" + that.timeStartCal + "</b> al <b>" + endLiteral + "</b> alle ore <b>" + that.timeEndCal + "</b> ?";

                }



            }











            that.$swal
                .fire({
                    title: "Sei sicuro?",
                    //html: "Vuoi inserire la richiesta ?",
                    html: txtMsg,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {


                        console.log(v_token);

                        var elabTime = 0;

                        if (that.causale == 1) {

                            if (that.tipoGiornata == "G_PARZIALE") {
                                elabTime = 1;
                            }

                        }
                        else {
                            elabTime = 1;
                        }





                        var v_start_time = "";

                        if (elabTime == 1) {

                            v_start_time = that.timeStartCal;

                        }
                        else {
                            v_start_time = "09:00:00";
                        }




                        var v_end_time = "";

                        if (elabTime == 1) {

                            v_end_time = that.timeEndCal;

                        }
                        else {

                            if (that.oreContratto == 8) {
                                v_end_time = "18:00:00";
                            }

                            if (that.oreContratto == 4) {
                                v_end_time = "13:00:00";
                            }

                        }

                        var start = that.rangeCal.start;
                        var end = that.rangeCal.end;

                        var v_start_date = start.getFullYear() + "-" + (start.getMonth() + 1).toString().padStart(2, "0") + "-" + start.getDate().toString().padStart(2, "0");

                        var v_end_date = end.getFullYear() + "-" + (end.getMonth() + 1).toString().padStart(2, "0") + "-" + end.getDate().toString().padStart(2, "0");

                        console.log("DATA INIZIO: ", v_start_date);
                        console.log("ORA INIZIO: ", v_start_time);
                        console.log("DATA FINE: ", v_end_date);
                        console.log("ORA FINE: ", v_end_time);

                        var parType = "";
                        if (that.causale == 1) parType = "ferie";
                        if (that.causale == 2) parType = "permesso retribuito";
                        if (that.causale == 3) parType = "permesso non retribuito";
                        if (that.causale == 6) parType = "trasferta";
                        if (that.causale == 8) parType = "permesso studio";
                        if (that.causale == 10) parType = "donazione sangue";





                        var v_key = that.generateRandomString(100, "gWnKdU7d4Sb58hFbL4G6");
                        v_key = v_key + "|" + v_token.toString();
                        v_key = v_key + "|" + v_start_date.toString();
                        v_key = v_key + "|" + v_end_date.toString();
                        v_key = v_key + "|" + parType.toString();
                        v_key = v_key + "|" + v_start_time.toString();
                        v_key = v_key + "|" + v_end_time.toString();
                        v_key = v_key + "|" + that.causale.toString();
                        v_key = v_key + "|" + that.notes.toString();
                        v_key = v_key + "|" + that.generateRandomString(100, "gWnKdU7d4Sb58hFbL4G6");

                        console.log("V_KEY: ", v_key);




                        v_key = btoa(v_key);
                        v_key = that.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6") + v_key + that.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");

                        console.log(v_key);

                        that.showSpinner = true;



                        var response = await apiholidaypermits.insHolidayNew(
                            v_key
                        ).then((res) => {

                            var v_id_inserted = res.data.IdInserted;

                            console.log("res from insHolidayNew", res);

                            var API_URL = that.getUrlDomain("sendMultiRequestHolidaysPermitsNew");

                            var v_key2 = that.generateRandomString(100, "gWnKdU7d4Sb58hFbL4G6");
                            v_key2 = v_key2 + "|" + v_token.toString();
                            v_key2 = v_key2 + "|" + v_id_inserted.toString();
                            v_key2 = v_key2 + "|" + that.generateRandomString(100, "gWnKdU7d4Sb58hFbL4G6");
                            v_key2 = btoa(v_key2);
                            v_key2 = that.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6") + v_key2 + that.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");

                            $.ajax({
                                type: "GET",
                                url: API_URL,
                                data: {
                                    "v_token": v_key2
                                },
                                success: function (resultData) {


                                    that.showSpinner = false;
                                    console.log("resultData", resultData, that);

                                    that.$swal({
                                        icon: "success",
                                        text: "La richiesta è stata inserita correttamente, tra circa 30 minuti verra approvata e sarà visibile nel timesheet",
                                        showConfirmButton: false,
                                        timer: 4000
                                    });



                                    setTimeout(() => {

                                        that.resetDayFerie();
                                        that.setupCalendarFerie();

                                    }, 4000);


                                },
                                error: function (errorData) {


                                    that.showSpinner = false;

                                    console.log(errorData);
                                    var msg = errorData.responseJSON.Error;

                                    that.$swal({
                                        icon: "error",
                                        text: msg,
                                        showConfirmButton: false,
                                        timer: 3000
                                    });
                                    that.$root.$children[0].showProgress = false;

                                }
                            });




                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );






                    }

                });



        },

        checkHolidays: async function () {

            var that = this;

            var start = that.rangeCal.start;
            var end = that.rangeCal.end;

            var startLiteral = start.getFullYear() + "-" + (start.getMonth() + 1).toString().padStart(2, "0") + "-" + start.getDate().toString().padStart(2, "0");

            var endLiteral = end.getFullYear() + "-" + (end.getMonth() + 1).toString().padStart(2, "0") + "-" + end.getDate().toString().padStart(2, "0");

            var response;

            try {

                that.showSpinner = true;



                response = await apiholidaypermits.checkRequestHolidayRis(
                    startLiteral,
                    endLiteral
                ).then((res) => {

                    that.showSpinner = false;
                    console.log("res from checkRequestHolidayRis", res);

                    if (res.data.Result == "OK") {

                        console.log("ok");

                        that.insertAndSendRequestHolidays();


                    }
                    else {
                        that.$swal({
                            icon: "error",
                            text: res.data.ErrDetails,
                            showConfirmButton: false,
                            timer: 3000
                        });
                    }



                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile eliminare questa richiesta");
                    console.log("response", response);

                }

                );


            } catch (error) {
                that.$root.$children[0].showProgress = false;
                console.log(error);

            }


        },


        validateRequestHolidays() {

            var errore = "";

            console.log("this.range", this.range);

            var start = this.rangeCal.start;
            var end = this.rangeCal.end;



            if (start == undefined) {
                errore = "Selezionare il periodo";
            }

            if (errore == "") {
                if (this.causale == "") {
                    errore = "Selezionare la causale";
                }

            }

            if (errore == "") {
                if (this.causale == 1) { // ferie
                    if (this.tipoGiornata == "G_PARZIALE") {
                        if (this.timeStartCal == "") {
                            errore = "Selezionare l'orario di inizio";
                        }
                    }
                }
            }

            if (errore == "") {
                if (this.causale == 1) { // ferie
                    if (this.tipoGiornata == "G_PARZIALE") {
                        if (this.timeEndCal == "") {
                            errore = "Selezionare l'orario di fine";
                        }
                    }
                }
            }

            if (errore == "") {
                if (this.causale != 1) {
                    if (this.timeStartCal == "") {
                        errore = "Selezionare l'orario di inizio";
                    }
                }
            }

            if (errore == "") {
                if (this.causale != 1) {
                    if (this.timeEndCal == "") {
                        errore = "Selezionare l'orario di fine";
                    }
                }
            }

            if (errore == "") {
                if (this.causale == 1) { // ferie
                    if (this.tipoGiornata == "G_PARZIALE") {

                        var startLiteral = start.getFullYear() + "-" + (start.getMonth() + 1).toString().padStart(2, "0") + "-" + start.getDate().toString().padStart(2, "0");

                        var endLiteral = end.getFullYear() + "-" + (end.getMonth() + 1).toString().padStart(2, "0") + "-" + end.getDate().toString().padStart(2, "0");

                        console.log("START DATE: ", startLiteral);
                        console.log("END DATE: ", endLiteral);

                        console.log("START TIME: ", this.timeStartCal);
                        console.log("END TIME: ", this.timeEndCal);

                        startLiteral = startLiteral + "T" + this.timeStartCal + ":00";
                        endLiteral = endLiteral + "T" + this.timeEndCal + ":00";

                        const date1 = new Date(startLiteral);
                        const date2 = new Date(endLiteral);

                        if (date1 >= date2) {
                            errore = "Periodo non valido la data/ora di fine deve essere maggiore di quella di inizio";
                        }

                    }
                }
            }

            if (errore == "") {
                if (this.causale != 1) {

                    var startLiteral2 = start.getFullYear() + "-" + (start.getMonth() + 1).toString().padStart(2, "0") + "-" + start.getDate().toString().padStart(2, "0");

                    var endLiteral2 = end.getFullYear() + "-" + (end.getMonth() + 1).toString().padStart(2, "0") + "-" + end.getDate().toString().padStart(2, "0");

                    console.log("START DATE 2: ", startLiteral2);
                    console.log("END DATE 2: ", endLiteral2);

                    console.log("START TIME: ", this.timeStartCal);
                    console.log("END TIME: ", this.timeEndCal);

                    startLiteral2 = startLiteral2 + "T" + this.timeStart + ":00";
                    endLiteral2 = endLiteral2 + "T" + this.timeEnd + ":00";

                    const date1 = new Date(startLiteral2);
                    const date2 = new Date(endLiteral2);

                    if (date1 >= date2) {
                        errore = "Periodo non valido la data/ora di fine deve essere maggiore di quella di inizio";
                    }


                }
            }

            if (errore == "") {

                console.log("ok");

                if (this.updateDay == 0) {
                    this.checkHolidays();

                }

                if (this.updateDay == 1) {
                    this.updateDayCalendar();

                }


            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });


            }


        },





        loadCausaliFerie: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var response = await apiholidaypermits.getHolPermReq(
                v_token
            ).then((res) => {

                console.log(response);

                console.log("res from getHolPermReq", res);

                var causali = res.data.Result;

                that.causaliFerie = causali;

                that.oreContratto = res.data.OreContr;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }


            );



        },

        sumHoursCal: function (event) {

            var operation = "+";

            if (event.target.classList.contains('minus')) {
                operation = '-';
            }

            if (event) {
                var dataid = event.target.getAttribute("data-id");

                var disabled = event.target.attributes.getNamedItem('disabled');
                var strDay = "";

                var lista_orari = this.lista_orari;

                console.log("LISTA_ORARI 2:", lista_orari);
                console.log("DATAID:", dataid);
                console.log("STRDAY:", strDay);
                console.log("OPERATION:", operation);

                if (disabled == null) {

                    var newIndex = 0;

                    for (var ind in lista_orari) {

                        if (dataid == 'startHour' && operation == '+') {
                            if (lista_orari[ind] == this.timeStartCal) {

                                newIndex = parseInt(ind) + 1;
                            }

                        }

                        if (dataid == 'startHour' && operation == '-') {
                            if (lista_orari[ind] == this.timeStartCal) {

                                newIndex = parseInt(ind) - 1;
                            }

                        }

                        if (dataid == 'endHour' && operation == '+') {
                            if (lista_orari[ind] == this.timeEndCal) {

                                newIndex = parseInt(ind) + 1;
                            }

                        }

                        if (dataid == 'endHour' && operation == '-') {
                            if (lista_orari[ind] == this.timeEndCal) {

                                newIndex = parseInt(ind) - 1;
                            }

                        }

                    }


                    var startIndex = 0;
                    var endIndex = lista_orari.length - 1;

                    console.log("START INDEX: ", startIndex);
                    console.log("END INDEX: ", endIndex);

                    if (dataid == "endHour") {

                        if ((newIndex >= startIndex) && (newIndex <= endIndex)) {

                            this.timeEndCal = lista_orari[newIndex.toString()];

                        }


                    }

                    if (dataid == "startHour") {

                        if ((newIndex >= startIndex) && (newIndex <= endIndex)) {

                            this.timeStartCal = lista_orari[newIndex.toString()];

                        }


                    }



                    console.log("NewIndex", newIndex);

                }


            }

        },

        updateSumDayCal: async function () {

        },

        chgRadio: function () {

            console.log("CHANGE: ", this.tipoGiornata);

            this.setSingleMode(false);

            if (this.tipoGiornata == "G_COMPLETA") {

                this.setSingleMode(true);
            }


        },

        setSingleMode(mode) {
            this.singleMode = mode;
        },

        onChangeCausale: function (newValue) {

            console.log("VALUE: ", newValue);

            this.vediGiornata = false;
            this.setSingleMode(false);

            if (newValue == 1) {

                this.vediGiornata = true;
            }

            if (newValue != 1) {

                this.setSingleMode(true);
            }



        },

        setTimeCal: function () {
            this.timeStartCal = "";
            this.timeEndCal = "";
        },

        setupButtonsFerie: function (v_enable, is_edit) {

            var pulsantis = [];

            if (v_enable) {

                pulsantis.push(



                    {
                        text: "",
                        icon: "mdi-refresh",
                        image: "https://app.emtool.eu/public/_lib/img/icon_save_emt.png",
                        link: "",
                        id: "btn_save_request_holiday",
                        disabled: false,
                        title: "Salva",
                        width: 28
                    },


                );

                if (is_edit == 1) {

                    pulsantis.push(



                        {
                            text: "",
                            icon: "mdi-refresh",
                            image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                            link: "",
                            id: "btn_delete_day",
                            disabled: false,
                            title: "Elimina",
                            width: 30
                        },


                    );


                }

            }

            pulsantis.push(



                {
                    text: "",
                    icon: "mdi-refresh",
                    image: "https://app.emtool.eu/public/_lib/img/timesheet3.png",
                    link: "",
                    id: "btn_timesheet",
                    disabled: false,
                    title: "Timesheet",
                    width: 28
                },


            );

            this.$root.$children[0].bnavbuttons = pulsantis;

        },

        getUrlDomainApi: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/public/api/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/api/";

            }

            return result + nome_metodo;

        },

        setupCalendarFerie: async function () {

            //ajax

            var startDate = this.currentYearFerie.toString() + "-" + this.currentMonthFerie.toString().padStart(2, "0") + "-01";

            const today = new Date();
            var lastDay = new Date(today.getFullYear(), this.currentMonthFerie, 0).getDate();

            var endDate = this.currentYearFerie.toString() + "-" + this.currentMonthFerie.toString().padStart(2, "0") + "-" + lastDay.toString().padStart(2, "0");

            console.log("DATE START: ", startDate);
            console.log("DATE END: ", endDate);

            var mesi = [
                "",
                "Gennaio",
                "Febbraio",
                "Marzo",
                "Aprile",
                "Maggio",
                "Giugno",
                "Luglio",
                "Agosto",
                "Settembre",
                "Ottobre",
                "Novembre",
                "Dicembre",
            ];

            this.mesePeriodo = mesi[this.currentMonthFerie];


            var v_token = window.$cookies.get("token");

            var username = btoa(window.$cookies.get("username"));
            var API_URL = this.getUrlDomainApi("getHolidaysPermitsCalendar");


            var that = this;
            that.showSpinner = true;
            var todos = [];
            var datesDisabled = [];

            $.ajax({
                type: "POST",
                url: API_URL,
                data: {
                    "v_user": username,
                    "username": v_token,


                },
                success: function (resultData) {
                    that.showSpinner = false;
                    console.log("resultData", resultData, that);
                    var arry = Object.entries(resultData.HolidaysPermits.giorni);

                    that.confCalendar = arry;

                    that.PeriodiDisabilitati = resultData.MesiDisabledTimesheet;

                    console.log("ARRY: ", arry);

                    that.oreTotFerie = 0;
                    that.oreTotPermRetr = 0;


                    $(arry).each(function (oki, ok) {
                        console.log("CIAO", ok[0]);

                        var elements2 = ok[1].split("|");

                        console.log("ELEMENTS2: ", elements2);


                        var curDate = new Date(ok[0] + "T00:00:00Z");
                        var formattedDate = curDate.toISOString().split("T")[0];

                        // console.log("CURDATE: ", formattedDate);
                        // console.log("CURDATE_STARTDATE: ", startDate);
                        // console.log("CURDATE_ENDDATE: ", endDate);


                        var v_color = elements2[0];
                        if (elements2[1] == "Ferie") {
                            // v_color = "yellow";

                            if ((formattedDate >= startDate) && (formattedDate <= endDate)) {
                                that.oreTotFerie += parseFloat(elements2[3]);
                            }



                        }
                        if (elements2[1] == "Permesso Retribuito") {
                            //v_color = "blue";



                            if ((formattedDate >= startDate) && (formattedDate <= endDate)) {
                                that.oreTotPermRetr += parseFloat(elements2[3]);
                            }

                        }



                        var vDescOre = "Ora";
                        if (parseFloat(elements2[3]) > 1) {
                            vDescOre = "Ore";
                        }

                        todos.push({
                            description: elements2[1] + " (" + elements2[3] + " " + vDescOre + ")",
                            dates: ok[0],
                            color: v_color,
                            isComplete: true,
                            dot: true
                        });

                        // devo aggiungere la data al mio array delle date da disabilitare
                        // perchè gia occupate

                        if (elements2[2] == 1) {

                            if (v_color != "yellow") {

                                var elements = ok[0].split("-");
                                var v_anno = parseInt(elements[0]);
                                var v_mese = parseInt(elements[1]) - 1;
                                var v_giorno = parseInt(elements[2]);


                                datesDisabled.push({
                                    start: new Date(v_anno, v_mese, v_giorno),
                                    end: new Date(v_anno, v_mese, v_giorno)
                                });


                            }


                        }





                    });

                    that.todos = todos;



                    for (var xx = 0; xx < resultData.GiorniDisabledTimesheet.length; xx++) {


                        var dateSearch = resultData.GiorniDisabledTimesheet[xx];

                        //console.log("DATESEARCH: ", dateSearch);

                        var findDay = 0;
                        for (var yy = 0; yy < datesDisabled.length; yy++) {

                            var giorno55 = datesDisabled[yy].start.getDate(); // Giorno (1-31)
                            var mese55 = datesDisabled[yy].start.getMonth() + 1; // Mese (0-11, quindi +1)
                            var anno55 = datesDisabled[yy].start.getFullYear(); // Anno (es. 2025)

                            var strDate = anno55.toString() + "-" + mese55.toString().padStart(2, "0") + "-" + giorno55.toString().padStart(2, "0");


                            //console.log("GIORNO ALREADY DISABLED: ", strDate);

                            if (strDate == dateSearch) {
                                findDay = 1;
                            }

                        }

                        //console.log("FINDDAY: ", findDay);

                        if (findDay == 0) {

                            var elements33 = dateSearch.split("-");

                            //console.log("ELEMENTS33: ", elements33);

                            var v_anno33 = parseInt(elements33[0]);
                            var v_mese33 = parseInt(elements33[1]) - 1;
                            var v_giorno33 = parseInt(elements33[2]);

                            datesDisabled.push({
                                start: new Date(v_anno33, v_mese33, v_giorno33),
                                end: new Date(v_anno33, v_mese33, v_giorno33)
                            });

                        }



                    }


                    that.disabledDatesCal = datesDisabled;

                    const oggi = new Date();
                    var strCurDate = oggi.getFullYear().toString() + "|" + (oggi.getMonth() + 1).toString();

                    console.log("CURDATE: ", strCurDate);

                    var disInterface = 0;

                    for (var jj = 0; jj <= resultData.MesiDisabledTimesheet.length; jj++) {
                        if (strCurDate == resultData.MesiDisabledTimesheet[jj]) {
                            disInterface = 1;
                        }
                    }

                    console.log("DISINTERFACE: ", disInterface);


                    if (disInterface == 1) {

                        that.viewSelezione = false;
                        that.viewCausale = false;
                        that.viewNote = false;

                        that.setupButtonsFerie(false, 0);



                    }




                }
            });

        },

        onMonthChangeCal(newMonth) {

            var that = this;

            console.log("MESE SELEZIONATO:", newMonth);
            console.log("CONF CALENDAR:", that.confCalendar);
            console.log("PERIODO DISABLED:", that.PeriodiDisabilitati);

            var mesi = [
                "",
                "Gennaio",
                "Febbraio",
                "Marzo",
                "Aprile",
                "Maggio",
                "Giugno",
                "Luglio",
                "Agosto",
                "Settembre",
                "Ottobre",
                "Novembre",
                "Dicembre",
            ];

            that.mesePeriodo = mesi[newMonth.month];






            var startDate = newMonth.year.toString() + "-" + newMonth.month.toString().padStart(2, "0") + "-01";

            var lastDay = new Date(newMonth.year, newMonth.month, 0).getDate();

            var endDate = newMonth.year.toString() + "-" + newMonth.month.toString().padStart(2, "0") + "-" + lastDay.toString().padStart(2, "0");

            console.log("DATE START: ", startDate);
            console.log("DATE END: ", endDate);

            var arry = that.confCalendar;

            that.oreTotFerie = 0;
            that.oreTotPermRetr = 0;


            $(arry).each(function (oki, ok) {
                console.log("CIAO", ok[0]);

                var elements2 = ok[1].split("|");

                console.log("ELEMENTS2: ", elements2);


                var curDate = new Date(ok[0] + "T00:00:00Z");
                var formattedDate = curDate.toISOString().split("T")[0];

                console.log("CURDATE: ", formattedDate);
                console.log("CURDATE_STARTDATE: ", startDate);
                console.log("CURDATE_ENDDATE: ", endDate);



                if (elements2[1] == "Ferie") {


                    if ((formattedDate >= startDate) && (formattedDate <= endDate)) {
                        that.oreTotFerie += parseFloat(elements2[3]);
                    }



                }
                if (elements2[1] == "Permesso Retribuito") {




                    if ((formattedDate >= startDate) && (formattedDate <= endDate)) {
                        that.oreTotPermRetr += parseFloat(elements2[3]);
                    }

                }







            });




            var curPeriod = newMonth.year.toString() + "|" + newMonth.month.toString();

            console.log("CURPERIOD: ", curPeriod);

            var disInterface2 = 0;

            for (var jj = 0; jj <= that.PeriodiDisabilitati.length; jj++) {
                if (curPeriod == that.PeriodiDisabilitati[jj]) {
                    disInterface2 = 1;
                }
            }

            console.log("DISINTERFACE2: ", disInterface2);


            if (disInterface2 == 0) {

                that.setupButtonsFerie(true, 0);
                that.viewSelezione = false;
                that.viewCausale = false;
                that.viewNote = false;
            }

            if (disInterface2 == 1) {

                that.setupButtonsFerie(false, 0);
                that.viewSelezione = false;
                that.viewCausale = false;
                that.viewNote = false;
                that.vediGiornata = false;
                that.singleMode = false;
            }


        },


        ultimoGiornoData: function (anno, mese) {

            var res = new Date(anno, mese, 0).toISOString().split('T')[0];

            var elems = res.split("-");

            return parseInt(elems[2]) + 1;
        },

        validate() {

            console.log("ARRAY_MONTH: ", this.monthArray);



            var errore = "";

            var ultimo_giorno = this.ultimoGiornoData(this.curYear, this.curMonth);

            console.log("ULTIMO GIORNO: ", ultimo_giorno);

            if (this.IsPartitaIva == "S") {


                for (var xx4 = 1; xx4 <= ultimo_giorno; xx4++) {

                    if (errore == "") {

                        var vDay = this.monthArray[xx4 + "_1"];

                        if (vDay.is_festivo == 0) {

                            if (vDay.hour_activity > 0) {
                                if (vDay.idtypeactivity == 0) {
                                    errore = "Specificare una causale per il giorno " + vDay.day;
                                }
                            }

                        }




                    }

                    if (errore == "") {

                        var vDay2 = this.monthArray[xx4 + "_1"];

                        if (vDay2.is_festivo == 0) {

                            if (vDay2.idtypeactivity > 0) {
                                if (vDay2.hour_activity == 0) {
                                    errore = "Specificare le ore di assenza per il giorno " + vDay2.day;
                                }
                            }

                        }




                    }

                }



            }


            if (errore == "") {
                console.log("ok");

                if (this.countProjAssoc <= 1) {

                    this.saveData();
                }

                if (this.countProjAssoc > 1) {

                    this.saveDataMoreProjs();
                }



            }
            else {
                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });

            }

        },


        onChangeViewInterv: function (newValue) {

            console.log("NEW VALUE: ", newValue);

            if (newValue == "S") {
                $("#Intervento").show();
                $("#Dalle").show();
                $("#Alle").show();
            }

            if (newValue == "N") {
                $("#Intervento").hide();
                $("#Dalle").hide();
                $("#Alle").hide();
            }


        },

        onChangeDescription: function (value) {

            this.monthArray[this.curDay + "_1"].note = value;

        },

        hideShowSecNav: function (v_show) {

            if (v_show) {

                $("#btn_indietro_dip").show();
                $("#name_dip_sel").show();
                $("#btn_avanti_dip").show();

            }
            else {


                $("#btn_indietro_dip").hide();
                $("#name_dip_sel").hide();
                $("#btn_avanti_dip").hide();

            }



        },


        getProgetti: async function () {

            console.log("GETPROGETTI: ", window.user_selected);


            var that = this;

            var sel_login_ver_ts = window.user_selected;



            var response = await apitimesheet.getProgetti(

                sel_login_ver_ts

            ).then((res) => {


                console.log("res from getProgetti", res);


                that.progetti = res.data.Result;


                that.oreContratto = res.data.OreContratto;

            }).catch(err => {

                that.showSpinner = false;

                window.numEventASaveAndOpen = 0;

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },

        manageClickProgetti: async function (progetto) {

            this.sheetProgetti = false;

            console.log("PROGETTO: ", progetto);

            window.curProgetto = progetto.projectid;


            this.syncMonth();




        },

        goToHoliday: function () {

            window.$cookies.set("is_from_ts_user", 1, "9y");

            router.push({
                path: "/initHoliday"
            });


        },

        goToDisease: function () {

            window.$cookies.set("is_from_ts_user", 1, "9y");

            router.push({
                path: "/initDisease"
            });


        },

        copyRow: async function () {

            var that = this;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });


            console.log("IDS: ", ids);



            that.showSpinner = true;

            var response = await apitimesheet.copyRow(
                ids
            ).then((res) => {

                that.showSpinner = false;

                console.log("res from copyRow", res);

                that.$swal({
                    icon: "success",
                    text: "Dati copiati con successo",
                    showConfirmButton: false,
                    timer: 2000
                });

                window.numEventASave = 0;

                that.syncMonth();

            }).catch(err => {

                that.showSpinner = false;

                window.numEventASaveAndOpen = 0;

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },

        deleteRow: async function () {

            var that = this;

            var str_values = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (str_values != "") {
                        str_values = str_values + "#";
                    }

                    var v_login = $(this).attr("v_login");
                    var v_day = $(this).attr("v_day");
                    var v_month = $(this).attr("v_month");
                    var v_year = $(this).attr("v_year");
                    var v_ordine = $(this).attr("v_ordine");

                    //this.deleteRowTs(v_login, v_day, v_month, v_year, v_ordine);

                    str_values = str_values + v_login + "|" + v_day + "|" + v_month + "|" + v_year + "|" + v_ordine;

                }

            });


            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare gli elementi selezionati ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {


                        console.log("VALUES: ", str_values);



                        that.showSpinner = true;

                        var response = await apitimesheet.deleteRowTsMultiNew(
                            str_values
                        ).then((res) => {

                            that.showSpinner = false;

                            console.log("res from deleteRowTsMultiNew", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati con successo",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            window.numEventASave = 0;

                            that.syncMonth();

                        }).catch(err => {

                            that.showSpinner = false;

                            window.numEventASaveAndOpen = 0;

                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );




                    }





                });


        },


        formatDateTime: function (v_datetime) {

            if (v_datetime != "") {

                if (v_datetime.toString().indexOf("T") !== -1) {


                    v_datetime = v_datetime.toString().substring(11, 16);
                    v_datetime = v_datetime + ":00";


                }

            }

            return v_datetime;

        },


        hideShowButtonsSelRow: function () {


            console.log("STATUS: ", this.curStatus);


            var tot = 0;
            var n_prog = 0;
            var tot_day_ass = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                    n_prog = $(this).attr("v_prog_assoc");

                    var v_idtype_activity = $(this).attr("v-idtypeactivity");

                    if (v_idtype_activity > 0) {
                        tot_day_ass = tot_day_ass + 1;
                    }


                }

            });

            setTimeout(() => {

                var pulsantis = [];

                if (this.curStatus == "Open") {

                    pulsantis.push(

                        {
                            text: "Ricarica",
                            icon: "mdi-refresh",
                            image: "https://app.emtool.eu/public/_lib/img/refresh-emt.png",
                            link: "",
                            id: "btn_refr_tms",
                            disabled: false,
                            title: "Aggiorna",
                            width: 30
                        },



                    );

                    pulsantis.push(



                        {
                            text: "",
                            icon: "mdi-refresh",
                            image: "https://app.emtool.eu/public/_lib/img/icon_save_emt.png",
                            link: "",
                            id: "btn_save",
                            disabled: false,
                            title: "Salva",
                            width: 28
                        },



                    );


                    pulsantis.push(



                        {
                            text: "",
                            icon: "mdi-refresh",
                            image: "https://app.emtool.eu/public/_lib/img/cambio-stato-emt.png",
                            link: "",
                            id: "btn_send_tms",
                            disabled: false,
                            title: "Invia Per Approvazione",
                            width: 35
                        },



                    );


                    if (tot > 0) {

                        if (tot_day_ass == 0) {

                            pulsantis.push(

                                {
                                    text: "Delete",
                                    icon: "mdi-home-circle",
                                    image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                                    link: "/info",
                                    id: "btn_delete",
                                    disabled: false,
                                    title: "Elimina",
                                    width: 30
                                },



                            );

                        }




                    }

                    if (tot > 0) {


                        if (n_prog > 1) {

                            if (tot == 1) {

                                pulsantis.push(




                                    {
                                        text: "",
                                        icon: "mdi-refresh",
                                        image: this.getUrlDomain("public/_lib/img/copia-emt.png"),
                                        link: "",
                                        id: "btn_copy",
                                        disabled: false,
                                        title: "Copia",
                                        width: 35
                                    },



                                );


                            }









                        }



                    }

                    pulsantis.push(



                        {
                            text: "",
                            icon: "mdi-refresh",
                            image: this.getUrlDomain("public/_lib/img/enable_disable_buttons_emt.png"),
                            link: "",
                            id: "btn_en_dis_interv",
                            disabled: false,
                            title: "Abilita / Disabilita Intervento",
                            width: 35
                        },



                    );


                    var is_partita_iva = window.$cookies.get("is_partita_iva");

                    if (is_partita_iva == 0) {

                        pulsantis.push(



                            {
                                text: "",
                                icon: "mdi-refresh",
                                image: this.getUrlDomain("public/_lib/img/malattia4.png"),
                                link: "",
                                id: "btn_go_disease",
                                disabled: false,
                                title: "Malattia",
                                width: 30
                            },



                        );

                        pulsantis.push(



                            {
                                text: "",
                                icon: "mdi-refresh",
                                image: this.getUrlDomain("public/_lib/img/ferie2.png"),
                                link: "",
                                id: "btn_go_ferie",
                                disabled: false,
                                title: "Ferie / Permessi",
                                width: 30
                            },



                        );


                    }






                }


                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);



        },

        viewColumns: function (v_enable) {
            window.table.column(10).visible(v_enable);
            window.table.column(11).visible(v_enable);
            window.table.column(12).visible(v_enable);
        },


        enableViewColumns: function () {

            var that = this;

            if (that.showColumns == true) {
                window.table.column(10).visible(false);
                window.table.column(11).visible(false);
                window.table.column(12).visible(false);
                that.showColumns = false;
            }
            else {

                window.table.column(10).visible(true);
                window.table.column(11).visible(true);
                window.table.column(12).visible(true);
                that.showColumns = true;


            }


        },

        updateTotalRows: function () {

            var dataTableElements = window.table.data();

            console.log("DATA TOTAL ROWS: ", dataTableElements);

            for (var xDay = 0; xDay < dataTableElements.length; xDay++) {

                var vRowDay = dataTableElements[xDay];

                var total = parseFloat(vRowDay.hour_ordinario) + parseFloat(vRowDay.hour_activity);

                var keyTotal = "#sel_ore_totali_" + vRowDay.day.toString();

                $(keyTotal).html(total.toFixed(2));


            }



        },


        colorRows: function () {

            var dataTableElements = window.table.data();

            var numCol = 14;

            for (var xDay = 0; xDay < dataTableElements.length; xDay++) {


                console.log("ELEMENTS TR2: ", dataTableElements[xDay]);

                var vRowDay = dataTableElements[xDay];

                if (vRowDay.desc_day != "Dom") {

                    if (vRowDay.desc_day != "Sab") {


                        if (vRowDay.fest_gen == 1) {

                            for (var xCol3 = 0; xCol3 <= numCol; xCol3++) {


                                $("#myTable > tbody > tr").eq(xDay).find("td").eq(xCol3).addClass("clRowFest");

                            }


                        }


                        if (vRowDay.fest_gen != 1) {

                            if (vRowDay.idtypeactivity == 4) {

                                for (var xCol4 = 0; xCol4 <= numCol; xCol4++) {


                                    $("#myTable > tbody > tr").eq(xDay).find("td").eq(xCol4).addClass("clRowMalattia");

                                }


                            }


                            if (vRowDay.idtypeactivity != 4) {

                                if (vRowDay.hour_ordinario > 0) {


                                    if (vRowDay.hour_activity == 0) {



                                        for (var xCol = 0; xCol <= numCol; xCol++) {


                                            $("#myTable > tbody > tr").eq(xDay).find("td").eq(xCol).addClass("clRowGreen");

                                        }




                                    }



                                }

                                if (vRowDay.hour_ordinario == 0) {


                                    if (vRowDay.hour_activity > 0) {



                                        for (var xCol2 = 0; xCol2 <= numCol; xCol2++) {


                                            $("#myTable > tbody > tr").eq(xDay).find("td").eq(xCol2).addClass("clRowYellow");

                                        }


                                    }



                                }




                                if (vRowDay.hour_ordinario > 0) {


                                    if (vRowDay.hour_activity > 0) {



                                        for (var xCol5 = 0; xCol5 <= numCol; xCol5++) {


                                            $("#myTable > tbody > tr").eq(xDay).find("td").eq(xCol5).addClass("clRowYellow");

                                        }


                                    }



                                }



                            }









                        }

                    }


                }




            }


        },


        generateRandomString: function (length, characters) {

            let result = "";
            const charLength = characters.length;

            for (let i = 0; i < length; i++) {
                const randomIndex = Math.floor(Math.random() * charLength);
                result += characters.charAt(randomIndex);
            }

            return result;


        },


        deleteRowTs: async function (v_login, v_day, v_month, v_year) {

            var that = this;

            console.log("LOGIN: ", v_login);
            console.log("DAY: ", v_day);
            console.log("MONTH: ", v_month);
            console.log("YEAR: ", v_year);


            var strDay = v_day;
            if (strDay.length == 1) {
                strDay = "0" + strDay.toString();
            }

            var strMonth = v_month;
            if (strMonth.length == 1) {
                strMonth = "0" + strMonth.toString();
            }

            var strDate = strDay + "/" + strMonth + "/" + v_year.toString();

            var strMsg = "Vuoi eliminare la giornata del " + strDate + " ?";

            that.$swal
                .fire({
                    title: "Elimina",
                    html: strMsg,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {



                        that.showSpinner = true;

                        var response = await apitimesheet.deleteRowTs(
                            v_login,
                            v_day,
                            v_month,
                            v_year
                        ).then((res) => {

                            that.showSpinner = false;

                            console.log("res from saveData", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati con successo",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            window.numEventASave = 0;

                            that.syncMonth();

                        }).catch(err => {

                            that.showSpinner = false;

                            window.numEventASaveAndOpen = 0;

                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );





                    }





                });


        },

        saveDataMoreProjForAppr: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            console.log("MONTH_ARRAY: ", that.monthArray);

            //var v_id_prj = that.idProjDefault;

            var lastDay = new Date(that.curYear, that.curMonth, 0).getDate();

            console.log("LAST DAY: ", lastDay);

            var data_timesheet = [];

            for (var xx = 1; xx <= lastDay; xx++) {

                for (var xOrdine = 1; xOrdine <= 3; xOrdine++) {

                    var strIndex = xx.toString() + "_" + xOrdine.toString();

                    if (strIndex in that.monthArray) {

                        console.log("GIORNO; ", that.monthArray[strIndex]);

                        var hourFromIntervento = "";
                        if (that.monthArray[strIndex].hour_from_intervento != null) {
                            hourFromIntervento = that.monthArray[strIndex].hour_from_intervento;
                        }

                        var hourToIntervento = "";
                        if (that.monthArray[strIndex].hour_from_intervento != null) {
                            hourToIntervento = that.monthArray[strIndex].hour_to_intervento;
                        }

                        var showCols = 0;
                        if (that.viewInterv == "S") {
                            showCols = 1;
                        }

                        var strNote = "";
                        if (that.monthArray[strIndex].note != null) {
                            strNote = that.monthArray[strIndex].note;
                        }

                        data_timesheet.push({
                            day: that.monthArray[strIndex].day,
                            day_modified: 0,
                            forzatura: "N",
                            hour_activity: that.monthArray[strIndex].hour_activity,
                            hour_activity2: 0,
                            hour_from_intervento: hourFromIntervento,
                            hour_intervento: that.monthArray[strIndex].hour_intervento,
                            hour_ordinario: that.monthArray[strIndex].hour_ordinario,
                            hour_reperibilita: that.monthArray[strIndex].hour_reperibilita,
                            hour_straordinario: that.monthArray[strIndex].hour_straordinario,
                            hour_to_intervento: hourToIntervento,
                            idtypeactivity: that.monthArray[strIndex].idtypeactivity,
                            idtypeactivity2: 0,
                            month: that.curMonth,
                            note: strNote,
                            ordine: xOrdine,
                            projectid: that.monthArray[strIndex].id_progetto,
                            sede_cliente: 0,
                            show_cols: showCols,
                            year: that.curYear
                        });

                    }





                }


            }

            console.log("TIMESHEET: ", data_timesheet);

            that.showSpinner = true;

            var v_key = that.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");
            v_key = v_key + "|" + that.curYear.toString();
            v_key = v_key + "|" + that.curMonth.toString();
            v_key = v_key + "|" + atob(v_token);
            v_key = v_key + "|" + JSON.stringify(data_timesheet);
            v_key = v_key + "|" + v_token;
            v_key = v_key + "|" + that.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");

            console.log("V_KEY: ", v_key);

            v_key = btoa(v_key);
            v_key = that.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6") + v_key + that.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");

            var response = await apitimesheet.saveDataNew(

                v_key

            ).then((res) => {

                that.showSpinner = false;

                console.log("res from saveDataNew", res);

                console.log(res);

                setTimeout(() => {

                    var response2 = that.updateStatusMonth(2).then((res2) => {

                        that.showSpinner = false;

                        if (res2.status == 200) {
                            that.$swal({
                                icon: "success",
                                text: "Il timesheet è stato inviato correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.resetDay();
                                that.syncMonth();

                            }, 2000);


                        } else {
                            that.$swal({
                                icon: "error",
                                text: "Il timesheet non è stato inviato correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });
                        }
                    });

                    console.log("Response", response2);

                }, 1000);


            }).catch(err => {

                this.showSpinner = false;

                window.numEventASaveAndOpen = 0;

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },


        sendTimesheetForApprove: async function () {

            var that = this;

            var $mesi = [
                "",
                "gennaio",
                "febbraio",
                "marzo",
                "aprile",
                "maggio",
                "giugno",
                "luglio",
                "agosto",
                "settembre",
                "ottobre",
                "novembre",
                "dicembre",
            ];
            var text = $mesi[that.curMonth] + " - " + that.curYear;

            console.log("this.curStatus", that.curStatus)
            var statonum = 1;
            // (1 - Aperto 2 - In Approvazione 3 - Chiuso) (obbligatorio)

            if (that.curStatus == 'Open') statonum = 1;
            if (that.curStatus == 'Close') statonum = 3;
            if (that.curStatus == 'For approval') statonum = 2;

            if (that.aa == 'Y' || that.su == 'Y') {

                console.log(statonum);

                that.sheetStatus = true;

            } else {
                that.$swal
                    .fire({
                        title: "Sei sicuro?",
                        html: "Vuoi inviare il timesheet <h4>" + text + " ?</h4> ",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                        cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                        customClass: {
                            cancelButton: 'order-1',
                            confirmButton: 'order-2',
                        }

                    })
                    .then((result) => {
                        if (result.value == true) {

                            if (that.countProjAssoc <= 1) {

                                that.saveDataForAppr();
                            }

                            if (that.countProjAssoc > 1) {

                                that.saveDataMoreProjForAppr();


                            }



                        }
                    });
            }

        },


        validateSendTimesheetForApprove: function () {

            var that = this;


            var params = that.getParamsApi();

            console.log("SAVE_ADN_OPEN");
            console.log("CUR_YEAR: ", that.curYear);
            console.log("CUR_MONTH: ", that.curMonth);
            console.log("USR_SEL: ", atob(params.token));

            var data_timesheet = [];

            $(".fldchanged").each(function (index) {

                console.log(index + ": " + $(this).text());

                console.log("VAL: ", $(this).val());

                var v_row = $(this).parent().parent();

                console.log("V_ROW: ", v_row);
                var item = window.table.row(v_row).data();

                //var v_day = index + 1; // valore giorno
                var v_day = item.day; // valore giorno
                var v_forz = "N";
                var v_note = $("#sel_desc_" + v_day + "_" + item.ordine).val();
                var v_h_ord = $("#sel_ordinario_" + v_day + "_" + item.ordine).val();
                var v_h_straord = $("#sel_eccedenze_" + v_day + "_" + item.ordine).val();
                var v_h_act = $("#sel_ore_ass_" + v_day + "_" + item.ordine).val();
                var v_id_type_act = $("#sel_tipo_ass_" + v_day + "_" + item.ordine).val();
                var v_h_act2 = $("#sel_ore_ass_2_" + v_day + "_" + item.ordine).val();
                var v_id_type_act2 = $("#sel_tipo_ass_2_" + v_day + "_" + item.ordine).val();

                var v_id_prj = that.idProjDefault;

                if (that.countProjAssoc > 1) {

                    v_id_prj = $("#sel_prj_" + v_day + "_" + item.ordine).val();

                }


                var v_desc_day = $("#desc_day_" + v_day + "_" + item.ordine).html();

                var v_h_rep = $("#sel_reperibilita_" + v_day + "_" + item.ordine).val();

                var v_h_int = 0;
                var v_h_from_int = "";
                var v_h_to_int = "";

                if (that.showColumns == true) {

                    v_h_int = $("#sel_intervento_" + v_day + "_" + item.ordine).val();
                    v_h_from_int = $("#sel_ora_da_intervento_" + v_day + "_" + item.ordine).val();
                    v_h_to_int = $("#sel_ora_a_intervento_" + v_day + "_" + item.ordine).val();

                }


                var check_sede_cliente = 0;
                if ($("#sel_sede_cliente_" + v_day + "_" + item.ordine).prop("checked")) {
                    check_sede_cliente = 1;
                }


                console.log("VAL GIORNO: ", v_day);
                console.log("VAL FORZATURA: ", v_forz);

                data_timesheet.push({
                    day_modified: $(this).val(),
                    forzatura: v_forz,
                    note: v_note,
                    year: that.curYear,
                    month: that.curMonth,
                    day: v_day,
                    hour_ordinario: v_h_ord,
                    hour_straordinario: v_h_straord,
                    hour_activity: v_h_act,
                    idtypeactivity: v_id_type_act,
                    hour_activity2: v_h_act2,
                    idtypeactivity2: v_id_type_act2,
                    projectid: v_id_prj,
                    desc_day: v_desc_day,
                    is_fest: $(this).attr("is-fest"),
                    hour_reperibilita: v_h_rep,
                    hour_intervento: v_h_int,
                    hour_from_intervento: that.formatDateTime(v_h_from_int),
                    hour_to_intervento: that.formatDateTime(v_h_to_int),
                    ordine: item.ordine,
                    sede_cliente: check_sede_cliente
                });

            });

            console.log("DATA TS: ", data_timesheet);


            var errore = "";

            if (that.countProjAssoc < 2) {

                for (var x = 0; x <= data_timesheet.length - 1; x++) {

                    var giorno = data_timesheet[x];


                    if ((giorno.desc_day.indexOf("Sab") == -1) && (giorno.desc_day.indexOf("Dom") == -1)) {

                        if (parseInt(giorno.idtypeactivity) != 8)  // diverso da non in forza
                        {



                            if (errore == "") {

                                if (parseFloat(giorno.hour_activity) > 0) // sono presenti delle ore di assenza nella gionata
                                {

                                    if (parseInt(giorno.idtypeactivity) == 0) // non è stata specificata la causale
                                    {

                                        errore = "Specificare una causale per le ore di assenza del giorno " + giorno.day;

                                    }


                                }


                            }


                            if (errore == "") {

                                if (parseInt(giorno.idtypeactivity) > 0) // è stata specificata la causale
                                {


                                    if (parseInt(giorno.idtypeactivity) != 5) {

                                        if (parseFloat(giorno.hour_activity) == 0) // non sono presenti delle ore di assenza nella gionata
                                        {

                                            errore = "Specificare le ore di assenza per il giorno " + giorno.day;


                                        }



                                    }




                                }

                            }


                            if (errore == "") {

                                if (giorno.hour_straordinario == "") {

                                    errore = "Specificare un valore per lo straordinario per il giorno " + giorno.day;


                                }


                            }

                            if (that.IsPartiIVAPartTime != "S") {

                                if (errore == "") {

                                    if ((parseFloat(giorno.hour_ordinario) == 0) && (parseFloat(giorno.hour_activity) == 0)) {

                                        errore = "Specificare un valore per le ore di assenza per il giorno " + giorno.day;


                                    }

                                }


                            }






                            if (errore == "") {

                                var h_ord = parseFloat(giorno.hour_ordinario);
                                var h_straord = parseFloat(giorno.hour_straordinario);
                                var h_ass = parseFloat(giorno.hour_activity);

                                if ((h_ord == 0) && (h_straord > 0) && (h_ass > 0)) {

                                    errore = "Specificare delle ore ordinarie per il giorno " + giorno.day;


                                }

                            }


                            if (errore == "") {

                                var h_ord2 = parseFloat(giorno.hour_ordinario);
                                var h_straord2 = parseFloat(giorno.hour_straordinario);
                                var h_ass2 = parseFloat(giorno.hour_activity);

                                if ((h_ord2 == 0) && (h_straord2 > 0) && (h_ass2 > 0)) {

                                    errore = "Specificare delle ore ordinarie per il giorno " + giorno.day;


                                }

                            }

                            if (errore == "") {

                                var h_straord3 = parseFloat(giorno.hour_straordinario);

                                if (h_straord3 > 10) {

                                    errore = "Le ore di straordinario devono essere al massimo 10 per il giorno " + giorno.day;


                                }

                            }






                        }




                    }









                }

            }

            if (that.countProjAssoc >= 2) {

                for (var x2 = 0; x2 <= data_timesheet.length - 1; x2++) {

                    var giorno2 = data_timesheet[x2];

                    if ((giorno2.desc_day.indexOf("Sab") == -1) && (giorno2.desc_day.indexOf("Dom") == -1)) {

                        if (errore == "") {

                            if (parseFloat(giorno2.hour_activity) > 0) // sono presenti delle ore di assenza nella gionata
                            {

                                if (parseInt(giorno2.idtypeactivity) == 0) // non è stata specificata la causale
                                {

                                    errore = "Specificare una causale per le ore di assenza del giorno " + giorno2.day;

                                }


                            }


                        }

                        if (errore == "") {

                            if (parseInt(giorno2.idtypeactivity) > 0) // è stata specificata la causale
                            {

                                if (parseFloat(giorno2.hour_activity) == 0) // non sono presenti delle ore di assenza nella gionata
                                {

                                    errore = "Specificare le ore di assenza per il giorno " + giorno2.day;


                                }



                            }

                        }

                    }






                    if (errore == "") {

                        if (parseFloat(giorno2.hour_ordinario) > 0) // ci sono delle ore di attivita
                        {

                            if (giorno2.projectid == 0) // non è stato specificato il progettogionata
                            {

                                errore = "Specificare il progetto per il giorno " + giorno2.day;


                            }



                        }

                    }

                    if ((giorno2.desc_day.indexOf("Sab") == -1) && (giorno2.desc_day.indexOf("Dom") == -1)) {

                        if (errore == "") {

                            if (parseInt(giorno2.hour_ordinario) == 0) // non sono presenti le ore ordinarie
                            {

                                if (parseFloat(giorno2.hour_activity) == 0) // non sono presenti delle ore di assenza nella gionata
                                {

                                    errore = "Specificare le ore ordinarie per il giorno " + giorno2.day;


                                }



                            }

                        }

                    }


                }



            }


            if (errore == "") {


                console.log("ok");


                that.sendTimesheetForApprove();

            }
            else {

                window.numEventASave = 0;

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                });


            }


        },



        validateSave: function () {

            var that = this;


            var params = that.getParamsApi();

            console.log("SAVE_ADN_OPEN");
            console.log("CUR_YEAR: ", that.curYear);
            console.log("CUR_MONTH: ", that.curMonth);
            console.log("USR_SEL: ", atob(params.token));

            var data_timesheet = [];

            $(".fldchanged").each(function (index) {

                console.log(index + ": " + $(this).text());

                var v_row = $(this).parent().parent();

                console.log("V_ROW: ", v_row);
                var item = window.table.row(v_row).data();

                console.log("ROM ITEM: ", item);
                console.log("ROM ITEM ORDINE: ", item.ordine);


                console.log("VAL: ", $(this).val());


                console.log("SEL TIPO ASS: ", $("#sel_tipo_ass_" + v_day + "_" + item.ordine));


                var v_day = item.day; // valore giorno

                var v_forz = "N";
                var v_note = $("#sel_desc_" + v_day + "_" + item.ordine).val()
                var v_h_ord = $("#sel_ordinario_" + v_day + "_" + item.ordine).val();
                var v_h_straord = $("#sel_eccedenze_" + v_day + "_" + item.ordine).val();
                var v_h_act = $("#sel_ore_ass_" + v_day + "_" + item.ordine).val();
                var v_id_type_act = $("#sel_tipo_ass_" + v_day + "_" + item.ordine).val();
                var v_h_act2 = $("#sel_ore_ass_2_" + v_day + "_" + item.ordine).val();
                var v_id_type_act2 = $("#sel_tipo_ass_2_" + v_day + "_" + item.ordine).val();
                var v_totale = $("#sel_ore_totali_" + v_day + "_" + item.ordine).html();


                var v_id_prj = that.idProjDefault;

                if (that.countProjAssoc > 1) {

                    v_id_prj = $("#sel_prj_" + v_day + "_" + item.ordine).val();

                }



                var v_desc_day = $("#desc_day_" + v_day + "_" + item.ordine).html();
                var v_h_rep = $("#sel_reperibilita_" + v_day + "_" + item.ordine).val();

                var v_h_int = 0;
                var v_h_from_int = "";
                var v_h_to_int = "";

                if (that.showColumns == true) {

                    v_h_int = $("#sel_intervento_" + v_day + "_" + item.ordine).val();
                    v_h_from_int = $("#sel_ora_da_intervento_" + v_day + "_" + item.ordine).val();
                    v_h_to_int = $("#sel_ora_a_intervento_" + v_day + "_" + item.ordine).val();

                }

                console.log("ORA FROM: ", v_h_from_int);
                console.log("ORA TO: ", v_h_to_int);

                console.log("VAL GIORNO: ", v_day);
                console.log("VAL FORZATURA: ", v_forz);


                var check_sede_cliente = 0;
                if ($("#sel_sede_cliente_" + v_day + "_" + item.ordine).prop("checked")) {
                    check_sede_cliente = 1;
                }



                data_timesheet.push({
                    day_modified: $(this).val(),
                    forzatura: v_forz,
                    note: v_note,
                    year: that.curYear,
                    month: that.curMonth,
                    day: v_day,
                    hour_ordinario: v_h_ord,
                    hour_straordinario: v_h_straord,
                    hour_activity: v_h_act,
                    idtypeactivity: v_id_type_act,
                    hour_activity2: v_h_act2,
                    idtypeactivity2: v_id_type_act2,
                    projectid: v_id_prj,
                    desc_day: v_desc_day,
                    is_fest: $(this).attr("is-fest"),
                    hour_reperibilita: v_h_rep,
                    hour_intervento: v_h_int,
                    hour_from_intervento: that.formatDateTime(v_h_from_int),
                    hour_to_intervento: that.formatDateTime(v_h_to_int),
                    ordine: item.ordine,
                    sede_cliente: check_sede_cliente,
                    ore_totali: v_totale
                });

            });

            console.log("DATA TS: ", data_timesheet);


            var errore = "";

            if (that.countProjAssoc < 2) {

                for (var x = 0; x <= data_timesheet.length - 1; x++) {

                    var giorno = data_timesheet[x];


                    if ((giorno.desc_day.indexOf("Sab") == -1) && (giorno.desc_day.indexOf("Dom") == -1)) {

                        if (parseInt(giorno.idtypeactivity) != 8)  // diverso da non in forza
                        {

                            if (errore == "") {

                                if (parseFloat(giorno.hour_activity) > 0) // sono presenti delle ore di assenza nella gionata
                                {

                                    if (parseInt(giorno.idtypeactivity) == 0) // non è stata specificata la causale
                                    {

                                        errore = "Specificare una causale per le ore di assenza del giorno " + giorno.day;

                                    }


                                }


                            }


                            if (errore == "") {

                                if (parseInt(giorno.idtypeactivity) > 0) // è stata specificata la causale
                                {

                                    if (parseFloat(giorno.hour_activity) == 0) // non sono presenti delle ore di assenza nella gionata
                                    {

                                        errore = "Specificare le ore di assenza per il giorno " + giorno.day;


                                    }



                                }

                            }



                            if (errore == "") {

                                if (parseInt(giorno.is_fest) == 1) // giorno festivo
                                {


                                    if (parseInt(giorno.day_modified) == 1) {


                                        if (parseFloat(giorno.hour_straordinario) == 0) {

                                            if (giorno.idtypeactivity != 5) {

                                                errore = "Non è possibile specificare un'assenza diversa dal festivo per il giorno " + giorno.day;



                                            }



                                        }


                                    }




                                }



                            }


                            if (errore == "") {

                                if (parseFloat(giorno.hour_reperibilita) == 0) {


                                    if (parseFloat(giorno.hour_intervento) > 0) {


                                        errore = "Specificare la reperibilità per il giorno " + giorno.day;


                                    }


                                }

                            }

                            if (errore == "") {

                                if (parseFloat(giorno.hour_reperibilita) > 0) {


                                    if (parseFloat(giorno.hour_intervento) > 0) {


                                        if (parseFloat(giorno.hour_reperibilita) < parseFloat(giorno.hour_intervento)) {

                                            errore = "La reperibilità deve essere maggiore o uguale dell'intervento per il giorno " + giorno.day;

                                        }




                                    }


                                }


                            }


                            if (errore == "") {

                                if (parseFloat(giorno.hour_intervento) > 0) {

                                    if ((giorno.hour_from_intervento == "") || (giorno.hour_to_intervento == "")) {

                                        errore = "Specificare Ora Da e Ora A dell'intervento per il giorno " + giorno.day;

                                    }

                                }


                            }


                            if (errore == "") {

                                if (parseFloat(giorno.hour_intervento) > 0) {

                                    var date1 = "2023-01-01 " + giorno.hour_from_intervento;
                                    var date2 = "2023-01-01 " + giorno.hour_to_intervento;

                                    date1 = new Date(date1);
                                    date2 = new Date(date2);

                                    if (date2 < date1) {

                                        errore = "Il valore di Ora A deve essere maggiore di Ora Da per il giorno " + giorno.day;


                                    }



                                }


                            }


                            if (errore == "") {

                                if ((giorno.hour_from_intervento != "") && (giorno.hour_to_intervento != "")) {

                                    if ((giorno.hour_from_intervento != "<nil>") && (giorno.hour_to_intervento != "<nil>")) {

                                        if (parseFloat(giorno.hour_intervento) == 0) {

                                            errore = "Specificare il totale ore intervento per il giorno " + giorno.day;


                                        }


                                    }



                                }


                            }




                            if (errore == "") {

                                if (giorno.hour_straordinario == "") {

                                    errore = "Specificare un valore per lo straordinario per il giorno " + giorno.day;


                                }


                            }

                            if (errore == "") {

                                if (giorno.hour_activity == "") {

                                    errore = "Specificare un valore per le ore di assenza per il giorno " + giorno.day;


                                }

                            }


                            if (errore == "") {

                                if ((parseFloat(giorno.hour_ordinario) == 0) && (parseFloat(giorno.hour_activity) == 0)) {

                                    errore = "Specificare un valore per le ore di assenza per il giorno " + giorno.day;


                                }

                            }


                            if (errore == "") {

                                var h_ord = parseFloat(giorno.hour_ordinario);
                                var h_straord = parseFloat(giorno.hour_straordinario);
                                var h_ass = parseFloat(giorno.hour_activity);

                                if ((h_ord == 0) && (h_straord > 0) && (h_ass > 0)) {

                                    errore = "Specificare delle ore ordinarie per il giorno " + giorno.day;


                                }

                            }


                            if (errore == "") {

                                var h_ord2 = parseFloat(giorno.hour_ordinario);
                                var h_straord2 = parseFloat(giorno.hour_straordinario);
                                var h_ass2 = parseFloat(giorno.hour_activity);

                                if ((h_ord2 == 0) && (h_straord2 > 0) && (h_ass2 > 0)) {

                                    errore = "Specificare delle ore ordinarie per il giorno " + giorno.day;


                                }

                            }


                            if (errore == "") {

                                var h_straord3 = parseFloat(giorno.hour_straordinario);

                                if (h_straord3 > 10) {

                                    errore = "Le ore di straordinario devono essere al massimo 10 per il giorno " + giorno.day;


                                }

                            }




                        }




                    }

                }


            }



            if (that.countProjAssoc >= 2) {

                for (var x2 = 0; x2 <= data_timesheet.length - 1; x2++) {

                    var giorno2 = data_timesheet[x2];

                    if ((giorno2.desc_day.indexOf("Sab") == -1) && (giorno2.desc_day.indexOf("Dom") == -1)) {

                        if (errore == "") {

                            if (parseFloat(giorno2.hour_activity) > 0) // sono presenti delle ore di assenza nella gionata
                            {

                                if (parseInt(giorno2.idtypeactivity) == 0) // non è stata specificata la causale
                                {

                                    errore = "Specificare una causale per le ore di assenza del giorno " + giorno2.day;

                                }


                            }


                        }

                        if (errore == "") {

                            if (parseInt(giorno2.idtypeactivity) > 0) // è stata specificata la causale
                            {

                                if (parseFloat(giorno2.hour_activity) == 0) // non sono presenti delle ore di assenza nella gionata
                                {

                                    errore = "Specificare le ore di assenza per il giorno " + giorno2.day;


                                }



                            }

                        }

                    }






                    if (errore == "") {

                        if (parseFloat(giorno2.hour_ordinario) > 0) // ci sono delle ore di attivita
                        {

                            if (giorno2.projectid == 0) // non è stato specificato il progettogionata
                            {

                                errore = "Specificare il progetto per il giorno " + giorno2.day;


                            }



                        }

                    }

                    if ((giorno2.desc_day.indexOf("Sab") == -1) && (giorno2.desc_day.indexOf("Dom") == -1)) {

                        if (errore == "") {

                            if (parseInt(giorno2.hour_ordinario) == 0) // non sono presenti le ore ordinarie
                            {

                                if (parseFloat(giorno2.hour_activity) == 0) // non sono presenti delle ore di assenza nella gionata
                                {

                                    errore = "Specificare le ore ordinarie per il giorno " + giorno2.day;


                                }



                            }

                        }

                    }


                    if (giorno2.projectid > 0) {

                        if (parseFloat(giorno2.hour_ordinario) == 0) {
                            errore = "Specificare le ore ordinarie per il giorno " + giorno2.day;

                        }


                    }







                }



            }




            if (errore == "") {

                console.log("ok");

                that.saveData();

            }
            else {

                window.numEventASave = 0;

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                });


            }


        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },


        getIdProjectDefault: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");


            that.showSpinner = true;

            var response = await apitimesheet.goGetIdProgettoDefault(
                v_token,
                that.curYear,
                that.curMonth
            ).then((res) => {

                that.showSpinner = false;

                console.log("res from goGetIdProgettoDefault", res);

                that.idProjDefault = res.data.Result;

                that.IsPartiIVAPartTime = res.data.IsPartiIVAPartTime;

                that.countProjAssoc = res.data.CntProj;

                that.tipoContratto = res.data.TipoContratto;


                that.showColumns = false;

                if (res.data.ViewColInterv == 1) {
                    that.showColumns = true;

                }

                if (that.tipoContratto != "PIVA") {


                    $('.selprogetti').hide();


                }

                if (that.tipoContratto == "PIVA") {


                    var projectsValues = [];

                    for (var xx = 0; xx < res.data.Projects.length; xx++) {

                        projectsValues.push({
                            projectid: res.data.Projects[xx].projectid,
                            codice: res.data.Projects[xx].codice,
                            ore: 0
                        });

                    }

                    console.log("PROJECTS: ", projectsValues);

                    that.valuesProjects = projectsValues;

                }



            }).catch(err => {

                this.showSpinner = false;

                window.numEventASaveAndOpen = 0;

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );

        },

        saveData: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            console.log("MONTH_ARRAY: ", that.monthArray);

            var v_id_prj = that.idProjDefault;

            var lastDay = new Date(that.curYear, that.curMonth, 0).getDate();

            console.log("LAST DAY: ", lastDay);

            var data_timesheet = [];

            for (var xx = 1; xx <= lastDay; xx++) {

                var strIndex = xx.toString() + "_1";

                console.log("GIORNO; ", that.monthArray[strIndex]);

                var hourFromIntervento = "";
                if (that.monthArray[strIndex].hour_from_intervento != null) {
                    hourFromIntervento = that.monthArray[strIndex].hour_from_intervento;
                }

                var hourToIntervento = "";
                if (that.monthArray[strIndex].hour_from_intervento != null) {
                    hourToIntervento = that.monthArray[strIndex].hour_to_intervento;
                }

                var showCols = 0;
                if (that.viewInterv == "S") {
                    showCols = 1;
                }

                var strNote = "";
                if (that.monthArray[strIndex].note != null) {
                    strNote = that.monthArray[strIndex].note;
                }

                data_timesheet.push({
                    day: that.monthArray[strIndex].day,
                    day_modified: 0,
                    forzatura: "N",
                    hour_activity: that.monthArray[strIndex].hour_activity,
                    hour_activity2: 0,
                    hour_from_intervento: hourFromIntervento,
                    hour_intervento: that.monthArray[strIndex].hour_intervento,
                    hour_ordinario: that.monthArray[strIndex].hour_ordinario,
                    hour_reperibilita: that.monthArray[strIndex].hour_reperibilita,
                    hour_straordinario: that.monthArray[strIndex].hour_straordinario,
                    hour_to_intervento: hourToIntervento,
                    idtypeactivity: that.monthArray[strIndex].idtypeactivity,
                    idtypeactivity2: 0,
                    month: that.curMonth,
                    note: strNote,
                    ordine: 1,
                    projectid: v_id_prj,
                    sede_cliente: 0,
                    show_cols: showCols,
                    year: that.curYear
                });

            }

            console.log("TIMESHEET: ", data_timesheet);

            that.showSpinner = true;

            var v_key = that.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");
            v_key = v_key + "|" + that.curYear.toString();
            v_key = v_key + "|" + that.curMonth.toString();
            v_key = v_key + "|" + atob(v_token);
            v_key = v_key + "|" + JSON.stringify(data_timesheet);
            v_key = v_key + "|" + v_token;
            v_key = v_key + "|" + that.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");

            console.log("V_KEY: ", v_key);

            v_key = btoa(v_key);
            v_key = that.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6") + v_key + that.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");





            var response = await apitimesheet.saveDataNew(

                v_key

            ).then((res) => {

                that.showSpinner = false;

                console.log("res from goSaveDataNew", res);

                that.$swal({
                    icon: "success",
                    text: "Dati salvati con successo",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.resetDay();
                    that.syncMonth();

                }, 2000);


            }).catch(err => {

                this.showSpinner = false;

                window.numEventASaveAndOpen = 0;

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },

        saveDataMoreProjs: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            console.log("MONTH_ARRAY: ", that.monthArray);

            //var v_id_prj = that.idProjDefault;

            var lastDay = new Date(that.curYear, that.curMonth, 0).getDate();

            console.log("LAST DAY: ", lastDay);

            var data_timesheet = [];

            for (var xx = 1; xx <= lastDay; xx++) {

                for (var xOrdine = 1; xOrdine <= 3; xOrdine++) {

                    var strIndex = xx.toString() + "_" + xOrdine.toString();

                    if (strIndex in that.monthArray) {

                        console.log("GIORNO; ", that.monthArray[strIndex]);

                        var hourFromIntervento = "";
                        if (that.monthArray[strIndex].hour_from_intervento != null) {
                            hourFromIntervento = that.monthArray[strIndex].hour_from_intervento;
                        }

                        var hourToIntervento = "";
                        if (that.monthArray[strIndex].hour_from_intervento != null) {
                            hourToIntervento = that.monthArray[strIndex].hour_to_intervento;
                        }

                        var showCols = 0;
                        if (that.viewInterv == "S") {
                            showCols = 1;
                        }

                        var strNote = "";
                        if (that.monthArray[strIndex].note != null) {
                            strNote = that.monthArray[strIndex].note;
                        }

                        data_timesheet.push({
                            day: that.monthArray[strIndex].day,
                            day_modified: 0,
                            forzatura: "N",
                            hour_activity: that.monthArray[strIndex].hour_activity,
                            hour_activity2: 0,
                            hour_from_intervento: hourFromIntervento,
                            hour_intervento: that.monthArray[strIndex].hour_intervento,
                            hour_ordinario: that.monthArray[strIndex].hour_ordinario,
                            hour_reperibilita: that.monthArray[strIndex].hour_reperibilita,
                            hour_straordinario: that.monthArray[strIndex].hour_straordinario,
                            hour_to_intervento: hourToIntervento,
                            idtypeactivity: that.monthArray[strIndex].idtypeactivity,
                            idtypeactivity2: 0,
                            month: that.curMonth,
                            note: strNote,
                            ordine: xOrdine,
                            projectid: that.monthArray[strIndex].id_progetto,
                            sede_cliente: 0,
                            show_cols: showCols,
                            year: that.curYear
                        });

                    }





                }


            }

            console.log("TIMESHEET: ", data_timesheet);

            that.showSpinner = true;

            var v_key = that.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");
            v_key = v_key + "|" + that.curYear.toString();
            v_key = v_key + "|" + that.curMonth.toString();
            v_key = v_key + "|" + atob(v_token);
            v_key = v_key + "|" + JSON.stringify(data_timesheet);
            v_key = v_key + "|" + v_token;
            v_key = v_key + "|" + that.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");

            console.log("V_KEY: ", v_key);

            v_key = btoa(v_key);
            v_key = that.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6") + v_key + that.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");





            var response = await apitimesheet.saveDataNew(

                v_key

            ).then((res) => {

                that.showSpinner = false;

                console.log("res from saveDataNew", res);

                that.$swal({
                    icon: "success",
                    text: "Dati salvati con successo",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.resetDay();
                    that.syncMonth();

                }, 2000);


            }).catch(err => {

                this.showSpinner = false;

                window.numEventASaveAndOpen = 0;

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },



        saveDataForAppr: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            console.log("MONTH_ARRAY: ", that.monthArray);

            var v_id_prj = that.idProjDefault;

            var lastDay = new Date(that.curYear, that.curMonth, 0).getDate();

            console.log("LAST DAY: ", lastDay);

            var data_timesheet = [];

            for (var xx = 1; xx <= lastDay; xx++) {

                var strIndex = xx.toString() + "_1";

                console.log("GIORNO; ", that.monthArray[strIndex]);

                var hourFromIntervento = "";
                if (that.monthArray[strIndex].hour_from_intervento != null) {
                    hourFromIntervento = that.monthArray[strIndex].hour_from_intervento;
                }

                var hourToIntervento = "";
                if (that.monthArray[strIndex].hour_from_intervento != null) {
                    hourToIntervento = that.monthArray[strIndex].hour_to_intervento;
                }

                var showCols = 0;
                if (that.viewInterv == "S") {
                    showCols = 1;
                }

                var strNote = "";
                if (that.monthArray[strIndex].note != null) {
                    strNote = that.monthArray[strIndex].note;
                }

                data_timesheet.push({
                    day: that.monthArray[strIndex].day,
                    day_modified: 0,
                    forzatura: "N",
                    hour_activity: that.monthArray[strIndex].hour_activity,
                    hour_activity2: 0,
                    hour_from_intervento: hourFromIntervento,
                    hour_intervento: that.monthArray[strIndex].hour_intervento,
                    hour_ordinario: that.monthArray[strIndex].hour_ordinario,
                    hour_reperibilita: that.monthArray[strIndex].hour_reperibilita,
                    hour_straordinario: that.monthArray[strIndex].hour_straordinario,
                    hour_to_intervento: hourToIntervento,
                    idtypeactivity: that.monthArray[strIndex].idtypeactivity,
                    idtypeactivity2: 0,
                    month: that.curMonth,
                    note: strNote,
                    ordine: 1,
                    projectid: v_id_prj,
                    sede_cliente: 0,
                    show_cols: showCols,
                    year: that.curYear
                });

            }

            console.log("TIMESHEET: ", data_timesheet);

            that.showSpinner = true;

            var v_key = that.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");
            v_key = v_key + "|" + that.curYear.toString();
            v_key = v_key + "|" + that.curMonth.toString();
            v_key = v_key + "|" + atob(v_token);
            v_key = v_key + "|" + JSON.stringify(data_timesheet);
            v_key = v_key + "|" + v_token;
            v_key = v_key + "|" + that.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");

            console.log("V_KEY: ", v_key);

            v_key = btoa(v_key);
            v_key = that.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6") + v_key + that.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");

            var response = await apitimesheet.saveDataNew(

                v_key

            ).then((res) => {


                console.log("res from saveDataNew", res);

                console.log(res);

                setTimeout(() => {

                    var response2 = that.updateStatusMonth(2).then((res2) => {

                        that.showSpinner = false;

                        if (res2.status == 200) {
                            that.$swal({
                                icon: "success",
                                text: "Il timesheet è stato inviato correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.resetDay();
                                that.syncMonth();

                            }, 2000);


                        } else {
                            that.$swal({
                                icon: "error",
                                text: "Il timesheet non è stato inviato correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });
                        }
                    });

                    console.log("Response", response2);

                }, 1000);


            }).catch(err => {

                this.showSpinner = false;

                window.numEventASaveAndOpen = 0;

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },





        setFlagFldChanged: function (v_day) {

            console.log("FLAG CHANGED");

            $("#fldchanged_" + v_day).val("1");

        },



        funcEvent: function () {

            var that = this;

            $(".selordinario").off();

            $(".selordinario").on("ionChange", function (opt) {

                console.log("NUM EVENT ORD: ", window.numEventOrdinario);

                if (window.numEventOrdinario == 0) {

                    // alert("evento")

                    console.log(opt);


                    //console.log("ID: ", $(this).attr("id"));

                    var val_data_hour_ordinario = $(this).attr("id");
                    var val_day = val_data_hour_ordinario.split("_")[2];
                    //var val_forzatura = $("#sel_forzatura_" + val_day).val();
                    // var val_forzatura = "N";

                    // var hour_ordinario = this.value;

                    window.numEventOrdinario = window.numEventOrdinario + 1;

                    // that.calcOvtAbs(val_day, val_forzatura, hour_ordinario);

                    that.setFlagFldChanged(val_day);

                }

            });

            $(".selforzatura").on("ionChange", function (opt) {

                console.log(opt);

                var val_data_hour_ordinario = $(this).attr("id");
                var val_day = val_data_hour_ordinario.split("_")[2];

                console.log("DAY: ", val_day);

                if (this.value == "S") {

                    $("#sel_eccedenze_" + val_day).removeAttr("readonly");
                    $("#sel_ore_ass_" + val_day).removeAttr("readonly");

                }

                if (this.value == "N") {

                    $("#sel_eccedenze_" + val_day).val("0.00");
                    $("#sel_eccedenze_" + val_day).attr('readonly', true);

                    $("#sel_ore_ass_" + val_day).val("0.00");
                    $("#sel_ore_ass_" + val_day).attr('readonly', true);

                }

                that.setFlagFldChanged(val_day);

            });



            $(".selstraordinario").on("ionBlur", function (opt) {

                console.log(opt);

                that.setFlagFldChanged(val_day);

            });

            $(".selassenza").on("ionBlur", function (opt) {

                console.log(opt);

                that.setFlagFldChanged(val_day);

            });


            $(".seltipoass1").on("ionChange", function (opt) {

                console.log(opt);

                var val_data_hour_ordinario = $(this).attr("id");
                var val_day = val_data_hour_ordinario.split("_")[3];

                that.setFlagFldChanged(val_day);

            });

            $(".oreass2").on("ionChange", function (opt) {

                console.log(opt);

                var val_data_hour_ordinario = $(this).attr("id");
                var val_day = val_data_hour_ordinario.split("_")[4];

                that.setFlagFldChanged(val_day);

            });

            $(".seltipoass2").on("ionChange", function (opt) {

                console.log(opt);

                var val_data_hour_ordinario = $(this).attr("id");
                var val_day = val_data_hour_ordinario.split("_")[3];

                that.setFlagFldChanged(val_day);

            });

            $(".seledesc").on("ionChange", function (opt) {

                console.log(opt);

                var val_data_hour_ordinario = $(this).attr("id");
                var val_day = val_data_hour_ordinario.split("_")[2];

                that.setFlagFldChanged(val_day);

            });




            $("#btn_avanti_dip").on("click", function (opt) {

                console.log(opt);

                if (window.numEventDipAvanti == 0) {

                    //console.log("CUR DIP: ", that.curResource);

                    window.numEventDipAvanti = window.numEventDipAvanti + 1;

                    var cur_pos = 0;
                    var find_pos = 0;

                    for (var dip of that.dipendenti) {

                        if (that.curResource == dip.fld_name) {
                            find_pos = cur_pos;
                        }

                        cur_pos = cur_pos + 1;

                    }

                    console.log("CUR POS DIP: ", find_pos);

                    find_pos = find_pos + 1;

                    console.log("NEXT POS DIP: ", find_pos);

                    console.log("NEXT DIP: ", that.dipendenti[find_pos]);

                    that.manageClickDipendenti(that.dipendenti[find_pos]);


                }

            });


            $("#btn_indietro_dip").on("click", function (opt) {

                console.log(opt);

                if (window.numEventDipIndietro == 0) {

                    console.log("CUR DIP 1: ", that.curResource);

                    window.numEventDipIndietro = window.numEventDipIndietro + 1;

                    var cur_pos = 0;
                    var find_pos = 0;

                    for (var dip of that.dipendenti) {

                        if (that.curResource == dip.fld_name) {
                            find_pos = cur_pos;
                        }

                        cur_pos = cur_pos + 1;

                    }

                    console.log("CUR POS DIP: ", find_pos);

                    find_pos = find_pos - 1;

                    console.log("NEXT POS DIP: ", find_pos);

                    console.log("NEXT DIP: ", that.dipendenti[find_pos]);

                    that.manageClickDipendenti(that.dipendenti[find_pos]);


                }

            });










        },

        manageEbadge: function () {
            setTimeout(() => {
                // alert ( this.tab );

            }, 300);
        },

        resetDay: function () {
            this.date = "";
            this.lastDayId = "";
            this.dayStatusSelected = 0;
        },



        changeStatus: async function (status) {
            console.log(status);
            this.sheetStatus = false;
            var statusInt = 0;

            if (status == "Aperto") statusInt = 1;
            if (status == "For approval") statusInt = 2;
            if (status == "Chiuso") statusInt = 3;
            console.log("Stato int", statusInt);

            //this.$root.$children[0].showProgress = true ;
            this.showSpinner = true;

            var response = await this.updateStatusMonthAdmin(statusInt).then((res) => {
                if (res.status == 200) {
                    // this.$swal("Il timesheet è stato inviato correttamente");
                    this.$swal({
                        icon: "success",
                        text: "Il timesheet è stato aggiornato correttamente",
                        showConfirmButton: false,
                        timer: 500
                    });
                    this.syncMonth();
                } else {
                    this.$swal({
                        icon: "error",
                        text: "Il timesheet non è stato aggiornato correttamente",
                    });
                }
            });
            //this.$root.$children[0].showProgress = false ;
            this.showSpinner = false;

            console.log("Response", response);

        },

        clearSearch() {
            this.search = "";
        },
        clearSearchDipendenti() {
            this.searchDipendenti = "";
        },

        sumHoursProject: function (event) {

            var operation = "+";

            if (event.target.classList.contains('minus')) {
                operation = '-';
            }

            console.log("OPERATION: ", operation);


            if (event) {
                var dataid = event.target.getAttribute("data-id");
                var disabled = event.target.attributes.getNamedItem('disabled');

                console.log("DATAID", dataid);
                console.log("DISABLED", disabled);

                console.log("PO", this.piano_orario);
                console.log("curDay", this.curDay);

                var strDay = new Date(this.curYear, this.curMonth - 1, this.curDay);
                //console.log ("dw", strDay.getDay() );
                var mapDay = {
                    "0": "domenica",
                    "1": "lunedi",
                    "2": "martedi",
                    "3": "mercoledi",
                    "4": "giovedi",
                    "5": "venerdi",
                    "6": "sabato"
                };

                var po = this.piano_orario[0];
                var dayLiteral = mapDay[strDay.getDay()];

                console.log("DAYLITERAL: ", dayLiteral);
                console.log("IS PIVA: ", this.IsUserPartitaIva);

                if ((dayLiteral == "sabato") || (dayLiteral == "domenica")) {
                    dayLiteral = "venerdi";
                }


                var oretoday = parseFloat(po[dayLiteral]);

                var max = 10;
                max = oretoday;

                console.log("MAX: ", max);

                var currentHour = 0;

                for (var xx9 = 0; xx9 < this.valuesProjects.length; xx9++) {

                    if (parseInt(dataid) == parseInt(this.valuesProjects[xx9].projectid)) {
                        console.log("PRJ FOUND");
                        currentHour = this.valuesProjects[xx9].ore;
                    }

                }

                console.log("CURRENT_HOUR: ", currentHour);

                var newHour = currentHour;

                if (disabled == null) {

                    if (operation == '+')
                        newHour = parseFloat(newHour) + 0.5;
                    else
                        newHour = parseFloat(newHour) - 0.5;

                }

                console.log("NEW_HOUR: ", newHour);

                if (newHour <= max && operation == '+') {

                    for (var xx10 = 0; xx10 < this.valuesProjects.length; xx10++) {

                        if (parseInt(dataid) == parseInt(this.valuesProjects[xx10].projectid)) {
                            console.log("PRJ FOUND 2");
                            this.valuesProjects[xx10].ore = newHour;
                        }

                    }

                }


                if (newHour >= 0 && operation == '-') {


                    for (var xx11 = 0; xx11 < this.valuesProjects.length; xx11++) {

                        if (parseInt(dataid) == parseInt(this.valuesProjects[xx11].projectid)) {
                            console.log("PRJ FOUND 3");
                            this.valuesProjects[xx11].ore = newHour;
                        }

                    }

                }




            }

        },

        sumHours: function (event) {

            var operation = "+";

            if (event.target.classList.contains('minus')) {
                operation = '-';
            }

            if (event) {
                var dataid = event.target.getAttribute("data-id");
                var disabled = event.target.attributes.getNamedItem('disabled');

                console.log("DATAID", dataid);
                console.log("DISABLED", disabled);

                console.log("PO", this.piano_orario);
                console.log("curDay", this.curDay);

                var strDay = new Date(this.curYear, this.curMonth - 1, this.curDay);
                //console.log ("dw", strDay.getDay() );
                var mapDay = {
                    "0": "domenica",
                    "1": "lunedi",
                    "2": "martedi",
                    "3": "mercoledi",
                    "4": "giovedi",
                    "5": "venerdi",
                    "6": "sabato"
                };

                var po = this.piano_orario[0];
                var dayLiteral = mapDay[strDay.getDay()];

                console.log("DAYLITERAL: ", dayLiteral);
                console.log("IS PIVA: ", this.IsUserPartitaIva);

                if ((dayLiteral == "sabato") || (dayLiteral == "domenica")) {
                    dayLiteral = "venerdi";
                }


                var oretoday = parseFloat(po[dayLiteral]);

                var max = 10;
                max = oretoday;
                var index = 0;
                var newQuant;
                if (dataid == 'Ordinari') index = 0;
                if (dataid == 'Straordinari') index = 1;
                if (dataid == 'Assenza') index = 2;
                if (dataid == 'Reperibilità') index = 6;
                if (dataid == 'Intervento') index = 7;

                console.log("quantita", this.forecast[index].quantity);

                if (disabled == null) {

                    if (operation == '+')
                        newQuant = parseFloat(this.forecast[index].quantity) + 0.5;
                    else
                        newQuant = parseFloat(this.forecast[index].quantity) - 0.5;

                    if (newQuant <= max && operation == '+') {
                        this.forecast[index].quantity = newQuant.toString();

                    }

                    if (newQuant >= 0 && operation == '-') {
                        this.forecast[index].quantity = newQuant.toString();

                    }

                    var diff = 0;
                    //adapta absence with ordinary , user cliecked ordinari so check if more than usual
                    if (dataid == 'Ordinari') {
                        if (newQuant <= oretoday) {
                            diff = oretoday - newQuant;
                            console.log("Diff:", diff);
                            //needed to set absense
                            if (diff > oretoday) diff = oretoday;
                            this.forecast[2].quantity = diff.toString();
                        }
                    }

                    if (dataid == 'Assenza') {
                        if (newQuant <= oretoday) {
                            diff = oretoday - newQuant;
                            console.log("Diff:", diff);
                            //needed to set absense
                            if (diff > oretoday) diff = oretoday;
                            this.forecast[0].quantity = diff.toString();
                        }
                    }

                    this.updateSumDay();
                }

            }

        },

        sumHours2: function (event) {

            var operation = "+";

            if (event.target.classList.contains('minus')) {
                operation = '-';
            }

            if (event) {
                var dataid = event.target.getAttribute("data-id");

                var disabled = event.target.attributes.getNamedItem('disabled');

                var index = 0;
                if (dataid == 'Dalle') index = 8;
                if (dataid == 'Alle') index = 9;



                var lista_orari = this.lista_orari;

                console.log("LISTA_ORARI 2:", lista_orari);


                if (disabled == null) {

                    var newIndex = 0;

                    if (dataid == "Dalle") {

                        for (var ind in lista_orari) {

                            if (operation == '+') {
                                if (lista_orari[ind] == this.forecast[index].timeStart) {



                                    newIndex = parseInt(ind) + 1;


                                }

                            }

                            if (operation == '-') {
                                if (lista_orari[ind] == this.forecast[index].timeStart) {

                                    newIndex = parseInt(ind) - 1;
                                }

                            }


                        }
                    }

                    if (dataid == "Alle") {

                        for (var ind2 in lista_orari) {

                            if (operation == '+') {
                                if (lista_orari[ind2] == this.forecast[index].timeEnd) {

                                    newIndex = parseInt(ind2) + 1;
                                }

                            }

                            if (operation == '-') {
                                if (lista_orari[ind2] == this.forecast[index].timeEnd) {

                                    newIndex = parseInt(ind2) - 1;
                                }

                            }


                        }
                    }


                    var startIndex = 0;
                    var endIndex = lista_orari.length - 1;

                    console.log("START INDEX: ", startIndex);
                    console.log("END INDEX: ", endIndex);



                    if (dataid == "Dalle") {

                        if ((newIndex >= startIndex) && (newIndex <= endIndex)) {

                            this.forecast[index].timeStart = lista_orari[newIndex.toString()];


                            this.monthArray[this.curDay + "_1"].hour_from_intervento = this.forecast[index].timeStart;


                        }


                    }

                    if (dataid == "Alle") {

                        if ((newIndex >= startIndex) && (newIndex <= endIndex)) {

                            this.forecast[index].timeEnd = lista_orari[newIndex.toString()];

                            this.monthArray[this.curDay + "_1"].hour_to_intervento = this.forecast[index].timeEnd;
                        }



                    }

                    console.log("NewIndex", newIndex);

                }



            }

        },

        manageClickDipendenti: function (dipendente) {

            this.sheetDipendenti = false;

            window.numEventDipAvanti = 0;
            window.numEventDipIndietro = 0;

            console.log(dipendente);

            this.currDipendente = dipendente.resourceid;

            this.resetDay();

            this.changeDip();

            setTimeout(() => {

                this.syncMonth();

                //console.log( "dipobj" ,this.currDipendenteObj);
                this.isDipEbadge = parseInt(this.currDipendenteObj.is_ebadge);

                this.entrata1 = "";
                this.entrata2 = "";
                this.uscita1 = "";
                this.uscita2 = "";

            }, 100);

        },

        manageClickAziende: async function (azienda) {

            //console.log(azienda);

            var v_token = window.$cookies.get("token");

            this.currAzienda = azienda;

            // console.log("CUR YEAR", this.curYear);
            // console.log("CUR MONTH", this.curMonth);

            this.$root.$children[0].showProgress = true;

            var response = await apitimesheet.getDipendenti(
                azienda,
                v_token,
                this.curYear,
                this.curMonth
            ).then((res) => {

                this.$root.$children[0].showProgress = false;

                //that.$root.$children[0].showProgress = false;
                console.log("res from getDipendenti", res);


                var arrDipendenti = [];

                for (var dip of res.data.Dipendenti) {

                    arrDipendenti.push(dip);

                }


                this.dipendenti = arrDipendenti;

                setTimeout(() => {
                    this.sheetAziende = false;
                    this.sheetDipendenti = true;
                }, 300);



                //  console.log("ARR DIP: ", arrDipendenti);




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }


            );



            // await this.syncArrayDipendenti();

            // console.log("CurAzienda", this.currAzienda);
            // console.log(this.dipendenti);

            // setTimeout(() => {
            //     this.sheetAziende = false;
            //     this.sheetDipendenti = true;
            // }, 300);

        },

        changeDip: function (selectObj) {
            console.log(selectObj);
            /*  console.log("Oggetto :", selectObj);
             console.log(selectObj.src);
 
             console.log(this.currDipendente);
             console.log(this.dipendenti); */

            //var selNameDip = "";
            for (const [key, dip] of Object.entries(this.dipendenti)) {
                console.log("key", key);
                if (parseInt(dip.resourceid) == this.currDipendente) {
                    this.currDipendenteObj = dip;
                    console.log("dip", dip);
                    //selNameDip = dip.fld_name;

                }
            }

            //  this.$root.$children[0].curDipTop = selNameDip;

        },

        updateStatusMonthAdmin: async function (status) {

            console.log("currDipObj", this.currDipendenteObj);

            //    return false ;// eslint-disable no-unreachable

            var v_token = window.$cookies.get("token");

            var token = window.$cookies.get("token");
            var system = window.$cookies.get("system");
            var user_id = window.$cookies.get("user_id");

            if (this.currDipendenteObj.resourceid !== undefined) {
                //alert (  this.currDipendenteObj.resourceid  );
                system = this.currDipendenteObj.v_res_id;
                token = this.currDipendenteObj.v_user;
                user_id = this.currDipendenteObj.v_user_id;

            }

            var success = 1;
            var response = null;
            try {
                response = await this.$api.tsUpdateStatus(
                    token,
                    system,
                    user_id,
                    status,
                    this.curYear,
                    this.curMonth,
                    v_token
                );
            } catch (error) {
                success = 0;
                console.log(error);
            }
            console.log(response, status, success, token, system, user_id);
            return response;
        },

        updateStatusMonth: async function (status) {
            var token = window.$cookies.get("token");
            var system = window.$cookies.get("system");
            var user_id = window.$cookies.get("user_id");
            //  var username = window.$cookies.get("username");
            //system => resourceid
            //token_system=>pass encodata
            //username

            var success = 1;
            var response = null;

            var v_key = this.generateRandomString(50, "gWnKdU7d4Sb58hFbL4G6");
            v_key = v_key + "|" + token.toString();
            v_key = v_key + "|" + system.toString();
            v_key = v_key + "|" + user_id.toString();
            v_key = v_key + "|" + status.toString();
            v_key = v_key + "|" + this.curYear.toString();
            v_key = v_key + "|" + this.curMonth.toString();
            v_key = v_key + "|" + this.generateRandomString(50, "gWnKdU7d4Sb58hFbL4G6");
            v_key = btoa(v_key);
            v_key = this.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6") + v_key + this.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");

            try {
                response = await this.$api.tsUpdateStatusApprovalNew(
                    v_key
                );
            } catch (error) {
                success = 0;
                console.log(error);
            }

            console.log(response, status, success);

            return response;
        },

        setupNavTop: async function () {
            // this.setupNavBottomDone = true ;
            /*   var disabled = false;
              if (this.curStatus == "Close" || this.curStatus == "For Approval") {
                disabled = true;
              } */


            var icon = "mdi-account-lock-open";
            var descStatus = "Aperto";
            if (this.curStatus == "Close") {
                icon = "mdi-account-lock";
                descStatus = "Chiuso";
            }
            if (this.curStatus == "For approval") {
                icon = "mdi-account-arrow-right";
                descStatus = "In Approvazione";
            }


            var color = this.colorsStati[this.curStatus];

            console.log("Color status :", color);

            var buttonsTop = [{
                text: "",
                icon: icon,
                link: "",
                id: "btn_status",
                disabled: false,
                color: color,
                title: descStatus
            }];

            this.$root.$children[0].buttonsTop = buttonsTop;
            //console.log ( icon );

            this.$root.$children[0].buttonsTop = [];

            $("#userRed").hide();
            $("#userYellow").hide();
            $("#userGreen").hide();

            if (descStatus == "Aperto") {

                $("#userRed").show();


            }

            if (descStatus == "In Approvazione") {

                $("#userYellow").show();

            }


            if (descStatus == "Chiuso") {

                $("#userGreen").show();

            }

        },

        setupNavBottom: async function () {



            this.setupNavBottomDone = true;
            var disabled = false;
            if (this.curStatus == "Close" || this.curStatus == "For approval") {
                disabled = true;
            }

            if (this.aa == 'Y' || this.su == 'Y') {
                disabled = false;
            }

            var icon = "mdi-account-lock-open";
            if (this.curStatus == "Close") icon = "mdi-account-lock";
            if (this.curStatus == "For approval") icon = "mdi-account-arrow-right";

            console.log(icon);
            console.log(disabled);

            console.log("IS PARTITA IVA: ", this.IsUserPartitaIva);


            setTimeout(() => {



                var pulsantis = [];

                if (!disabled) {

                    pulsantis.push(

                        {
                            text: "Ricarica",
                            icon: "mdi-refresh",
                            image: "https://app.emtool.eu/public/_lib/img/refresh-emt.png",
                            link: "",
                            id: "btn_refr_tms",
                            disabled: false,
                            title: "Aggiorna",
                            width: 30
                        },



                    );

                    pulsantis.push(



                        {
                            text: "",
                            icon: "mdi-refresh",
                            image: "https://app.emtool.eu/public/_lib/img/icon_save_emt.png",
                            link: "",
                            id: "btn_save",
                            disabled: false,
                            title: "Salva",
                            width: 28
                        },



                    );


                    pulsantis.push(



                        {
                            text: "",
                            icon: "mdi-refresh",
                            image: "https://app.emtool.eu/public/_lib/img/cambio-stato-emt.png",
                            link: "",
                            id: "btn_send_tms",
                            disabled: false,
                            title: "Invia Per Approvazione",
                            width: 35
                        },



                    );

                }


                if ((this.aa == "S") || (this.aa == "Y")) {

                    pulsantis.push(



                        {
                            text: "",
                            icon: "mdi-refresh",
                            image: "https://app.emtool.eu/public/_lib/img/ferie2.png",
                            link: "",
                            id: "btn_ferie_permessi",
                            disabled: false,
                            title: "Ferie / Permessi",
                            width: 30
                        },



                    );

                }

                if (this.aa == "N") {

                    if (this.IsUserPartitaIva == 0) {

                        if (!disabled) {

                            pulsantis.push(




                                {
                                    text: "",
                                    icon: "mdi-refresh",
                                    image: "https://app.emtool.eu/public/_lib/img/ferie2.png",
                                    link: "",
                                    id: "btn_ferie_permessi",
                                    disabled: false,
                                    title: "Ferie / Permessi",
                                    width: 30
                                },



                            );

                            pulsantis.push(




                                {
                                    text: "",
                                    icon: "mdi-refresh",
                                    image: "https://app.emtool.eu/public/_lib/img/malattia4.png",
                                    link: "",
                                    id: "btn_malattia",
                                    disabled: false,
                                    title: "Malattia",
                                    width: 30
                                },



                            );

                        }



                    }

                    if (!disabled) {

                        pulsantis.push(




                            {
                                text: "",
                                icon: "mdi-refresh",
                                image: this.getUrlDomain("public/_lib/img/easyer-emt.png"),
                                link: "",
                                id: "btn_ins_nota_spese",
                                disabled: false,
                                title: "Nota Spese",
                                width: 30
                            },



                        );

                    }



                }





                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 10);
        },

        resetSumDay: async function () {
            /*  this.totPresenzaMese = monthPresenza;
                  this.totStraordinariMese = monthStraordinary; */

            this.forecast[0].quantity = "";
            this.forecast[1].quantity = "";
            this.forecast[2].quantity = "";
            this.forecast[3].quantity = "";
            this.forecast[4].quantity = "";
        },

        roundstaff: function (val) {
            console.log(val);

            return val.toFixed(2);

        },

        updateSumTotals: function () {
            //update all
            var result = Object.entries(this.monthArray);
            //   console.log ("qui" ,  result );
            var monthPresenza = 0;
            var monthStraordinary = 0;
            var monthAssenza = 0;
            var monthReperibilita = 0;
            var monthIntervento = 0;

            //var monthAssenza = 0 ;

            var ordinary = "";
            var straordinary = "";
            var assenza = "";
            var reperibilita = "";
            var intervento = "";

            for (var dat of result) {
                ordinary = dat[1].hour_ordinario;
                straordinary = dat[1].hour_straordinario;
                assenza = dat[1].hour_activity;
                reperibilita = dat[1].hour_reperibilita;
                intervento = dat[1].hour_intervento;

                if (ordinary > 0) monthPresenza += parseFloat(ordinary);
                if (straordinary > 0) monthStraordinary += parseFloat(straordinary);
                if (assenza > 0) monthAssenza += parseFloat(assenza);
                if (reperibilita > 0) monthReperibilita += parseFloat(reperibilita);
                if (intervento > 0) monthIntervento += parseFloat(intervento);

            }
            this.totPresenzaMese = this.roundstaff(monthPresenza);
            this.totStraordinariMese = this.roundstaff(monthStraordinary);
            this.totAssenzaMese = this.roundstaff(monthAssenza);
            this.totReperibilitaMese = this.roundstaff(monthReperibilita);
            this.totInterventoMese = this.roundstaff(monthIntervento);

            var totali = parseFloat(monthPresenza) + parseFloat(monthStraordinary);

            this.$root.$children[0].curDipOreTop = "";
            this.$root.$children[0].curDipOreTopLabel = this.roundstaff(totali) + " H";

        },

        updateSumDay: async function () {
            // var token = window.$cookies.get("token");
            // var system = window.$cookies.get("system");

            var username = window.$cookies.get("username");
            console.log(username);

            var ord = this.forecast[0].quantity;
            var straord = this.forecast[1].quantity;
            var ass = this.forecast[2].quantity;
            var id_ass = this.forecast[3].quantity;
            var rep = this.forecast[6].quantity;
            var interv = this.forecast[7].quantity;

            console.log("ID_ASS: ", id_ass);


            // if (ass == 0 || ass == "0") {
            //     id_ass = "";
            //     this.forecast[3].quantity = "";

            // }

            this.forecast[4].quantity = parseFloat(ord) + parseFloat(straord);

            console.log("meseArray", this.monthArray);

            this.monthArray[this.curDay + "_1"].hour_ordinario = ord;
            this.monthArray[this.curDay + "_1"].hour_activity = ass;
            this.monthArray[this.curDay + "_1"].hour_straordinario = straord;
            this.monthArray[this.curDay + "_1"].idtypeactivity = id_ass;
            this.monthArray[this.curDay + "_1"].hour_reperibilita = rep;
            this.monthArray[this.curDay + "_1"].hour_intervento = interv;

            //trial
            this.updateSumTotals();

            //last modify update calendar icons
            this.syncMOnthIcons();


        },

        syncArrayDipendenti: async function () {

            console.log(this.currDipendente);

            // this.currDipendente = "";
            // this.$root.$children[0].curDipTop = "";

            this.$root.$children[0].curDipOreTop = "";
            this.$root.$children[0].curDipOreTopLabel = "";

            //aziende
            var arrAziende = [];
            var arrDipendenti = [];

            var aziende = this.globalArrAziende; // Object.entries( this.monthArray.aziende  ) ;
            //   var aziende =   this.aziende  ;

            console.log("Elenco aziende:", this.aziende);
            // this.currAzienda
            //console.log ( "aziendeOK" , aziende );
            for (var az of aziende) {

                var currArAzienda = az[1].fld_name;
                console.log("az", az);
                arrAziende.push(az[1].fld_name);

                var dipArray = az[1].dipendenti;
                for (var dip of dipArray) {
                    //console.log ( dip.fld_name );
                    //  console.log ( dip );
                    if (currArAzienda == this.currAzienda) {
                        arrDipendenti.push(dip);

                    }
                }

            }
            //this.aziende = arrAziende;
            this.dipendenti = arrDipendenti;

        },

        reloadGrid() {

            console.log("RELOADGRID");


            var params = this.getParamsApi();

            console.log("RELOAD_GRID");
            console.log("CUR_YEAR: ", this.curYear);
            console.log("CUR_MONTH: ", this.curMonth);
            console.log("TOKEN_USER: ", atob(params.token));
            console.log("CUR_FULL_NAME_RES: ", this.curResource);
            console.log("CUR_AZIENDA: ", this.currAzienda);

            console.log("CUR_DIPENDENTI: ", this.dipendenti);

            if (this.dipendenti.length == 0) {
                $("#sec_move_dip").hide();
            }

            if (this.dipendenti.length > 0) {
                $("#sec_move_dip").show();


                // abilito le frecce a seconda se sono sul primo o l'ultimo elemento

                var cur_pos = 0;
                var find_pos = 0;

                for (var dip of this.dipendenti) {

                    if (this.curResource == dip.fld_name) {
                        find_pos = cur_pos;
                    }

                    cur_pos = cur_pos + 1;

                }

                $("#btn_indietro_dip").show();
                $("#btn_avanti_dip").show();

                if (find_pos == 0) {

                    $("#btn_indietro_dip").hide();

                }

                console.log("FIND POS: ", find_pos);
                console.log("DIP LEN: ", this.dipendenti.length);

                if (find_pos == (this.dipendenti.length - 1)) {

                    $("#btn_avanti_dip").hide();

                }


            }


            window.anno = this.curYear;
            window.mese = this.curMonth;
            window.user_selected = atob(params.token);

            //window.cur_full_name_res = this.curResource;
            window.cur_full_name_res = this.currAzienda;

            //  $("#full_name_res").html(this.curResource);

            if (this.currAzienda != "") {

                $("#full_name_res").html(this.currAzienda);

                $("#name_dip_sel").html(this.curResource);


                window.table.ajax.reload();

            }
            else {


                this.loadAziendaUserLogged();

            }






        },

        loadAziendaUserLogged: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var response = await apitimesheet.getAziendaByUser(
                v_token
            ).then((res) => {

                console.log("res from getAziendaByUser", res);

                $("#res_full_name").html("<b>" + res.data.Azienda + "</b>");



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },

        getParamsApi() {


            console.log("CUR DIP 2: ", this.currDipendente);


            var curDip = parseInt(this.currDipendente);
            var token = window.$cookies.get("token");
            var system = window.$cookies.get("system");

            if (curDip > 0) {
                console.log("curDIp", curDip, "this.dipendenti", this.dipendenti);
                //for( var dip in this.dipendenti  )
                for (const [key, dip] of Object.entries(this.dipendenti)) {
                    console.log("key", key);
                    if (parseInt(dip.resourceid) == curDip) {
                        console.log("dip", dip, "token", token, "system", system);
                        token = dip.v_user;
                        system = dip.resourceid;

                    }
                }

            }
            console.log({
                "token": token,
                "system": system
            });
            return {
                "token": token,
                "system": system
            };
        },

        syncMOnthIcons: function () {
            var arrTimesheet = this.monthArray;

            console.log("syncMOnthIcons");
            console.log("arrTimesheet: ", arrTimesheet);


            var todos = [];

            var sizeDot = "size:10px";

            //var sabdom = [] ;
            for (var day of Object.entries(arrTimesheet)) {
                console.log("GIORNO CORRENTE: ", day[1]);
                var dayCur = day[1];
                var strDay = new Date();



                //Green for workingday
                if (dayCur.hour_ordinario > 0) {


                    var txtDesc = "Ordinarie  " + dayCur.hour_ordinario + " H";
                    if (dayCur.desc_progetto != "") {
                        txtDesc = txtDesc + " (" + dayCur.desc_progetto + ")";
                    }


                    console.log("Ordinari");
                    strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);
                    todos.push({
                        description: txtDesc,
                        dates: [strDay],
                        color: "green",
                        highlight: true,
                        isComplete: true,
                        dot: true,
                    });

                }

                if (dayCur.hour_straordinario > 0) {

                    console.log("Straordinari");
                    strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);
                    todos.push({
                        description: "Straordinario " + dayCur.hour_straordinario + " H",
                        dates: [strDay],
                        color: "gray",
                        isComplete: false,
                        dot: {
                            style: {
                                style: sizeDot,
                            }
                        }
                    });

                }

                if (dayCur.hour_reperibilita > 0) {

                    console.log("Reperibilita");
                    strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);
                    todos.push({
                        description: "Reperibilità " + dayCur.hour_reperibilita + " H",
                        dates: [strDay],
                        color: "pink",
                        isComplete: false,
                        dot: {
                            style: {
                                style: sizeDot,
                            }
                        }
                    });


                }

                if (dayCur.hour_intervento > 0) {

                    console.log("Intervento");
                    strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);
                    todos.push({
                        description: "Intervento " + dayCur.hour_intervento + " H",
                        dates: [strDay],
                        color: "orange",
                        isComplete: false,
                        dot: {
                            style: {
                                style: sizeDot,
                            }
                        }
                    });

                }


                if (dayCur.hour_activity > 0) {

                    console.log("fesilnesst");
                    strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);


                    if (dayCur.idtypeactivity == 4) // malattia
                    {


                        todos.push({
                            description: "N. Protocollo (" + dayCur.num_protocollo + ")",
                            dates: [strDay],
                            color: "red",
                            isComplete: false,
                            dot: {
                                style: {
                                    style: sizeDot,
                                }
                            }
                        });

                    }

                    if (dayCur.idtypeactivity == 3) // ferie
                    {

                        todos.push({
                            description: "Ferie " + dayCur.hour_activity + " H",
                            dates: [strDay],
                            color: "yellow",
                            isComplete: false,
                            dot: {
                                style: {
                                    style: sizeDot,
                                }
                            }
                        });

                    }

                    if (dayCur.idtypeactivity == 6) // permesso retribuito
                    {

                        todos.push({
                            description: "Permesso retribuito " + dayCur.hour_activity + " H",
                            dates: [strDay],
                            color: "yellow",
                            isComplete: false,
                            dot: {
                                style: {
                                    style: sizeDot,
                                }
                            }
                        });

                    }

                    if (dayCur.idtypeactivity == 7) // permesso non retribuito
                    {

                        todos.push({
                            description: "Permesso non retribuito " + dayCur.hour_activity + " H",
                            dates: [strDay],
                            color: "yellow",
                            isComplete: false,
                            dot: {
                                style: {
                                    style: sizeDot,
                                }
                            }
                        });

                    }

                    if (dayCur.idtypeactivity == 8) // non in forza
                    {

                        todos.push({
                            description: "Non in forza " + dayCur.hour_activity + " H",
                            dates: [strDay],
                            color: "yellow",
                            isComplete: false,
                            dot: {
                                style: {
                                    style: sizeDot,
                                }
                            }
                        });

                    }

                    if (dayCur.idtypeactivity == 17) // donazione sangue
                    {

                        todos.push({
                            description: "Donazione sangue " + dayCur.hour_activity + " H",
                            dates: [strDay],
                            color: "yellow",
                            isComplete: false,
                            dot: {
                                style: {
                                    style: sizeDot,
                                }
                            }
                        });

                    }

                    if (dayCur.idtypeactivity == 72) // permesso studio
                    {

                        todos.push({
                            description: "Permesso studio " + dayCur.hour_activity + " H",
                            dates: [strDay],
                            color: "yellow",
                            isComplete: false,
                            dot: {
                                style: {
                                    style: sizeDot,
                                }
                            }
                        });

                    }

                    if (dayCur.idtypeactivity == 73) // no work (partita iva)
                    {

                        todos.push({
                            description: "No work " + dayCur.hour_activity + " H",
                            dates: [strDay],
                            color: "blue",
                            isComplete: false,
                            dot: {
                                style: {
                                    style: sizeDot,
                                }
                            }
                        });

                    }

                    if (dayCur.idtypeactivity == 77) // partial work
                    {

                        var txtDesc2 = "Partial work " + dayCur.hour_activity + " H";
                        if (dayCur.desc_progetto != "") {
                            txtDesc2 = txtDesc2 + " (" + dayCur.desc_progetto + ")";
                        }

                        todos.push({
                            description: txtDesc2,
                            dates: [strDay],
                            color: "yellow",
                            isComplete: false,
                            dot: {
                                style: {
                                    style: sizeDot,
                                }
                            }
                        });




                    }

                    if (dayCur.idtypeactivity == 16) // congedo martimoniale
                    {

                        todos.push({
                            description: "Congedo matrimoniale " + dayCur.hour_activity + " H",
                            dates: [strDay],
                            color: "yellow",
                            isComplete: false,
                            dot: {
                                style: {
                                    style: sizeDot,
                                }
                            }
                        });

                    }

                    if (dayCur.idtypeactivity == 71) // permesso lutto
                    {

                        todos.push({
                            description: "Permesso lutto " + dayCur.hour_activity + " H",
                            dates: [strDay],
                            color: "yellow",
                            isComplete: false,
                            dot: {
                                style: {
                                    style: sizeDot,
                                }
                            }
                        });

                    }

                }





                if (dayCur.is_festivo) {
                    console.log("fest");
                    strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);
                    todos.push({
                        description: dayCur.desc_festività,
                        dates: [strDay],
                        color: "blue",
                        isComplete: false,
                        dot: {
                            style: {
                                style: sizeDot,
                            }
                        }
                    });

                }
                if (!dayCur.is_festivo) {
                    if (
                        dayCur.day_working == "NOT WORKING" &&
                        (dayCur.desc_day == "Sab" || dayCur.desc_day == "Dom")
                    ) {


                        if ((dayCur.hour_ordinario == 0) && (dayCur.hour_straordinario == 0)) {

                            strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);
                            todos.push({
                                description: "Non lavorativo",
                                dates: [strDay],
                                color: "blue",
                                isComplete: false,
                                dot: {
                                    style: {
                                        style: sizeDot,
                                    }
                                }
                            });

                        }

                    }

                }

            }
            this.todos = todos;


            // setTimeout(() => {

            //     $('span.vc-dot').each(function () {
            //        // console.log("VC-DOT: ", $(this));
            //         console.log("VC-DOT: ", $(this).css("background-color"));
            //     });


            // }, 500);










        },

        syncMonth: async function () {

            $("#Ordinarie").hide();
            $("#Assenza").hide();
            $("#Straordinari").hide();
            $("#Causale").hide();
            $("#Descrizione").hide();
            $("#Reperibilità").hide();
            $("#Intervento").hide();
            $("#Dalle").hide();
            $("#Alle").hide();
            $("#Progetto").hide();
            this.dayFormatted = "";

            var params = this.getParamsApi();

            /*          var token = window.$cookies.get("token");
                        var system = window.$cookies.get("system"); */

            var token = params.token;
            var system = params.system;

            //   console.log ( system );
            this.showSpinner = true;
            var response;
            var arrTimesheet = [];

            try {
                response = await this.$api
                    .GetTimesheetCalendar(token, system, this.curYear, this.curMonth)
                    .then((res) => {
                        arrTimesheet = res.data.Timesheet;
                        console.log("Timesheet", arrTimesheet);

                        this.monthArray = arrTimesheet;

                        var result = Object.entries(arrTimesheet);
                        console.log(result);

                        var info_month = arrTimesheet.info_month;

                        console.log("CUR AZIENDA: ", this.currAzienda);

                        this.IsUserPartitaIva = arrTimesheet.is_partita_iva;

                        this.IsPartitaIva = "N";
                        if (arrTimesheet.is_partita_iva == 1) {
                            this.IsPartitaIva = "S";
                        }

                        var a = window.$cookies.get("a");
                        if ((a == "S") || (a == "Y")) {
                            this.IsPartitaIva = "S";
                            this.IsUserPartitaIva = "N";
                        }


                        var is_su = window.$cookies.get("a");

                        if ((is_su == "S") || (is_su == "Y")) {
                            this.viewRep = "S";
                            this.viewButtonInterv = "S";
                            this.viewInterv = "N";
                        }

                        if (is_su == "N") {
                            this.viewRep = arrTimesheet.is_enable_rep;
                            this.viewButtonInterv = arrTimesheet.is_enable_interv;
                        }


                        if (!this.setupNavBottomDone) {
                            this.su = arrTimesheet.u_complete;
                            this.aa = arrTimesheet.az_complete;

                        }

                        this.curStatus = info_month.desc_stato;
                        this.curReadOnly = info_month.editable;
                        this.piano_orario = arrTimesheet.piano_orario;

                        console.log("info_month", info_month);

                        var causali = arrTimesheet.causali;
                        console.log("causali", causali);
                        var arrCausali = [];

                        for (var causale of causali) {
                            //arrCausali[causale.idtypeactivity] = causale.description  ;
                            arrCausali.push(causale.description);
                        }
                        // console.log ( arrCausali );
                        this.causali = causali;

                        //aziende
                        var arrAziende = [];
                        var arrDipendenti = [];
                        console.log(arrDipendenti);

                        var aziende = Object.entries(arrTimesheet.aziende);
                        //console.log ( "aziendeOK" , aziende );
                        for (var az of aziende) {
                            console.log("az", az);
                            arrAziende.push(az[1].fld_name);

                        }

                        arrAziende.sort();

                        if (arrAziende.length > 1 && !this.setupNavBottomDone) {

                            //attention
                            this.aziende = arrAziende;
                            this.dipendenti = arrDipendenti;
                            this.globalArrAziende = aziende;

                            /*  this.dipendenti =  this.$root.$children[0].dipendenti;
                              this.aziende =  this.$root.$children[0].aziende;
                               this.globalArrAziende =  this.$root.$children[0].globalArrAziende; */

                        }

                        console.log("arraz", arrAziende);
                        console.log("globalArrAziende", this.globalArrAziende);
                        //this.causali = arrCausali;
                        //Start
                        //var datesOk =   [ ];
                        //  var descOk =   [ ];

                        console.log("SYNC_MONTH DIPENDENTI: ", this.dipendenti);
                        console.log("SYNC_MONTH DIPENDENTI LEN: ", this.dipendenti.length);

                        this.curResource = info_month.full_name;

                        if (this.dipendenti.length == 0) {

                            this.hideShowSecNav(false);

                        }

                        if (this.dipendenti.length > 0) {

                            this.hideShowSecNav(true);

                            $("#name_dip_sel").html(info_month.full_name);

                            // abilito le frecce a seconda se sono sul primo o l'ultimo elemento

                            var cur_pos = 0;
                            var find_pos = 0;

                            for (var dip of this.dipendenti) {

                                if (info_month.full_name == dip.fld_name) {
                                    find_pos = cur_pos;
                                }

                                cur_pos = cur_pos + 1;

                            }

                            $("#btn_indietro_dip").show();
                            $("#btn_avanti_dip").show();

                            if (find_pos == 0) {

                                $("#btn_indietro_dip").hide();

                            }

                            console.log("FIND POS: ", find_pos);
                            console.log("DIP LEN: ", this.dipendenti.length);

                            if (find_pos == (this.dipendenti.length - 1)) {

                                $("#btn_avanti_dip").hide();

                            }




                        }


                        this.syncMOnthIcons();


                        if (this.currAzienda != "") {

                            $("#res_full_name").html("<b>" + this.currAzienda + "</b>");


                        }
                        else {

                            this.loadAziendaUserLogged();

                        }

                        if (this.viewRep == "N") {
                            $("#Reperibilità").hide();
                        }


                        if (this.viewInterv == "N") {
                            $("#Intervento").hide();
                            $("#Dalle").hide();
                            $("#Alle").hide();
                        }


                        // imposto i giorni disabilitati

                        this.datesDisabled = arrTimesheet.days_disable;

                        var datesDisabled = [];

                        for (var xx = 0; xx < arrTimesheet.days_disable.length; xx++) {

                            var elements33 = arrTimesheet.days_disable[xx].split("-");

                            //console.log("ELEMENTS33: ", elements33);

                            var v_anno33 = parseInt(elements33[0]);
                            var v_mese33 = parseInt(elements33[1]) - 1;
                            var v_giorno33 = parseInt(elements33[2]);

                            datesDisabled.push({
                                start: new Date(v_anno33, v_mese33, v_giorno33),
                                end: new Date(v_anno33, v_mese33, v_giorno33)
                            });

                        }

                        console.log("DATES DISABLED: ", datesDisabled);



                        this.disabledDates = datesDisabled;






                        setTimeout(() => {

                            this.funcEvent();

                        }, 300);



                    });
            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }

            this.showSpinner = false;
            console.log(response);



            this.resetSumDay();
            this.updateSumTotals();

            //Setup status at top
            this.setupNavTop();

            //if ( !this.setupNavBottomDone)
            this.setupNavBottom();

        },

        pageChange(obj) {



            this.resetDay();

            this.monthArray = [];
            this.dayFormatted = "";
            this.curYear = obj.year;
            this.curMonth = obj.month;
            this.forecast[0].quantity = 0;
            this.forecast[1].quantity = 0;
            this.forecast[2].quantity = 0;
            this.forecast[3].quantity = 0;
            this.forecast[4].quantity = 0;
            this.forecast[6].quantity = 0;

            this.entrata1 = "";
            this.entrata2 = "";
            this.uscita1 = "";
            this.uscita2 = "";

            this.day_editable = false;

            this.syncMonth();



        },

        onDayClickFerie(day) {

            var that = this;

            console.log("DAY: ", day);
            console.log("RANGE: ", that.rangeCal);
            console.log("CONF CALENDAR: ", that.confCalendar);

            //console.log("DATES DISABLED: ", this.disabledDatesCal);

            var cur_date = day.year.toString() + "-" + day.month.toString().padStart(2, '0') + "-" + day.day.toString();

            //console.log("CUR DATE: ", cur_date);


            var is_disabled = 0;
            for (var xx6 = 0; xx6 < that.disabledDatesCal.length; xx6++) {

                var date_disabled = new Date(that.disabledDatesCal[xx6].start);
                var giorno = date_disabled.getDate();
                var mese = date_disabled.getMonth() + 1;
                var anno = date_disabled.getFullYear();

                var dataDisabled = anno.toString() + "-" + mese.toString().padStart(2, '0') + "-" + giorno.toString().padStart(2, '0');

                //console.log("DATE DISABLED: ", dataDisabled);

                if (cur_date == dataDisabled) {
                    is_disabled = 1;
                }
            }

            console.log("DAY_DISABLED: ", is_disabled);

            var viewFields = 0;

            that.updateDay = 0;

            if (is_disabled == 0) {

                if ((that.rangeCal.start != undefined) && (that.rangeCal.end != undefined)) {

                    var dateStart = new Date(that.rangeCal.start);
                    var giornoStart = dateStart.getDate();
                    var meseStart = dateStart.getMonth() + 1;
                    var annoStart = dateStart.getFullYear();
                    var startDate = annoStart.toString() + "-" + meseStart.toString().padStart(2, '0') + "-" + giornoStart.toString().padStart(2, '0');

                    var dateEnd = new Date(that.rangeCal.end);
                    var giornoEnd = dateEnd.getDate();
                    var meseEnd = dateEnd.getMonth() + 1;
                    var annoEnd = dateEnd.getFullYear();
                    var endDate = annoEnd.toString() + "-" + meseEnd.toString().padStart(2, '0') + "-" + giornoEnd.toString().padStart(2, '0');

                    console.log("START DATE: ", startDate);
                    console.log("END DATE: ", endDate);


                    if (startDate == endDate) {
                        viewFields = 1;
                    }


                    if (startDate != endDate) {

                        console.log("ok");

                        let currentDate = new Date(startDate);
                        let stopDate = new Date(endDate);
                        let dateArray = [];

                        while (currentDate <= stopDate) {
                            dateArray.push(currentDate.toISOString().split('T')[0]); // Formatta la data come YYYY-MM-DD
                            currentDate.setDate(currentDate.getDate() + 1); // Incrementa di un giorno
                        }

                        console.log("PERIOD: ", dateArray);

                        var findDay = 0;

                        for (var xx9 = 0; xx9 < dateArray.length; xx9++) {

                            var vDay = dateArray[xx9];

                            $(that.confCalendar).each(function (oki, ok) {

                                console.log("DATA", ok[0]);

                                if (vDay == ok[0]) {
                                    findDay = 1;
                                }

                            });


                        }

                        console.log("FIND DAY: ", findDay);

                        if (findDay == 0) {
                            viewFields = 1;
                        }

                        if (findDay == 1) {

                            that.$swal({
                                icon: "errore",
                                text: "La modifica è possibile solo selezionando un singolo giorno",
                                showConfirmButton: false,
                                timer: 2000
                            });


                        }


                    }


                    if (viewFields == 1) {

                        var strCausale = "";
                        var strOraInizio = "";
                        var strOraFine = "";
                        var strNote = "";

                        $(that.confCalendar).each(function (oki, ok) {

                            console.log("DATA", ok[0]);

                            var elements55 = ok[1].split("|");

                            console.log("ELEMENTS: ", elements55);

                            if (cur_date == ok[0]) {
                                strCausale = elements55[1];
                                strOraInizio = elements55[4];
                                strOraFine = elements55[5];
                                strNote = elements55[6];

                                that.updateDay = 1;

                                var dateSel = day.year.toString() + "-" + day.month.toString().padStart(2, "0") + "-" + day.day.toString().padStart(2, "0");

                                that.daySelForUpdate = dateSel;

                                that.idHeaderDayForUpdate = elements55[7];
                            }


                        });

                        console.log("CAUSALE: ", strCausale);
                        console.log("ORA INIZIO: ", strOraInizio);
                        console.log("ORA FINE: ", strOraFine);
                        console.log("NOTE: ", strNote);
                        console.log("UPDATE DAY: ", that.updateDay);
                        console.log("ID HEADER: ", that.idHeaderDayForUpdate);

                        this.dayFormatted = day.ariaLabel;
                        this.viewCausale = true;
                        this.viewNote = true;

                        that.setupButtonsFerie(true, 0);

                        if (that.updateDay == 1) {

                            var id_causale = 0;
                            if (strCausale == "Ferie") {
                                id_causale = 1;
                            }
                            if (strCausale == "Permesso Retribuito") {
                                id_causale = 2;
                            }
                            if (strCausale == "Permesso Non Retribuito") {
                                id_causale = 3;
                            }
                            if (strCausale == "Permesso Studio") {
                                id_causale = 9;
                            }
                            if (strCausale == "Donazione sangue") {
                                id_causale = 10;
                            }

                            console.log("ID_CAUSALE: ", id_causale);

                            that.causale = id_causale;

                            if (strCausale == "Ferie") {
                                if ((strOraInizio == "") && (strOraFine == "")) {
                                    that.vediGiornata = true;
                                    that.tipoGiornata = "G_COMPLETA";
                                }
                                if ((strOraInizio == "09:00:00") && (strOraFine == "18:00:00")) {
                                    that.vediGiornata = true;
                                    that.tipoGiornata = "G_COMPLETA";
                                }

                            }

                            if (strCausale != "Ferie") {

                                that.singleMode = true;

                                if (strOraInizio != "") {

                                    var elements66 = strOraInizio.split(":");
                                    var newHour = elements66[0] + ":" + elements66[1];
                                    that.timeStartCal = newHour;

                                }

                                if (strOraFine != "") {

                                    var elements77 = strOraFine.split(":");
                                    var newHour77 = elements77[0] + ":" + elements77[1];
                                    that.timeEndCal = newHour77;

                                }

                            }

                            that.notes = strNote;

                            that.setupButtonsFerie(true, 1);

                        }

                    }


                }

            }

            console.log("VIEW FIELDS: ", viewFields);




        },

        onDayClick(day) {


            console.log("N PROJ: ", this.countProjAssoc);

            if (this.lastDayId != day.id) {
                this.dayStatusSelected = 1;
                this.lastDayId = day.id;
            } else {

                if (this.dayStatusSelected == 1) {
                    this.dayStatusSelected = 0;
                } else {
                    this.dayStatusSelected = 1;
                }

            }


            console.log("SELECIONATO: ", this.dayStatusSelected);


            $("#Ordinarie").hide();
            $("#Assenza").hide();
            $("#Straordinari").hide();
            $("#Causale").hide();
            $("#Descrizione").hide();
            $("#Reperibilità").hide();
            $("#Intervento").hide();
            $("#Dalle").hide();
            $("#Alle").hide();
            $("#Progetto").hide();
            this.dayFormatted = "";

            console.log("DISABLE DATES: ", this.datesDisabled);



            var dayDisabled = 0;
            if (this.datesDisabled.length > 0) {

                for (var xx4 = 0; xx4 < this.datesDisabled.length; xx4++) {
                    if (this.datesDisabled[xx4] == day.id) {
                        dayDisabled = 1;
                    }
                }


            }

            console.log("DAY DISABLED: ", dayDisabled);



            if (dayDisabled == 0) {

                if (this.dayStatusSelected == 1) {

                    if (this.IsPartitaIva == "S") {
                        $("#Ordinarie").show();
                        $("#Assenza").show();
                        $("#Causale").show();
                        $("#Progetto").show();
                    }

                    $("#Straordinari").show();
                    $("#Descrizione").show();

                    if (this.viewRep == "S") {
                        $("#Reperibilità").show();
                    }

                    if (this.viewInterv == "S") {
                        $("#Intervento").show();
                        $("#Dalle").show();
                        $("#Alle").show();
                    }

                    if (this.countProjAssoc > 1) {
                        $("#Progetto").show();
                    }



                    console.log("MONTH ARRAY: ", this.monthArray);


                    this.curDay = day.day;

                    this.forecast[0].quantity = 0;
                    this.forecast[1].quantity = 0;
                    this.forecast[2].quantity = 0;
                    this.forecast[3].quantity = 0;
                    this.forecast[4].quantity = 0;
                    this.forecast[6].quantity = 0;
                    this.forecast[7].quantity = 0;

                    this.entrata1 = "";
                    this.entrata2 = "";
                    this.uscita1 = "";
                    this.uscita2 = "";

                    //const idx = this.days.findIndex(d => d.id === day.id);
                    console.log(day);
                    // this.forecast[0].day = day.ariaLabel;
                    if (day) this.dayFormatted = day.ariaLabel;

                    try {
                        var ord = this.monthArray[day.day + "_1"].hour_ordinario;
                        var straord = this.monthArray[day.day + "_1"].hour_straordinario;
                        var assenza = this.monthArray[day.day + "_1"].hour_activity;
                        var idtypeactivity = this.monthArray[day.day + "_1"].idtypeactivity;
                        var description = this.monthArray[day.day + "_1"].note;
                        var rep = this.monthArray[day.day + "_1"].hour_reperibilita;
                        var interv = this.monthArray[day.day + "_1"].hour_intervento;
                        var hour_from_int = this.monthArray[day.day + "_1"].hour_from_intervento;
                        var hour_to_int = this.monthArray[day.day + "_1"].hour_to_intervento;

                        console.log("EX", this.monthArray[day.day + "_1"].entrata1);

                        this.entrata1 = this.monthArray[day.day + "_1"].entrata1.substr(0, 5);
                        this.entrata2 = this.monthArray[day.day + "_1"].entrata2.substr(0, 5);
                        this.uscita1 = this.monthArray[day.day + "_1"].uscita1.substr(0, 5);
                        this.uscita2 = this.monthArray[day.day + "_1"].uscita2.substr(0, 5);

                        console.log("DESCRIZIONE: ", description);



                        $("#inputStraordinari").val(straord + " H");
                        if (description == null) {
                            description = "";
                        }
                        if (description == "null") {
                            description = "";
                        }

                        this.valDescription = description;
                        $("#inputDescrizione").val(description);




                        $("#inputReperibilità").val(rep + " H");
                        $("#inputIntervento").val(interv + " H");

                        if (hour_from_int != "") {

                            if (hour_from_int != null) {
                                var elements3 = hour_from_int.split(":");

                                hour_from_int = elements3[0] + ":" + elements3[1];

                                $("#inputDalle").val(hour_from_int);

                            }


                        }

                        if (hour_to_int != "") {

                            if (hour_to_int != null) {
                                var elements4 = hour_to_int.split(":");

                                hour_to_int = elements4[0] + ":" + elements4[1];

                                $("#inputAlle").val(hour_to_int);

                            }



                        }

                        //alert (this.entrata1);

                        this.day_editable = Boolean(this.monthArray[day.day + "_1"].day_editable);
                        this.forecast[0].quantity = ord;
                        this.forecast[1].quantity = straord;
                        this.forecast[2].quantity = assenza;
                        this.forecast[3].quantity = idtypeactivity;
                        this.forecast[4].quantity = parseFloat(ord) + parseFloat(straord);
                        this.forecast[6].quantity = rep;
                        this.forecast[7].quantity = interv;

                        this.forecast[8].timeStart = hour_from_int;
                        this.forecast[9].timeEnd = hour_to_int;



                        if (this.countProjAssoc > 1) {

                            var tot_ord = 0;
                            for (var xOrd44 = 1; xOrd44 <= 3; xOrd44++) {

                                var xKeyDay22 = this.curDay + "_" + xOrd44.toString();

                                if (xKeyDay22 in this.monthArray) {
                                    tot_ord = tot_ord + parseFloat(this.monthArray[xKeyDay22].hour_ordinario)
                                }

                            }

                            this.forecast[0].quantity = tot_ord;

                            var tot_ore_ass = this.piano_orario[0].lunedi - tot_ord;

                            this.forecast[2].quantity = tot_ore_ass;


                        }







                    } catch (error) {
                        console.log(error);
                    }

                }

            }

            if (this.dayStatusSelected == 0) {

                console.log("DAY DESELECTED");


                $(".clStatusTS").focus();
            }





        },
    },

    data() {






        var today = new Date();
        var curMonth = today.getMonth() + 1;
        var curYear = today.getFullYear();
        var curDay = today.getDate();

        var todos = [];

        // dichiarazioni
        return {

            tipoContratto: "",

            showKm: false,

            allTypes: [{
                img: 'https://app.emtool.eu/public/_lib/img/hotel_easyer.png',
                title: 'Hotel',
                "id": 5,
                icon: "mdi-account-lock-open",
                "color": "red",
                width: 32,
                height: 32,
                sizeAvatar: "32px"
            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/carburante_easyer.png',
                title: 'Carburante',
                "id": 8,
                icon: "mdi-account-lock-open",
                "color": "red",
                width: 32,
                height: 32,
                sizeAvatar: "32px"
            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/vitto_easyer.png',
                title: 'Vitto',
                "id": 10,
                icon: "mdi-account-lock-open",
                "color": "red",
                width: 32,
                height: 32,
                sizeAvatar: "32px"
            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/aereo_easyer.png',
                title: 'Viaggio',
                "id": 15,
                icon: "mdi-account-lock-open",
                "color": "red",
                width: 32,
                height: 32,
                sizeAvatar: "32px"

            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/spese/17.png',
                title: 'Noleggio',
                "id": 19,
                icon: "mdi-account-lock-open",
                "color": "red",
                width: 25,
                height: 25,
                sizeAvatar: "25px"

            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/altro_easyer.png',
                title: 'Altro',
                "id": 2,
                icon: "mdi-account-lock-open",
                "color": "red",
                width: 32,
                height: 32,
                sizeAvatar: "32px"

            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/euro-km-easyer.png',
                title: '€ * KM',
                "id": 20,
                icon: "mdi-account-lock-open",
                "color": "red",
                width: 32,
                height: 32,
                sizeAvatar: "32px"

            },

            ],

            spese: {
                idprojects_cdc_type: "",
                km: "",
                description: "",
                data_trasferta: ""
            },

            showPicker: false,

            testoPulsante: "Upload Foto",
            titoloPopup2: "Allega Foto",
            fileAccettati: ".png,.jpg,.jpeg,.pdf",
            nomeFilePresente: "",
            dimFilePresente: "",
            dimKbFilePresente: 0,
            dimMinKbFile: 100000,
            dimMaxKbFile: 5000000,
            folderServerToSave: "/documents/easyer/",
            folderDoxToSave: "/Products/Easyer/",
            removeUploadFile: 0,
            attachRemoveVisura: false,
            attachPresent: false,

            oreTotaliGiornataProgetti: 0,
            dataGiornataProgetti: "",

            valuesProjects: [],


            isMobile: false,

            oreTotPermessiACAP: 0,

            oreTotFerieACAP: 0,

            mesePeriodo: "",

            idDisease: 0,

            daySelected: "",


            certificato: "",

            numeroProtocollo: "",

            isUpdateDisease: 0,

            viewProtocollo: false,

            PeriodiEditAbilitati: [],


            textNote: "",

            periodoDalUpd: null,
            periodoAlUpd: null,

            protocolli: [],

            currentYearDisease: "",

            currentMonthDisease: "",

            disabledDatesDisease: [],

            rangeDisease: {},

            rangeDiseaseEnabled: true,

            oreTotaliMalattia: 0,

            daySelForUpdate: "",

            idHeaderDayForUpdate: 0,

            updateDay: 0,

            causaliFerie: [],

            notes: "",

            singleMode: false,

            tipoGiornata: "G_COMPLETA",

            vediGiornata: false,

            causale: "",

            timeStartCal: null,
            timeEndCal: null,

            rangeCal: {
                /*  start: new Date(),
                 end: new Date() */
            },

            viewCausale: false,
            viewNote: false,
            viewSelezione: false,

            PeriodiDisabilitati: [],

            confCalendar: null,

            currentYearFerie: "",

            currentMonthFerie: "",


            disabledDatesCal: [],

            oreTotFerie: 0,

            oreTotPermRetr: 0,

            sezione: "Timesheet",

            lista_orari: [
                "09:00",
                "09:30",
                "10:00",
                "10:30",
                "11:00",
                "11:30",
                "12:00",
                "12:30",
                "13:00",
                "13:30",
                "14:00",
                "14:30",
                "15:00",
                "15:30",
                "16:00",
                "16:30",
                "17:00",
                "17:30",
                "18:00",
            ],

            IsUserPartitaIva: 0,

            valDescription: "",


            IsPartitaIva: "N",

            datesDisabled: [],

            lastDayId: "",

            dayStatusSelected: 0,

            idProjDefault: 0,

            IsPartiIVAPartTime: "N",

            countProjAssoc: 0,

            disabledDates: [],


            viewRep: "N",

            viewButtonInterv: "N",

            viewInterv: "N",


            oreContratto: 0,

            progetti: [],

            searchProgetti: "",

            sheetProgetti: false,

            totReperibilitaMese: "0",

            totInterventoMese: "0",


            showColumns: false,




            dipendetiElenco: [],

            isDipEbadge: 0,
            entrata1: "",
            entrata2: "",
            uscita1: "",
            uscita2: "",

            curResource: "",

            itemsEbadge: ['Totali', 'Ebadge',],
            tab: null,
            menuAziende: false,
            search: '',
            searchDipendenti: '',
            todos,
            globalArrAziende: {},
            setupNavBottomDone: false,
            currDipendente: "",
            currDipendenteObj: {},
            currAzienda: "",
            su: 'N',
            aa: 'N',
            sheet: false,

            allStatus: [{
                img: 'keep.png',
                title: 'Aperto',
                "id": 1,
                icon: "mdi-account-lock-open",
                "color": "red"
            },
            {
                img: 'hangouts.png',
                title: 'For approval',
                "id": 2,
                icon: "mdi-account-arrow-right",
                "color": "yellow"
            },
            {
                img: 'inbox.png',
                title: 'Chiuso',
                "id": 3,
                icon: "mdi-account-lock",
                "color": "green"
            },
            ],
            tiles: [{
                img: 'keep.png',
                title: 'Keep'
            },
            {
                img: 'inbox.png',
                title: 'Inbox'
            },
            {
                img: 'hangouts.png',
                title: 'Hangouts'
            },
            {
                img: 'messenger.png',
                title: 'Messenger'
            },
            {
                img: 'google.png',
                title: 'Google+'
            },
            {
                img: 'keep.png',
                title: 'Keep'
            },
            {
                img: 'inbox.png',
                title: 'Inbox'
            },
            {
                img: 'hangouts.png',
                title: 'Hangouts'
            },
            {
                img: 'messenger.png',
                title: 'Messenger'
            },
            {
                img: 'google.png',
                title: 'Google+'
            },
            {
                img: 'keep.png',
                title: 'Keep'
            },
            {
                img: 'inbox.png',
                title: 'Inbox'
            },
            {
                img: 'hangouts.png',
                title: 'Hangouts'
            },
            {
                img: 'messenger.png',
                title: 'Messenger'
            },
            {
                img: 'google.png',
                title: 'Google+'
            },
            {
                img: 'keep.png',
                title: 'Keep'
            },
            {
                img: 'inbox.png',
                title: 'Inbox'
            },
            {
                img: 'hangouts.png',
                title: 'Hangouts'
            },
            {
                img: 'messenger.png',
                title: 'Messenger'
            },
            {
                img: 'google.png',
                title: 'Google+'
            },
            {
                img: 'keep.png',
                title: 'Keep'
            },
            {
                img: 'inbox.png',
                title: 'Inbox'
            },
            {
                img: 'hangouts.png',
                title: 'Hangouts'
            },
            {
                img: 'messenger.png',
                title: 'Messenger'
            },
            {
                img: 'google.png',
                title: 'Google+'
            },
            {
                img: 'keep.png',
                title: 'Keep'
            },
            {
                img: 'inbox.png',
                title: 'Inbox'
            },
            {
                img: 'hangouts.png',
                title: 'Hangouts'
            },
            {
                img: 'messenger.png',
                title: 'Messenger'
            },
            {
                img: 'google.png',
                title: 'Google+'
            },
            {
                img: 'keep.png',
                title: 'Keep'
            },
            {
                img: 'inbox.png',
                title: 'Inboex'
            },
            {
                img: 'hangouts.png',
                title: 'Hangouts'
            },
            {
                img: 'messenger.png',
                title: 'Meessenger'
            },
            {
                img: 'google.png',
                title: 'Gooegle+'
            },
            {
                img: 'keep.png',
                title: 'Keeep'
            },
            {
                img: 'inbox.png',
                title: 'Inbox'
            },
            {
                img: 'hangouts.png',
                title: 'Hangouts'
            },
            {
                img: 'messenger.png',
                title: 'Messenger'
            },
            {
                img: 'google.png',
                title: 'Google+'
            },
            {
                img: 'keep.png',
                title: 'K3eep'
            },
            {
                img: 'inbox.png',
                title: 'Inbox'
            },
            {
                img: 'hangouts.png',
                title: 'Hango3uts'
            },
            {
                img: 'messenger.png',
                title: 'Messen3ger'
            },
            {
                img: 'google.png',
                title: 'Google3+'
            },
            ],

            admindialog: false,
            sheetStatus: false,
            sheetAziende: false,
            sheetDipendenti: false,
            /*  curDayReadOnly:true, */
            totPresenzaMese: "0",
            totAssenzaMese: "0",
            totStraordinariMese: "0",
            causali: [],
            aziende: [],
            dipendenti: [],
            day_editable: false,
            curStatus: "Open",
            piano_orario: {},
            colorsStati: {
                "Close": "green",
                "For approval": "yellow",
                "Open": "red"
            },

            datesPoint: [new Date("2021-12-12"), new Date("2021-12-13")],
            showSpinner: false,
            curMonth: curMonth,
            curYear: curYear,
            curDay: curDay,

            monthArray: [],
            labels: ["SU", "MO", "TU", "WED", "TH", "FR", "SA"],
            time: 0,
            itemsQuantity: [
                "0",
                "0.5",
                "1",
                "1.5",
                "2",
                "2.5",
                "3",
                "3.5",
                "4",
                "4.5",
                "5",
                "5.5",
                "6",
                "6.5",
                "7",
                "7.5",
                "8",
                "8.5",
                "9",
                "9.5",
                "10",
            ],
            forecast: [{
                day: "Ordinarie",
                icon: "mdi-account-hard-hat",
                quantity: "0",
                description: "",
                timeStart: "",
                timeEnd: "",
                viewInterv: "N",
            },
            {
                day: "Straordinari",
                icon: "mdi-account-plus",
                quantity: "0",
                description: "",
                timeStart: "",
                timeEnd: "",
                viewInterv: "N",
            },
            {
                day: "Assenza",
                icon: "mdi-account-minus",
                quantity: "0",
                description: "",
                timeStart: "",
                timeEnd: "",
                viewInterv: "N",

            },
            {
                day: "Causale",
                icon: "mdi-format-list-bulleted-type",
                quantity: "Festività",
                description: "",
                timeStart: "",
                timeEnd: "",
                viewInterv: "N",

            },
            {
                day: "Totale",
                icon: "mdi-expand-all",
                quantity: "8",
                description: "",
                timeStart: "",
                timeEnd: "",
                viewInterv: "N",

            },

            {
                day: "Descrizione",
                icon: "mdi-file-document-outline",
                quantity: "Festività",
                description: "",
                timeStart: "",
                timeEnd: "",
                viewInterv: "N",
            },

            {
                day: "Reperibilità",
                icon: "mdi-home-search",
                quantity: "0",
                description: "",
                timeStart: "",
                timeEnd: "",
                viewInterv: "N",

            },

            {
                day: "Intervento",
                icon: "mdi-account-hard-hat",
                quantity: "0",
                description: "",
                timeStart: "",
                timeEnd: "",
                viewInterv: "N",

            },


            {
                day: "Dalle",
                icon: "mdi-clock-time-eight-outline",
                quantity: "Festività",
                description: "",
                timeStart: "",
                timeEnd: "",
                viewInterv: "N",
            },

            {
                day: "Alle",
                icon: "mdi-clock-time-eight-outline",
                quantity: "Festività",
                description: "",
                timeStart: "",
                timeEnd: "",
                viewInterv: "N",
            },

            {
                day: "Progetto",
                icon: "mdi-star-minus-outline",
                quantity: "Festività",
                description: "",
                timeStart: "",
                timeEnd: "",
                viewInterv: "N",
            },

            ],

            dayFormatted: "",
            dayAbout: "San Cristoforo",

            attributesOld: [{
                dot: {
                    style: {
                        "background-color": "brown",
                    },
                },
                dates: this.datesPoint
                /*  [
                                   new Date( '2021-12-12'), // Jan 12th
                                   new Date('2021-12-13'), // Jan 26th
                                   new Date(2018, 0, 15), // Jan 15th
                               ] */
                ,
            },

                /*  {
                        dot: "red",
                        dates: this.datesPoint,
                        }, */
            ],

            /*   date: new Date(), */
            date: "",

            /*   "it" : it,
            en: en,
            es: es,
            de: de */
        };
    },

    computed: {


        rangeDetailDisease() {
            var start = this.rangeDisease.start;
            var end = this.rangeDisease.end;

            var text = "";

            if (start == undefined) return "";

            var $mesi = [

                "gen.",
                "feb.",
                "mar.",
                "apr.",
                "mag.",
                "giu.",
                "lug.",
                "ago.",
                "set.",
                "ott.",
                "nov.",
                "dic.",
            ];

            var startLiteral = start.getDate() + " " + $mesi[start.getMonth()] + " " + start.getFullYear();

            var endLiteral = end.getDate() + " " + $mesi[end.getMonth()] + " " + end.getFullYear();

            if (startLiteral != endLiteral) {
                text = startLiteral + " - " + endLiteral;
                //this.setSingleMode(false);

                this.setTimeCal();

            } else {
                text = startLiteral;
                //this.setSingleMode(true);

            }
            return text;

        },




        rangeDetailCal() {
            var start = this.rangeCal.start;
            var end = this.rangeCal.end;

            var text = "";

            if (start == undefined) return "";

            var $mesi = [

                "gen.",
                "feb.",
                "mar.",
                "apr.",
                "mag.",
                "giu.",
                "lug.",
                "ago.",
                "set.",
                "ott.",
                "nov.",
                "dic.",
            ];

            var startLiteral = start.getDate() + " " + $mesi[start.getMonth()] + " " + start.getFullYear();

            var endLiteral = end.getDate() + " " + $mesi[end.getMonth()] + " " + end.getFullYear();

            if (startLiteral != endLiteral) {
                text = startLiteral + " - " + endLiteral;
                //this.setSingleMode(false);

                this.setTimeCal();

            } else {
                text = startLiteral;
                //this.setSingleMode(true);

            }
            return text;

        },

        filteredProgetti() {


            return _.orderBy(this.progetti.filter(item => {
                if (!this.searchProgetti) return this.progetti;
                return (item.toLowerCase().includes(this.searchProgetti.toLowerCase()));
            }), 'headline')
        },

        filteredDipendenti() {

            var elements = _.orderBy(this.dipendenti.filter(item => {
                if (!this.searchDipendenti) return this.dipendenti;
                return (item.full_name.toLowerCase().includes(this.searchDipendenti.toLowerCase()));
            }), 'headline');

            console.log("ELEMENTS: ", elements);

            var arrDip = [];
            var n_row = 1;

            for (var dip of elements) {

                dip.n_dip = n_row;

                n_row = n_row + 1;

                arrDip.push(dip);

            }


            return arrDip;
        },



        filteredAziende() {

            console.log("AZI 1: ", this.aziende);

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        attributes() {
            var ok = 0;
            console.log(ok);
            return [

                // Attributes for todos
                ...this.todos.map((todo) => ({
                    dates: todo.dates,
                    dot: todo.dot ? {
                        color: todo.color,
                        class: todo.isComplete ? "opacity-75" : "",
                    } : false,
                    bar: todo.bar ? todo.bar : false,
                    popover: {
                        label: todo.description,
                        hideIndicator: false,
                    },
                    customData: todo,
                })),
            ];
        },

        attributesCal() {
            var ok = 0;
            console.log(ok);
            return [

                // Attributes for todos
                ...this.todos.map((todo) => ({
                    dates: todo.dates,
                    dot: todo.dot ? {
                        color: todo.color,
                        class: todo.isComplete ? "opacity-75" : "",
                    } : false,
                    bar: todo.bar ? todo.bar : false,
                    popover: {
                        label: todo.description,
                        hideIndicator: false,
                    },
                    customData: todo,
                })),
            ];
        },

        attributeDiseaseCal() {
            var ok = 0;
            console.log(ok);
            return [

                // Attributes for todos
                ...this.todos.map((todo) => ({
                    dates: todo.dates,
                    dot: todo.dot ? {
                        color: todo.color,
                        class: todo.isComplete ? "opacity-75" : "",
                    } : false,
                    bar: todo.bar ? todo.bar : false,
                    popover: {
                        label: todo.description,
                        hideIndicator: false,
                    },
                    customData: todo,
                })),
            ];
        },

    },

    components: {
        datepicker,
        RiseLoader,
        VueAutonumeric,
    },

};
</script>

<style>


#fldNoteNataSpeseCalTs {
    height: 50px !important;
}


#timesheetCalendarRis .rowPrevFile .dropify-wrapper {
    height: 187px !important;
    width: 363px !important;
    margin-bottom: 20px;
    margin-left: 1px;
}


#timesheetCalendarRis .buttonFileEditSpesa {
    border: 1px solid black;
    padding: 10px;
    width: 162px;
}

#timesheetCalendarRis .containButtonFileEditSpesa {
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: center;
}


#timesheetCalendarRis .clViewProjects {
    cursor: pointer;
    margin-left: 10px;
}


.contVoices .v-list-item {
    max-height: 56px;
    ;
}


#timesheetCalendarRis .minusContainer {
    margin-right: 10px !important;
}

#timesheetCalendarRis .plusContainer {
    background: white !important;
    border: 1px solid !important;
    cursor: pointer !important;
    max-height: 15px !important;
    min-width: 15px !important;
    margin-right: 10px !important;
}


.cl_res_full_name {
    padding-left: 8px;
    padding-top: 2px;
}

#timesheetCalendarRis .cl_cont_azi_res_ts {
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: flex-start !important;
    min-height: 70px !important;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    padding-top: 3px !important;
    background-color: white;
    border-color: #f5cf2e;
}

#timesheetCalendarRis .clferiepermessi {
    align-items: center !important;
    margin-top: 15px;
}

#timesheetCalendarRis .cont_names {
    max-width: 93%;
    display: flex;
    flex-direction: column;
    min-width: 100%;
}



.contQuanties {
    max-width: 420px !important;
}


#timesheetCalendarRis .selprogetti {
    max-width: 23px;
    min-height: 23px;
    margin-left: 208px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 23px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: -10px;
}

.cl_input_text_row {
    font-size: 11px !important;
    background-color: white;
    margin-top: 7px;
}

.cl_input_text_row:disabled {
    font-size: 11px !important;
    background-color: inherit;
    margin-top: 7px;
}

.cl_ora_interv {
    border-bottom: 1px solid #ced4da;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 4px;
    padding-left: 0px;
    margin-top: 10px;
    --background: white;
}


.cont_ts_un_prog .val_status {
    width: 10px !important;
}

.cont_ts_un_prog .val_sede_cliente {
    width: 10px !important;
}


.clRowHolidaysPermitsUser {
    display: flex;
    margin-left: 10px;
}



#myTable .dataTable tbody tr {
    padding: 5px !important;
}






.deleteRow img {
    width: 16px;
    cursor: pointer;
}

#timesheetCalendarRis .v-list-item {
    margin-left: 20px;
}

#timesheetCalendarRis select:focus-visible {
    outline: 1px dashed rgb(247, 247, 247);
}

#timesheetCalendarRis .v-select__slot {
    font-size: 10px;
}

#timesheetCalendarRis .v-list-item__title {
    font-size: 12px;
}

#timesheetCalendarRis .vc-day {
    min-height: 36px !important;
    padding: 8px;
}

#timesheetCalendarRis .contQuanties {
    max-width: 420px !important;
}

#timesheetCalendarRis .vc-title {
    font-size: 12px !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
    color: black !important;
}

@media only screen and (max-width: 600px) {

    #timesheetCalendarRis .v-menu__content REMOVE {
        left: 0px !important;
        min-width: 100vw !important;
        top: auto !important;
        bottom: 0px !important;
        position: fixed !important;
        min-height: 200px;
        /*   min-height: 300px; */

        background: #80808091;
        background: white;

        /*  pointer-events: none; */

    }

    #timesheetCalendarRis .v-menu__content .v-select-listREMOVE {
        max-height: 100%;
        bottom: 0px;
        position: absolute;
        overflow: auto;
        min-width: 100vw;
    }

    #timesheetCalendarRis .vc-day {
        min-height: 2px !important;
        padding: 6px 8px;
    }

    #timesheetCalendarRis .contQuanties {
        max-width: 320px !important;
    }
}

#timesheetCalendarRis span.titletotals {
    color: black;
    font-weight: bold;
    display: flex;
    align-items: center;
}

#timesheetCalendarRis .totals {
    transform: translate(0px, 78px);
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    font-size: 12px;
    margin-top: 17px;
}

#timesheetCalendarRis .contDayFormatted {}

#timesheetCalendarRis .dayFormatted {
    color: black !important;
    font-weight: bolder;
}

#timesheetCalendarRis .spanStatus {
    color: red;
}

#timesheetCalendarRis .rowStatus {
    display: none;
}

#timesheetCalendarRis .progressBar {
    position: fixed;
    top: 25%;
    left: 25%;
    position: fixed;
    justify-content: center;
    display: flex;
    right: 0;
    left: 0;
    z-index: 100000;
    width: 100%;
    top: 0;
}

#timesheetCalendarRis .contcalendar .v-card__actions {
    padding: 0px !important;
    padding-top: 0px !important;
}

#timesheetCalendarRis .contcalendarferie .v-card__actions {
    padding: 0px !important;
    padding-top: 0px !important;
}

#timesheetCalendarRis .nocolor {
    border: none !important;
    box-shadow: none !important;
}

#timesheetCalendarRis {
    padding-top: 0px;
    max-width: 90vw;
    margin: 0 auto;
}

#timesheetCalendarRis .contcalendar {
    min-width: 60vw;
    align-content: center;
}

#timesheetCalendarRis .contcalendarferie {
    min-width: 60vw;
    align-content: center;
    margin-top: 83px;
}

#timesheetCalendarRis .vc-container {
    --day-content-height: 50px;
    --day-content-width: 50px;
}

#timesheetCalendarRis .vc-container.vc-is-expanded {
    border: none;
}

#timesheetCalendarRis .selectHour {
    padding-left: 25px;
    font-size: 12px !important;
}

#timesheetCalendarRis .v-list-item {
    min-height: 42px;
}

#timesheetCalendarRis .v-list-item__icon {
    margin: 10px 0px;
}

#timesheetCalendarRis .totaleCombo input {
    color: black !important;
    font-weight: 600;
}

#timesheetCalendarRis html:not(.hydrated) body {
    display: block;
}

#timesheetCalendarRis .vc-header {
    font-family: 'Montserrat' !important;

}


#timesheetCalendarRis .minus {
    cursor: pointer !important;
    border: 0 !important;
    width: 15px !important;
    height: 15px !important;
}

#timesheetCalendarRis .plus {
    cursor: pointer !important;
    border: 0 !important;
    width: 15px !important;
    height: 15px !important;
}

#timesheetCalendarRis .text-left {
    min-height: 10px !important;
    max-height: 10px !important;
}


#timesheetCalendarRis .addbuttons {
    display: flex;
    margin-left: 5px;
    align-items: center;
    font-size: 20px;
    /* padding-bottom: 12px; */
}

#timesheetCalendarRis .conSelect {
    display: flex;

}

#timesheetCalendarRis div[disabled] {
    opacity: 0.2;
    cursor: default;
}
</style>

<style>
#timesheetCalendarRis input[type="number"] {
    -moz-appearance: textfield;
}

#timesheetCalendarRis input::-webkit-outer-spin-button,
#timesheetCalendarRis input::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

#timesheetCalendarRis .detailtotals {
    display: none;
}

#timesheetCalendarRis .vc-bars {
    max-width: 18px;
    transform: translate(0px, 5px);
}

#timesheetCalendarRis .vc-bar {
    max-height: 1px !important;
}

#timesheetCalendarRis .v-application .text-center {

    overflow: auto;
}

#timesheetCalendarRis .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: inherit !important;
}

#timesheetCalendarRis .v-btn--disabled {
    opacity: 0.3;
}

#timesheetCalendarRis .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 100% !important;
}

#timesheetCalendarRis .itemAzienda {
    margin-left: 15px;
}

.itemNumDip {
    margin-left: 15px;
    max-width: 20px;
}

.itemNameDip {
    margin-left: 15px;
}



#timesheetCalendarRis .headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

#timesheetCalendarRis .closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

#timesheetCalendarRis .closeStatusSheet {
    position: absolute;
    right: 0;
}

#timesheetCalendarRis .v-dialog__content {
    margin-top: 56px;
}

#timesheetCalendarRis .containerFields {
    transform: translateX(-12px);
    max-width: 420px !important;
    min-width: 420px !important;
    overflow-y: auto !important;
    min-height: 350px !important;
    max-height: 350px !important;
}

#timesheetCalendarRis .vc-dot {
    width: 8px !important;
    height: 8px !important;
}



.cont_ts_un_prog #myTable {
    margin-right: 3px;
    margin-top: 72px;
}

.cont_ts_un_prog table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_ts_un_prog table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 26px !important;
}

.cont_ts_un_prog th {
    padding-left: 5px !important;
}

.cont_ts_un_prog ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_ts_un_prog .table-responsive {
    padding: 0px;
    min-width: 80vw;
    max-width: 96vw;
}

.cont_ts_un_prog div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cont_ts_un_prog #myTable_wrapper {
    padding-top: 0px;
    margin-top: 6px !important
}

.cont_ts_un_prog .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}

@media screen and (max-width: 960px) {

    .cont_ts_un_prog .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_ts_un_prog #myTable {
        /* margin-top:54px; */
    }

    .cont_ts_un_prog #myTable_filter {
        top: 103px;
        position: fixed;

    }

}

@media screen and (max-width: 768px) {

    #timesheetCalendarRis .cl_cont_azi_res_ts {
        display: flex !important;
        flex-direction: row !important;
        align-items: flex-start !important;
        min-height: 70px !important;
        padding-top: 19px !important;
    }

    #timesheetCalendarRis .cont_names {
        max-width: 93% !important;
        display: flex !important;
        flex-direction: column !important;
        min-width: 100% !important;
        margin-top: 3px !important;
    }


}

.cont_ts_un_prog input[type=Search] {
    display: none !important;
}

.cont_ts_un_prog ion-item {
    --min-height: 20px;
}

.cont_ts_un_prog .dataTable tbody tr td {
    vertical-align: middle;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-right: 0px !important;
    padding-left: 6px !important;
}

#timesheetCalendarRis .vc-pane-container {
    margin-top: -7px;
}

.salva {
    max-width: 25px;
    min-height: 25px;
    margin-left: -2px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.salvaeapri {
    max-width: 27px;
    min-height: 27px;
    margin-left: -2px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.salvaechiudi {
    max-width: 27px;
    min-height: 27px;
    margin-left: 4px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.pdfamministrativo {
    max-width: 24px;
    min-height: 24px;
    margin-left: 4px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.pdfcompleto {
    max-width: 25px;
    min-height: 25px;
    margin-left: 4px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.item.sc-ion-label-md-h,
.item .sc-ion-label-md-h {
    white-space: normal !important;
}

.item.sc-ion-label-ios-h,
.item .sc-ion-label-ios-h {
    white-space: normal !important;
}

.cont_ts_un_prog .day_red {
    color: red;
}


.cont_ts_un_prog .day_blue {
    color: blue;
}


.cont_ts_un_prog .day_green {
    color: green;
}

.cont_ts_un_prog .day_black {
    color: black;
}


.cont_ts_un_prog #myTable_filter {
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: flex-start !important;
    min-height: 70px !important;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    padding-top: 3px !important;
    background-color: white;
    border-color: #f5cf2e;
}


#timesheetCalendarRis .cont_buttons {

    width: 122px;
    min-width: 122px;

}

#timesheetCalendarRis .name_res {
    font-weight: bold;
    padding-left: 5px;
    transform: translate(3px, 24px);
}


.move_dip {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    min-width: 100%;
    left: 0;
    transform: translate(0px, 38px);
}

.cl_h_forzatura {
    transform: translateX(-15px) !important;
}

.cl_h_ordinario {
    transform: translateX(-17px) !important;
}

.cl_h_straordinario {
    transform: translateX(-16px) !important;
}

.cl_h_assenza_ore_1 {
    transform: translateX(-16px) !important;
}

.cl_h_assenza_tipo_1 {
    transform: translateX(-16px) !important;
}

.cl_h_assenza_ore_2 {
    transform: translateX(-17px) !important;
}

.cl_h_assenza_tipo_2 {
    transform: translateX(-18px) !important;
}

.cl_h_totale {
    transform: translateX(-18px) !important;
}

.cl_h_descrizione {
    transform: translateX(-16px) !important;
}

.cl_h_progetto {
    transform: translateX(-15px) !important;
}


@media screen and (max-width: 768px) {

    #timesheetCalendarRis .name_res {
        font-weight: bold;
        padding-left: 5px;
        transform: translate(3px, 12px);
    }


}


@media screen and (max-width: 1024px) {

    .cont_ts_un_prog .table-responsive {
        padding: 0px;
        min-width: 80vw;
        max-width: 100vw;
        left: 0px;
        position: fixed;

    }

    .cont_ts_un_prog #myTable {
        max-width: 100vw;
        margin-right: 0px;
        min-width: 100vw;
        position: relative;
        /*   left: 12px; */
        width: 100vw !important;
        margin-top: 70px;
    }

    .cont_ts_un_prog .cl_view {
        display: inline-flex;
    }

    #timesheetCalendarRis .vc-pane-container {
        margin-top: -8px;
    }

    #timesheetCalendarRis .totals {
        margin-top: -18px !important;
    }

    .cont_ts_un_prog #myTable_wrapper {
        padding-top: 0px;
        margin-top: 6px !important;
        max-width: 100vw !important;
        overflow-x: hidden !important;
    }

    .cont_ts_un_prog ion-select {
        max-width: 100px !important;
        word-break: break-all !important;
    }

    .cont_ts_un_prog ion-select label {
        max-width: 100px !important;
        word-break: break-all !important;
    }

    .cont_ts_un_prog .cl_tipo_ass_1 {
        max-width: 100px !important;
        margin-top: -4px !important;
    }

    .cont_ts_un_prog .day_red {
        color: red !important;
        min-width: 40px !important;
        margin-top: -4px;
        min-height: 30px !important;
        padding-top: 7px !important;
    }

    .cont_ts_un_prog .day_green {
        color: green !important;
        min-width: 40px !important;
        margin-top: -4px;
        min-height: 30px !important;
        padding-top: 7px !important;
    }

    .cont_ts_un_prog .day_black {
        color: black !important;
        min-width: 40px !important;
        margin-top: -4px;
        min-height: 30px !important;
        padding-top: 7px !important;

    }

    .cont_ts_un_prog .cl_giorno {
        margin-left: 18px;
    }

    .cont_ts_un_prog .col_ordinario {
        margin-top: -4px !important;
    }

    .cont_ts_un_prog .cl_eccedenze {
        margin-top: -4px !important;
    }

    .cont_ts_un_prog .cl_ore_ass_1 {
        margin-top: -4px !important;
    }



    .cont_ts_un_prog #myTable_filter {
        display: flex;
        flex-direction: row;
        align-items: flex-start !important;
        min-height: 70px !important;
        padding-top: 3px !important;
    }

    #timesheetCalendarRis .cont_buttons {

        width: 122px;
        min-width: 122px;

    }


    .move_dip {
        position: absolute;
        transform: translate(0px, 34px);
    }


    .cl_h_forzatura {}

    .cl_h_ordinario {}

    .cl_h_straordinario {}

    .cl_h_assenza_ore_1 {}

    .cl_h_assenza_tipo_1 {}

    .cl_h_assenza_ore_2 {}

    .cl_h_assenza_tipo_2 {}

    .cl_h_totale {}

    .cl_h_descrizione {}

    .cl_h_progetto {}





}
</style>